import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react';
import PropTypes from 'prop-types';
import { compose } from 'lodash/fp';

import ToggleItem from '../../components/ToggleItem/ToggleItem';
import LoadingIcon from '../../images/loading.gif';
import withProps from '../../components/withProps';

class JobTypes extends Component {
  // Shorthand for the rest of the code
  get independentProfile() {
    const {
      connectTechSettingsStore: { independentProfileStore },
    } = this.props;
    return independentProfileStore
      ? independentProfileStore.independentProfile
      : null;
  }

  get basicProfile() {
    const {
      connectTechSettingsStore: { basicProfileStore },
    } = this.props;
    return basicProfileStore ? basicProfileStore.basicProfile : null;
  }

  get JobSkillset() {
    const { connectTechSettingsStore } = this.props;
    return connectTechSettingsStore ? connectTechSettingsStore.getJobSkillset() : null;
  }

  render() {
    const {
      userId,
      categories,
      setCategories,
      connectTechSettingsStore: { isError, occupationsStore: { occupations, errorOccupations } },
    } = this.props;

    if (isError || errorOccupations) {
      return <Redirect to={`/mysite/${userId}/onboarding/error`} />;
    }

    if (!occupations || !this.independentProfile || !this.basicProfile) {
      return (
        <img
          className="connect-tech-settings__loading"
          src={LoadingIcon}
          alt="Loading"
        />
      );
    }

    return (
      <div className="connect-tech-settings">
        <div className="connect-tech-settings__title__question">
          <h1 className="connect-tech-settings__title__header">
            What kind of jobs do you do?
          </h1>
        </div>
        <div className="connect-tech-settings__section">
          {
            categories && categories.map((category) => (
              <ToggleItem
                ref={category.id}
                key={category.id}
                title={category.categoryName}
                enabled={category.enabled}
                icon={category.icon}
                onToggle={() => setCategories(
                  categories.map((x) => (x.id === category.id ? { ...x, enabled: !x.enabled } : x)),
                )}
              />
            ))
          }
        </div>
      </div>
    );
  }
}
JobTypes.propTypes = {
  connectTechSettingsStore: PropTypes.shape({
    isError: PropTypes.bool,
    getJobSkillset: PropTypes.func,
    independentProfileStore: PropTypes.shape({
      independentProfile: MobxPropTypes.observableObject,
    }),
    basicProfileStore: PropTypes.shape({
      basicProfile: MobxPropTypes.observableObject,
    }),
    occupationsStore: PropTypes.shape({
      occupations: MobxPropTypes.observableArray,
      errorOccupations: PropTypes.bool,
    }).isRequired,
  }).isRequired,
  userId: PropTypes.string,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      categoryName: PropTypes.string,
      enabled: PropTypes.bool,
      icon: PropTypes.string,
    }),
  ),
  setCategories: PropTypes.func,
};

export default compose(
  withProps(({
    match: {
      params: { userId },
    },
  }) => ({
    userId,
  })),
  inject((stores) => ({
    connectTechSettingsStore: stores.connectTechSettingsStore,
    occupationsStore: stores.connectTechSettingsStore.occupationsStore,
  })),
  observer,
)(JobTypes);
