import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router';
import { observer, inject } from 'mobx-react';
import { compose } from 'lodash/fp';

import FeatureFlagRoute from '../../../components/FeatureFlagRoute';
import { FeatureIds } from '../../../components/FeatureFlagsProvider';

import CarBrandsV2 from './CarBrandsV2';
import NotFoundView from '../../NotFoundView/NotFoundView';
import CarBrands from '../CarBrands';
import CarBrandYears from '../CarBrandYears';

const DEFAULT_CAR_BRAND_PAGES = [
  {
    pageKey: 'ConnectTechSettingsCarbrandsCommonBrandsPresetPage',
    pathSuffix: 'commonbrands',
    props: {
      usePresets: true,
      useNonPresets: false,
      defaultShowEnabled: false,
      enableSearch: false,
      enableToggleFilter: false,
      enableDatePicker: false,
      enableBrandToggle: true,
      enableNextButton: true,
      saveEagerly: false,
      removeSkillWithoutBrandsOnBack: true,
      removeSkillWithoutBrandsOnNext: false,
      prevLink: null,
      nextLink: './morebrands',
      contentTitle: 'Here are the most common car brands',
      contentSubtitle: 'Deselect brands you don\'t service for \':serviceName\'',
    },
  },
  {
    pageKey: 'ConnectTechSettingsCarbrandsOtherBrandsPresetPage',
    pathSuffix: 'morebrands',
    props: {
      usePresets: false,
      useNonPresets: true,
      defaultShowEnabled: false,
      enableSearch: true,
      enableToggleFilter: false,
      enableDatePicker: false,
      enableBrandToggle: true,
      enableNextButton: true,
      saveEagerly: false,
      removeSkillWithoutBrandsOnBack: false,
      removeSkillWithoutBrandsOnNext: true,
      prevLink: './commonbrands',
      nextLink: './yearrange',
      contentTitle: 'Do you service any other car brands',
      contentSubtitle: 'Select brands you do service for \':serviceName\'',
    },
  },
  {
    pageKey: 'ConnectTechSettingsCarbrandsYearRangePresetPage',
    pathSuffix: 'yearrange',
    props: {
      usePresets: false,
      useNonPresets: false,
      defaultShowEnabled: true,
      enableSearch: false,
      enableToggleFilter: false,
      enableDatePicker: true,
      enableBrandToggle: false,
      enableNextButton: true,
      saveEagerly: false,
      removeSkillWithoutBrandsOnBack: false,
      removeSkillWithoutBrandsOnNext: true,
      prevLink: './morebrands',
      nextLink: null,
      contentTitle: 'Here are all the brands you service for \':serviceName\'',
      contentSubtitle: 'Specify which years you service for each brand',
    },
  },
  {
    pageKey: 'ConnectTechSettingsCarbrandsFullConfigurationPage',
    pathSuffix: 'configureall',
    props: {
      usePresets: false,
      useNonPresets: false,
      defaultShowEnabled: true,
      enableSearch: true,
      enableToggleFilter: true,
      enableDatePicker: true,
      enableBrandToggle: true,
      enableNextButton: false,
      saveEagerly: false,
      removeSkillWithoutBrandsOnBack: true,
      removeSkillWithoutBrandsOnNext: false,
      prevLink: null,
      nextLink: null,
      contentTitle: null,
      contentSubtitle: 'Select all brands you service',
    },
  },
];

const CarBrandRoutes = ({
  match,
  connectTechSettingsStore,
  isOnboarding,
}) => {
  useEffect(() => {
    // reset error states when navigating across pages
    connectTechSettingsStore.resetErrors();
  }, [match]);

  return (
    <Switch>
      <Route
        exact
        path={`${match.path}/`}
        render={(props) => (<CarBrands {...props} isOnboarding={isOnboarding} />)}
      />
      {DEFAULT_CAR_BRAND_PAGES.map((featurePage) => (
        <FeatureFlagRoute
          exact
          key={featurePage.pageKey}
          path={`${match.path}/${featurePage.pathSuffix}`}
          render={(props) => (
            <CarBrandsV2
              {...props}
              {...featurePage.props}
              isOnboarding={isOnboarding}
            />
          )}
          flag={FeatureIds.DEFAULT_AUTO_BRANDS}
          fallback={<NotFoundView />}
        />
      ))}
      <Route
        exact
        path={`${match.path}/:brandId`}
        render={(props) => (<CarBrandYears {...props} isOnboarding={isOnboarding} />)}
      />
    </Switch>
  );
};

CarBrandRoutes.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.shape({
      userId: PropTypes.string,
    }),
  }),
  connectTechSettingsStore: PropTypes.shape({
    resetErrors: PropTypes.func,
  }),
  isOnboarding: PropTypes.bool,
};

export default compose(
  withRouter,
  inject('connectTechSettingsStore'),
  observer,
)(CarBrandRoutes);
