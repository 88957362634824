/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { observer, inject, PropTypes as MobxPropTypes } from 'mobx-react';
import { compose } from 'lodash/fp';

import { fullDayTimeslot, defaultTimeslot } from '../../constants/ConnectTechSettings';
import withProps from '../../components/withProps';

import BackArrowIcon from '../../images/icon-left-arrow.svg';
import ToggleOnIcon from '../../images/icon-toggle-on.svg';
import ToggleOffIcon from '../../images/icon-toggle-off.svg';
import LoadingIcon from '../../images/transparent-spinner.gif';

import TimeWindow from './TimeWindow';

import './DayAvailability.scss';

const render24HrWindow = () => (
  <div className="connect-tech-settings__day__block">
    <div className="connect-tech-settings__day__range">
      <div className="connect-tech-settings__day__range__unit right readonly">
        <div className="connect-tech-settings__day__range__display"> 12:00 AM </div>
        Start time
      </div>
      <div className="connect-tech-settings__day__range__unit left readonly">
        <div className="connect-tech-settings__day__range__display"> 11:59 PM </div>
        End Time
      </div>
    </div>
  </div>
);

class DayAvailability extends Component {
  on24HrToggle(val) {
    const { independentProfile } = this.props;
    const { schedule } = independentProfile;
    const { day } = this.props;
    const dayInfo = schedule[day - 1];

    const newSchedule = schedule.map((scheduleDay) => {
      if (scheduleDay === dayInfo) {
        return {
          ...dayInfo,
          enabled: val,
          fullDay: val,
          timeSlots: val ? fullDayTimeslot : defaultTimeslot,
        };
      }
      return scheduleDay;
    });

    // and assign it, the setter and the reaction will handle the update
    // in the store
    independentProfile.schedule = newSchedule;
  }

  onTimeInput = (window) => ({ start, end }) => {
    const { independentProfile } = this.props;
    const { schedule } = independentProfile;
    const { day } = this.props;
    const dayInfo = schedule[day - 1];

    const [startHour, startMin] = start.split(':').map((v) => parseInt(v, 10));
    const [endHour, endMin] = end.split(':').map((v) => parseInt(v, 10));

    const newTimeSlots = dayInfo.timeSlots.map((slot) => (
      slot.display === window.display
        ? {
          ...slot,
          display: `${start}-${end}`,
          start_time: start,
          end_time: end,
          start_hour: startHour,
          start_min: startMin,
          end_hour: endHour,
          end_min: endMin,
        }
        : slot
    ));

    independentProfile.setNewSchedule(newTimeSlots, dayInfo);
  };

  onDeleteWindow = (window) => {
    const { day, history, independentProfile } = this.props;
    const { schedule } = independentProfile;
    const dayInfo = schedule[day - 1];

    const newTimeSlots = dayInfo.timeSlots.filter((slot) => slot.display !== window);
    independentProfile.setNewSchedule(newTimeSlots, dayInfo);

    if (newTimeSlots.length === 0) {
      history.goBack();
    }
  };

  renderTimeWindows(dayInfo) {
    const { timeSlots } = dayInfo;

    return timeSlots.map((timeSlot) => (
      <TimeWindow
        key={timeSlot.display}
        deletable={timeSlots.length !== 1}
        onDelete={() => this.onDeleteWindow(timeSlot.display)}
        onChange={this.onTimeInput(timeSlot)}
        startValue={timeSlot.start_time.padStart(5, '0')}
        endValue={timeSlot.end_time.padStart(5, '0')}
      />
    ));
  }

  render() {
    const { day, independentProfile, history } = this.props;

    if (!independentProfile) {
      return (<img className="connect-tech-settings__loading" src={LoadingIcon} alt="Loading" />);
    }

    const dayInfo = independentProfile.schedule[day - 1];
    const dayName = dayInfo.name;
    const { fullDay } = dayInfo;

    return (
      <div className="connect-tech-settings__day">
        {
          independentProfile.isError && (
            <div className="connect-tech-settings__error">
              Something went wrong, try again.
            </div>
          )
        }
        <img
          className="connect-tech-settings__back-arrow"
          src={BackArrowIcon}
          onClick={history.goBack}
          alt="Back"
        />
        <div className="connect-tech-settings__day__header">
          <div className="connect-tech-settings__day__header__title">{ dayName }</div>
          {
            !fullDay && (
              <button
                type="button"
                className="connect-tech-settings__day__header__button"
                onClick={() => independentProfile.addNewWindow(day)}
              >
                +
              </button>
            )
          }
        </div>
        <div className="connect-tech-settings__day__divider" />
        <div className="connect-tech-settings__day__all-day">
          <div>Available 24hrs</div>
          <button
            type="button"
            className="connect-tech-settings__day__all-day__toggle"
            onClick={() => this.on24HrToggle(!fullDay)}
          >
            <img
              src={fullDay ? ToggleOnIcon : ToggleOffIcon}
              alt={fullDay ? 'enabled' : 'disabled'}
            />
          </button>
        </div>
        {
          !fullDay ? this.renderTimeWindows(dayInfo) : render24HrWindow()
        }
      </div>
    );
  }
}

DayAvailability.propTypes = {
  day: PropTypes.number,
  independentProfile: PropTypes.shape({
    dayInfo: MobxPropTypes.observableObject,
    setDayInfo: PropTypes.func,
    isError: PropTypes.bool,
    schedule: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        fullDay: PropTypes.bool,
        timeSlots: PropTypes.arrayOf(
          PropTypes.shape({
            start_time: PropTypes.string,
            end_time: PropTypes.string,
          }),
        ),
      }),
    ),
    setNewSchedule: PropTypes.func,
    addNewWindow: PropTypes.func,
  }),
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }),
};

export default compose(
  withProps(({
    match: {
      params: {
        userId,
        day,
      },
    },
  }) => ({
    userId,
    day: parseInt(day, 10),
  })),
  withRouter,
  inject((stores) => ({
    independentProfile: stores.connectTechSettingsStore.independentProfileStore,
  })),
  observer,
)(DayAvailability);
