import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject, PropTypes as MobxPropTypes } from 'mobx-react';
import _ from 'lodash';
import { compose } from 'lodash/fp';

import LoadingIcon from '../../images/transparent-spinner.gif';
import ToggleOnIcon from '../../images/icon-toggle-on.svg';
import ToggleOffIcon from '../../images/icon-toggle-off.svg';
import './Skillset.scss';

import SelectDrawer from '../../components/SelectDrawer/SelectDrawer';
import ToggleItem from '../../components/ToggleItem/ToggleItem';
import Layout from '../../components/MySite/Layout/Layout';
import withProps from '../../components/withProps';

const getTitle = (title) => {
  switch (title) {
    case 'Home':
      return 'Home & Office';
    case 'Auto':
      return 'Automotive';
    default:
      return title;
  }
};

class Skillset extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewDraw: false,
      recentlyToggledOnService: null,
      category: null,
    };
  }

  componentDidMount() {
    const { occupationId, subcategoryId, occupationsStore } = this.props;
    const occupation = occupationId
      && occupationsStore.getOccupation(parseInt(occupationId, 10));
    const subcategory = occupation && occupation.categories.find((c) => c.id === subcategoryId);
    if (subcategory && subcategory.category) {
      this.setState({ category: subcategory.category });
    }
  }

  componentDidUpdate() {
    // watch for a new auto skill to be added by the datastore, and
    // if car brand presets are to be used, redirect to the configuration page
    const { basicProfileStore, history, enableAutoBrandPresets } = this.props;
    const { recentlyToggledOnService, category } = this.state;

    if (enableAutoBrandPresets
      && basicProfileStore
      && basicProfileStore.skillset
      && recentlyToggledOnService) {
      const skill = basicProfileStore.skillset.find(
        (s) => s.job_service_id === recentlyToggledOnService.id,
      );

      // a fully created skill from the datastore will have an id
      const skillId = skill ? skill.id : null;
      if (category === 'Auto' && skillId && recentlyToggledOnService.enabled) {
        history.push({
          pathname: `../../${skillId}/carbrands/commonbrands`,
          state: {
            title: recentlyToggledOnService.service,
            serviceId: recentlyToggledOnService.id,
            doAutoConfigurePresets: true,
          },
        });
      }
    }
  }

  onToggle(service) {
    const {
      subcategoryId,
      occupationId,
      basicProfileStore,
      enableAutoBrandPresets,
    } = this.props;
    const { category } = this.state;

    let newSkillset = _.cloneDeep(basicProfileStore.skillset);
    if (!service.enabled) {
      if (category === 'Auto') {
        this.setState({
          viewDraw: !enableAutoBrandPresets,
          recentlyToggledOnService: { ...service, enabled: enableAutoBrandPresets },
        });
      }

      newSkillset.push({
        occupation_id: occupationId,
        job_sub_category_id: subcategoryId,
        job_service_id: service.id,
      });
    } else {
      this.setState({ viewDraw: false });

      newSkillset = newSkillset.filter((s) => s.job_service_id !== service.id);
    }

    basicProfileStore.skillset = newSkillset;
  }

  getPageItems() {
    const {
      occupationId,
      subcategoryId,
      occupationsStore,
      basicProfileStore,
    } = this.props;

    if (occupationsStore) {
      const occupation = occupationId
        && occupationsStore.getOccupation(parseInt(occupationId, 10));
      const subcategory = occupation.categories.find(
        (c) => c.id === subcategoryId,
      );
      const activeServices = subcategory.services.filter((s) => s.active);

      if (basicProfileStore.skillset.length > 0) {
        return activeServices.map((service) => {
          const matchSkill = basicProfileStore.skillset.find(
            (s) => service.id === s.job_service_id,
          );
          const res = {
            ...service,
            skillId: matchSkill ? matchSkill.id : null,
            enabled: !!matchSkill,
          };
          return res;
        });
      }
      return [];
    }
    return null;
  }

  goBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  exitDraw = () => {
    const { recentlyToggledOnService } = this.state;

    recentlyToggledOnService.enabled = true;
    this.onToggle(recentlyToggledOnService);

    this.setState({ viewDraw: false });
  };

  onDrawSelect = (matchId, service) => {
    const { basicProfileStore, history } = this.props;
    const skillId = basicProfileStore.skillset.find(
      (s) => s.job_service_id === service.id,
    )?.id;
    if (!skillId) {
      this.setState({ viewDraw: false });
      return;
    }
    if (matchId === -2) {
      history.push({
        pathname: `../../${skillId}/carbrands`,
        state: { title: service.service },
      });
    } else if (matchId === -1) {
      basicProfileStore.selectAllBrands(skillId);
      this.setState({ viewDraw: false });
    } else {
      const matchSkillId = basicProfileStore.skillset.find(
        (s) => s.job_service_id === matchId,
      ).id;
      basicProfileStore.selectBrandsFromSkill(skillId, matchSkillId);
      this.setState({ viewDraw: false });
    }
  };

  renderToggles(services) {
    const { category } = this.state;
    const { basicProfileStore, enableAutoBrandPresets } = this.props;
    if (category === 'Auto') {
      return services.map((service) => {
        const skill = basicProfileStore.skillset.find(
          (s) => s.job_service_id === service.id,
        );
        const skillId = skill ? skill.id : null;
        const brandsForSkill = skillId
          ? basicProfileStore.autobrandsBySkill[skillId]
          : null;
        const selectedBrands = `${
          brandsForSkill
            ? brandsForSkill.filter((brand) => brand.enabled).length
            : 0
        } out of ${basicProfileStore.autobrands === null ? 0 : basicProfileStore.autobrands.length}`;
        const autoBrandPathname = enableAutoBrandPresets
          ? `../../${skillId}/carbrands/configureall`
          : `../../${skillId}/carbrands`;
        return (
          <ToggleItem
            ref={service.service}
            key={service.service}
            title={service.service}
            link={{
              pathname: autoBrandPathname,
              state: {
                title: service.service,
                serviceId: service.id,
                doAutoConfigurePresets: false,
              },
            }}
            subcomponent={<div>{selectedBrands}</div>}
            enabled={service.enabled}
            onToggle={() => this.onToggle(service)}
          />
        );
      });
    }
    return services.map((service) => (
      <div
        className="connect-tech-settings__skillset__service"
        key={`service-${service.id}`}
      >
        {service.service}
        <button
          type="button"
          onClick={() => this.onToggle(service)}
          className="connect-tech-settings__skillset__service__toggle"
        >
          <img
            src={service.enabled ? ToggleOnIcon : ToggleOffIcon}
            alt={service.enabled ? 'on' : 'off'}
          />
        </button>
      </div>
    ));
  }

  render() {
    const {
      occupationId,
      subcategoryId,
      occupationsStore,
      basicProfileStore,
      connectTechSettingsStore,
      isOnboarding,
      userId,
    } = this.props;
    const {
      category,
      viewDraw,
      recentlyToggledOnService,
    } = this.state;

    if (!basicProfileStore) {
      return (
        <Layout loading fullWidth />
      );
    }

    const title = occupationsStore.occupations ? getTitle(category) : 'Skillset';
    const shouldDisplayWarning = connectTechSettingsStore && connectTechSettingsStore.data.marketplace_qualification === 'Eligible';

    return (
      <Layout fullWidth loading={false} userId={userId}>
        <div className="connect-tech-settings__skillset">
          {basicProfileStore.isError && (
          <div className="connect-tech-settings__error">
            Something went wrong, try again.
          </div>
          )}
          <div className="connect-tech-settings__skillset__title">
            <div className="connect-tech-settings__title__header">{isOnboarding ? `Which ${title} jobs?` : ''}</div>
          </div>
          {
          this.renderToggles(
            connectTechSettingsStore.getPageItems(occupationId, subcategoryId),
          )
        }
          {
          viewDraw && (
            <button
              aria-label="exit"
              type="button"
              className="connect-tech-settings__skillset__backdrop"
              onClick={this.exitDraw}
            />
          )
        }
          {basicProfileStore.isLoading && (
          <div className="connect-tech-settings__skillset__backdrop-high">
            <img
              className="connect-tech-settings__loading"
              src={LoadingIcon}
              alt="Loading"
            />
          </div>
          )}
          <SelectDrawer
            currentService={recentlyToggledOnService}
            enabled={viewDraw}
            services={connectTechSettingsStore.getPageItems(
              occupationId,
              subcategoryId,
            )}
            onSelection={this.onDrawSelect}
            isOnboarding={isOnboarding}
          />
        </div>
        {shouldDisplayWarning
        && (
          <p className="connect-tech-settings__warning">
            Changing this setting on
            MySite will update it in your Connect Settings too.
          </p>
        )}
      </Layout>
    );
  }
}

Skillset.propTypes = {
  occupationId: PropTypes.number,
  subcategoryId: PropTypes.number,
  basicProfileStore: PropTypes.shape({
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    autobrands: MobxPropTypes.observableArray,
    autobrandsBySkill: MobxPropTypes.observableObject,
    skillset: MobxPropTypes.observableArray,
    selectBrandsFromSkill: PropTypes.func,
    selectAllBrands: PropTypes.func,
  }),
  occupationsStore: PropTypes.shape({
    getOccupation: PropTypes.func,
    occupations: MobxPropTypes.observableObject,
  }),
  connectTechSettingsStore: PropTypes.shape({
    getPageItems: PropTypes.func,
    data: {
      marketplace_qualification: PropTypes.string,
    },
  }),
  history: PropTypes.shape({
    goBack: PropTypes.func,
    push: PropTypes.func,
  }),
  isOnboarding: PropTypes.bool,
  userId: PropTypes.string,
  enableAutoBrandPresets: PropTypes.bool,
};

export default compose(
  withProps(({
    match: {
      params: { userId, occupationId, subcategoryId },
    },
    isOnboarding,
  }) => ({
    userId,
    isOnboarding,
    occupationId: parseInt(occupationId, 10),
    subcategoryId: parseInt(subcategoryId, 10),
  })),
  inject((stores) => ({
    basicProfileStore: stores.connectTechSettingsStore.basicProfileStore,
    occupationsStore: stores.connectTechSettingsStore.occupationsStore,
    connectTechSettingsStore: stores.connectTechSettingsStore,
  })),
  observer,
)(Skillset);
