class JobModel {
  cleanID: string;
  occupationId: number;

  constructor(job: Job) {
    this.cleanID = job?.cleanID;
    this.occupationId = job?.occupation_id;
  }
}

export default JobModel;
