import { useEffect, useRef } from 'react';

const useDidMount = (callback: () => void, deps: unknown[]) => {
  const executed = useRef(false);
  useEffect(() => {
    if (!executed.current) {
      executed.current = true;
      callback();
    }
  }, deps);
};

export default useDidMount;
