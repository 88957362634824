import React, { useEffect, useCallback, useRef } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import { usePrevious } from '@jobox/react-components-library/mobile';

import config from '../config';

function withAnalytics(options = {}) {
  return (WrappedComponent) => (
    function AnalyticsWrap(props) {
      const location = useLocation();
      const prevLocation = usePrevious(location);
      const gaInitialized = useRef(false);

      useEffect(() => {
        ReactGA.initialize(config.GOOGLE_ANALYTICS_KEY, {
          debug: config.APP_ENV !== 'production',
          testMode: config.APP_ENV !== 'production',
          gaOptions: typeof options === 'function' ? options(props) : options,
        });
        gaInitialized.current = true;
      }, []);

      useEffect(() => {
        if (gaInitialized.current) {
          if (location.pathname !== prevLocation?.pathname) {
            ReactGA.pageview(location.pathname);
          }
        }
      }, [location, prevLocation]);

      const registerEvent = useCallback((eventDefinition) => {
        if (gaInitialized.current) {
          ReactGA.event(eventDefinition);
        }
      }, []);

      return (
        <WrappedComponent {...props} registerAnalyticsEvent={registerEvent} />
      );
    }
  );
}

export default withAnalytics;
