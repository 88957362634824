import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import classes from './menuItem.module.scss';

import arrowIcon from '../../../images/arrowRight.svg';

interface LogoProps {
  to: string,
  icon: string,
  title: string,
  text: React.ReactElement,
}

const MenuItem = ({
  to, icon, title, text,
}: LogoProps) => {
  const isClickable = to !== '#';

  return (
    <div className={classes.menuItem}>
      <div className={classes.header}>
        <img src={icon} alt={title} />
        <span>{title}</span>
      </div>
      <Link to={to} className={cn(classes.link, isClickable ? null : classes.disabled)}>
        <div className={classes.textContainer}>
          {text}
        </div>
        {isClickable && <img src={arrowIcon} alt="open" />}
      </Link>
    </div>
  );
};

export default MenuItem;
