import { useEffect } from 'react';

const useOnEventOutside = (ref, eventHandler, events) => {
  useEffect(
    () => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          eventHandler();
        }
      };

      events.forEach((action) => document.addEventListener(action, handleClickOutside));

      return () => {
        events.forEach((action) => document.removeEventListener(action, handleClickOutside));
      };
    },
    [eventHandler, events, ref],
  );
};

export default useOnEventOutside;
