import {
  observable,
  makeObservable,
  action, toJS, computed,
} from 'mobx';

class ReviewsStore {
  reviews = null;
  userId = null;
  isLoading = false;

  constructor(apiService) {
    this.apiService = apiService;

    makeObservable(this, {
      reviews: observable,
      isLoading: observable,

      total: computed,
      visible: computed,

      fetchReviews: action,
      toggleVisible: action,
    });
  }

  get visible() {
    const reviewsObject = toJS(this.reviews) || [];
    return reviewsObject.filter((review) => review.visible).length;
  }

  get total() {
    const reviewsObject = toJS(this.reviews) || [];
    return reviewsObject.length;
  }

  fetchReviews(userId) {
    this.isLoading = true;

    this.userId = userId;
    this.apiService.getMySiteReviews(userId)
      .then(action((reviews) => {
        this.reviews = reviews;
      })).finally(() => {
        this.isLoading = false;
      });
  }

  toggleVisible(id) {
    const reviewsObject = toJS(this.reviews);
    let visible;
    const modifiedReviews = reviewsObject.map((review) => {
      visible = review.visible;
      if (review.id === id) {
        visible = !review.visible;
      }
      return { ...review, visible };
    });

    this.apiService.updateMySiteReviews(this.userId, modifiedReviews);
    this.reviews = modifiedReviews;
  }
}

export default ReviewsStore;
