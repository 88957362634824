/* eslint-disable no-console */
import React from 'react';
import Button from '../../../components/MySite/Button/Button';
import classes from '../mySiteDashboardView.module.scss';
import previewIcon from '../../../images/preview.svg';

interface PreviewButtonProps {
  previewUrl: string;
  className?: string
}

const PreviewButton = ({ previewUrl, className }: PreviewButtonProps) => (
  <a className={classes.preview} href={previewUrl}>
    <Button onClick={() => {}} label="Preview" icon={previewIcon} className={className} />
  </a>
);

export default PreviewButton;
