import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import NavSection from './NavSection';
import './NavSection.scss';

const SkillsetSection = ({
  userId,
  skillset,
  occupation,
  isLink,
  baseUrl,
}) => (
  <>
    {
      skillset.map((category) => (
        <Link
          to={`/${baseUrl}/${userId}/skillset/${occupation.id}/subcategory/${category.id}`}
          className="text-decoration-none"
          key={`${occupation.name}-${category.categoryName}`}
        >
          <NavSection
            title={category.categoryName}
            subtitle={`${category.services} Services`}
            icon={category.icon}
            isLink={isLink}
            className="connect-tech-settings__skillset-section"
          />
        </Link>
      ))
    }
  </>
);

SkillsetSection.propTypes = {
  userId: PropTypes.string,
  skillset: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      categoryName: PropTypes.string,
      services: PropTypes.number,
      icon: PropTypes.string,
    }),
  ),
  occupation: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  isLink: PropTypes.bool,
  baseUrl: PropTypes.string,
};

export default SkillsetSection;
