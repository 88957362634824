import {
  init,
  configureScope,
  captureException,
  captureMessage,
} from '@sentry/browser';
import config from '../config';
import logger from './logger';
import packageJson from '../../package.json';

// Init
if (config.SENTRY_DSN) {
  init({
    dsn: config.SENTRY_DSN,
    environment: config.APP_ENV,
    release: packageJson.version,
  });

  configureScope((scope) => {
    scope.setTag('app', 'client');
  });
} else {
  logger.warn('Sentry DSN server is not set - skipping initialization');
}

const reportError = (error, errorInfo) => {
  if (error instanceof Error) {
    captureException(error);
  } else if (typeof error === 'string') {
    captureMessage(error);
  } else {
    configureScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
    });
  }
};

export default reportError;
