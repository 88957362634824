import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Route } from 'react-router';
import config from '../../config';

interface TrackedRouteProps {
  pageName: string;
  component: any;
  render?: (props: any) => JSX.Element;
  [_: string]: any;
}

const TrackedRoute = ({
  pageName,
  component: Component,
  render,
  ...rest
}: TrackedRouteProps) => {
  useEffect(() => {
    if (pageName && config.GOOGLE_ANALYTICS_KEY) {
      ReactGA.pageview(pageName);
      ReactGA.event({
        category: 'pageview',
        label: pageName,
        action: 'user navigation',
      });
    }
  }, [pageName]);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (render) {
          return render(props);
        }
        return <Component {...props} />;
      }}
    />
  );
};

export default TrackedRoute;
