/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback, useEffect, useState } from 'react';
import { observer, inject, PropTypes as MobxPropTypes } from 'mobx-react';
import PropTypes from 'prop-types';
import { compose } from 'lodash/fp';

import withProps from '../../components/withProps';
import BackArrowIcon from '../../images/icon-left-arrow.svg';

import './Skillset.scss';
import './CarBrandYear.scss';

const MIN_YEAR = 1950;

const CarBrandYears = ({
  basicProfileStore,
  skillId,
  brandId,
  history,
  serviceTitle,
  brandName,
  startYear,
  endYear,
}) => {
  const [{ start, end }, setYearState] = useState({
    start: startYear || '',
    end: endYear || '',
  });
  const goBack = () => {
    history.goBack();
  };

  const saveBrandYears = useCallback(() => {
    if (start || end) {
      basicProfileStore.updateUserSkillAutobrand(skillId, brandId, {
        brand_id: brandId,
        year_from: start,
        year_to: end,
      });
    }
  }, [basicProfileStore, start, end, skillId, brandId]);

  useEffect(() => {
    saveBrandYears();
  }, [start, end, saveBrandYears]);

  const setEndYear = (e) => {
    setYearState({
      start: start <= e.target.value ? start : '',
      end: e.target.value,
    });
  };

  const setStartYear = (e) => {
    setYearState({
      end: end >= e.target.value ? end : '',
      start: e.target.value,
    });
  };

  const now = new Date().getFullYear();
  const years = Array(now - MIN_YEAR + 1)
    .fill()
    .map((_, idx) => now - idx);
  return (
    <>
      {basicProfileStore.isError && (
        <div className="connect-tech-settings__error">
          Something went wrong, try again.
        </div>
      )}
      <img
        className="connect-tech-settings__back-arrow"
        src={BackArrowIcon}
        alt="Back"
        onClick={goBack}
      />
      <div className="connect-tech-settings__car-brands__page-title">
        {serviceTitle}
      </div>
      <div className="connect-tech-settings__car-brands__title">
        <div className="connect-tech-settings__title__header">{brandName}</div>
      </div>
      <div className="year-picker__container">
        <div className="year-picker__description">
          Limit the years for
          {' '}
          {brandName}
          {' '}
          jobs you receive by adding a start and/or end year.
          {' '}
          Leave blank to accept jobs from any year.
        </div>
        <div className="year-picker__select-container-top">
          <div>Start Year</div>
          <select
            value={start}
            className="year-picker__select-box"
            onChange={setStartYear}
          >
            <option value="">All</option>
            {years.map((year) => (
              <option key={year}>{year}</option>
            ))}
          </select>
        </div>
        <div className="year-picker__select-container-bottom">
          <div>End Year</div>
          <select
            className="year-picker__select-box"
            value={end}
            onChange={setEndYear}
          >
            <option value="">Present</option>
            {years.map((year) => (
              <option value={Number(year)} key={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
};

CarBrandYears.propTypes = {
  basicProfileStore: PropTypes.shape({
    fetchAutobrands: PropTypes.func,
    createUserSkillAutobrand: PropTypes.func,
    removeUserSkillAutobrand: PropTypes.func,
    updateUserSkillAutobrand: PropTypes.func,
    clearBrands: PropTypes.func,
    selectAllBrands: PropTypes.func,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    autobrandsBySkill: MobxPropTypes.observableObject,
    skillset: MobxPropTypes.observableArray,
  }),
  skillId: PropTypes.number,
  brandId: PropTypes.string,
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }),
  serviceTitle: PropTypes.string,
  brandName: PropTypes.string,
  startYear: PropTypes.number,
  endYear: PropTypes.number,
};

export default compose(
  withProps(({
    location: {
      state: {
        serviceTitle,
        brandName,
        startYear,
        endYear,
      },
    },
    match: {
      params: { userId, skillId, brandId },
    },
  }) => ({
    userId,
    skillId: parseInt(skillId, 10),
    brandId,
    serviceTitle,
    brandName,
    startYear,
    endYear,
  })),
  inject((stores) => ({
    basicProfileStore: stores.connectTechSettingsStore.basicProfileStore,
    occupationsStore: stores.connectTechSettingsStore.occupationsStore,
    connectTechSettingsStore: stores.connectTechSettingsStore,
  })),
  observer,
)(CarBrandYears);
