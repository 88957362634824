import {
  action, makeObservable, observable, computed, runInAction,
} from 'mobx';
import { chain, orderBy } from 'lodash';

import JobModel from '../models/JobModel';
import OccupationModel from '../models/OccupationModel';

import ActionStatus from '../lib/actionStatus';

class CancelJobStore {
  constructor(apiService) {
    this.apiService = apiService;
    this.prepare();

    makeObservable(this, {
      fetchJobStatus: observable,
      fetchOccupationStatus: observable,
      rejectStatusObservable: observable,
      revokeStatusObservable: observable,
      updateStatusObservable: observable,

      job: observable,
      occupation: observable,

      initialize: action,
      fetchJob: action,
      fetchOccupation: action,
      revokeJob: action,
      rejectJob: action,
      updateJob: action,

      cancellationReasons: computed,
    });
  }

  prepare() {
    this.fetchJobStatus = ActionStatus.createIdle();
    this.fetchOccupationStatus = ActionStatus.createIdle();
    this.rejectStatusObservable = ActionStatus.createIdle();
    this.revokeStatusObservable = ActionStatus.createIdle();
    this.updateStatusObservable = ActionStatus.createIdle();

    this.job = {};
    this.occupation = {};
  }

  // Getters ------------------------------------
  get cancellationReasons() {
    return this.occupation.cancellationReasons ?? [];
  }

  get cancellationReasonsTree() {
    const topLevelReasons = this.cancellationReasons.filter(
      ({ parentReasonId }) => !parentReasonId,
    );
    const childReasonsGroups = chain(this.cancellationReasons)
      .difference(topLevelReasons)
      .groupBy('parentReasonId')
      .value();
    return chain(topLevelReasons)
      .orderBy('rank', 'desc')
      .map((reason) => ({
        ...reason,
        childReasons: orderBy(childReasonsGroups[reason.id], 'rank', 'desc') || null,
      }))
      .value();
  }

  get jobRefId() {
    return this.job.cleanID ?? null;
  }

  get revokeStatus() {
    return this.revokeStatusObservable;
  }

  get rejectStatus() {
    return this.rejectStatusObservable;
  }

  get updateStatus() {
    return this.updateStatusObservable;
  }

  // Setters ------------------------------------
  set revokeStatus(value) {
    runInAction(() => {
      this.revokeStatusObservable = value;
    });
  }

  set rejectStatus(value) {
    runInAction(() => {
      this.rejectStatusObservable = value;
    });
  }

  set updateStatus(value) {
    runInAction(() => {
      this.updateStatusObservable = value;
    });
  }

  // Actions ------------------------------------
  async initialize(jobId) {
    this.prepare();

    if (jobId) {
      const jobRaw = await this.fetchJob(jobId);
      if (jobRaw) {
        runInAction(() => {
          this.job = new JobModel(jobRaw);
        });
      }

      const { occupationId } = this.job;
      const occupationRaw = await this.fetchOccupation(occupationId);
      if (occupationRaw) {
        runInAction(() => {
          this.occupation = new OccupationModel(occupationRaw);
        });
      }
    }
  }

  rejectJob(jobId, reasonData) {
    this.rejectStatus = ActionStatus.createLoading();

    return this.apiService.rejectJob(jobId, reasonData)
      .then(action(() => {
        this.rejectStatus = ActionStatus.createSuccess();
      }))
      .catch(action((exc) => {
        this.rejectStatus = ActionStatus.createError(exc.messsage);
      }));
  }

  updateJob(jobId, reasonData) {
    this.updateStatus = ActionStatus.createLoading();

    const { reason_id: reasonId, reason, price } = reasonData;
    return this.apiService.updateJob(jobId, { reason_id: reasonId, message: reason, price })
      .then(action(() => {
        this.updateStatus = ActionStatus.createSuccess();
      }))
      .catch(action((exc) => {
        this.updateStatus = ActionStatus.createError(exc.messsage);
      }));
  }

  revokeJob(jobId, reasonData) {
    this.revokeStatus = ActionStatus.createLoading();

    return this.apiService.revokeJob(jobId, reasonData)
      .then(action(() => {
        this.revokeStatus = ActionStatus.createSuccess();
      }))
      .catch(action((exc) => {
        this.revokeStatus = ActionStatus.createError(exc.messsage);
      }));
  }

  fetchJob(jobId) {
    this.fetchJobStatus = ActionStatus.createLoading();

    return this.apiService.getJob(jobId)
      .then(action((job) => {
        this.fetchJobStatus = ActionStatus.createSuccess();

        return job;
      }))
      .catch(action((exc) => {
        this.fetchJobStatus = ActionStatus.createError(exc.messsage);
      }));
  }

  fetchOccupation(occupationId) {
    this.fetchOccupationStatus = ActionStatus.createLoading();

    return this.apiService.getOccupationV2(occupationId)
      .then(action((occupation) => {
        this.fetchOccupationStatus = ActionStatus.createSuccess();

        return occupation;
      }))
      .catch(action((exc) => {
        this.fetchOccupationStatus = ActionStatus.createError(exc.messsage);
      }));
  }
}

export default CancelJobStore;
