/* eslint-disable import/prefer-default-export */
export const ActionType = {
  Reject: 'reject',
  Revoke: 'revoke',
  Update: 'update',
};

export const ReasonType = {
  Price: 'PRICE',
  Text: 'TXT',
};

export const CompletionStatus = {
  Quit: 'quit',
  Complete: 'complete',
};
