import React from 'react';
import classes from './rangeGrid.module.scss';

interface RangeGridProps {
  items: { value: number; label: string | number | undefined, disabled: boolean }[];
  selectedStart?: number;
  selectedEnd?: number;
  onSelected?: (selectedItem: number) => void;
  columnsCount: number;
  inRange?: (m1?: number, m2?: number) => boolean;
  singlePick?: boolean;
}

function RangeGrid({
  items,
  columnsCount,
  selectedEnd,
  selectedStart,
  singlePick,
  onSelected,
  inRange,
}:
RangeGridProps) {
  const getTableRows = () => {
    const rows = [];
    for (let i = 0; i < items.length; i += columnsCount) {
      rows.push(items.slice(i, i + columnsCount));
    }
    return rows;
  };
  const isInRange = (value: number) => (inRange && inRange(selectedStart, value))
  || (selectedStart && selectedEnd
    && selectedStart <= value && value <= selectedEnd);
  return (
    <>
      <table className={classes.range_grid__table}>
        <tbody>
          {getTableRows().map((row) => (
            <tr key={row[0].value}>
              {row.map(({ value, label, disabled }) => (
                <td
                  className={`${classes.range_grid__cell} ${isInRange(value) && classes.range_grid__selected} ${disabled && classes.range_grid__disabled} ${!!singlePick && classes.range_grid__single_highlight}`}
                  key={value}
                >
                  <button
                    type="button"
                    onClick={() => onSelected && !disabled && onSelected(value)}
                    className={`${classes.range_grid__cell} ${isInRange(value) && classes.range_grid__selected} ${disabled && classes.range_grid__disabled} ${!!singlePick && classes.range_grid__single_highlight}`}
                  >
                    {label}
                  </button>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

export default RangeGrid;
