import axios from 'axios';
import logger from './logger';

const createAxiosInstance = (params) => {
  const instance = axios.create(params);
  instance.interceptors.response.use((value) => Promise.resolve(value), (error) => {
    logger.error(error);

    return Promise.reject(error);
  });
  return instance;
};

export default createAxiosInstance;
