import {
  action, observable, makeObservable, toJS,
} from 'mobx';
import OccupationModel from '../models/OccupationModel';

class OccupationsStore {
  occupations = [];

  constructor(apiService) {
    this.apiService = apiService;
    this.loadingOccupations = false;
    this.errorOccupations = null;
    makeObservable(this, {
      loadingOccupations: observable,
      occupations: observable,
      getOccupation: action,
      loadOccupations: action,
    });
  }

  get allOccupations() {
    const occupations = this.occupations.map((occupation) => new OccupationModel(occupation));

    // Sorting in backend order but showing occupations with icon first
    return occupations.filter((o) => o.icon).concat(occupations.filter((o) => !o.icon));
  }

  getOccupation(occupationId) {
    const { occupations } = toJS(this.occupations);
    const occupation = occupations
      && new OccupationModel(occupations.find((o) => o.id === occupationId));

    return occupation || null;
  }

  loadOccupations() {
    this.loadingOccupations = true;
    return this.apiService.getOccupations()
      .then(action((data) => {
        this.occupations = data;
        this.loadingOccupations = false;
      }))
      .catch(((error) => { this.errorOccupations = error; }));
  }
}

export default OccupationsStore;
