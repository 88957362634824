import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import {
  Box,
  Icon,
  List,
  ListGroup,
  ListItem,
} from '@jobox/react-components-library/mobile';

import classes from './ReasonsList.module.scss';

const ReasonsList = ({
  reasonsTree,
  selectedId,
  onSelectedIdChanged,
}) => {
  const renderListItems = (listItems) => listItems?.map((listItem) => {
    if (!isEmpty(listItem.childReasons)) {
      return (
        <ListGroup id={listItem.id} key={listItem.id} title={listItem.description}>
          { renderListItems(listItem.childReasons, listItem.id) }
        </ListGroup>
      );
    }
    return (
      <ListItem
        id={listItem.id}
        key={listItem.id}
        renderIndicator={() => (
          <Box className={classes['list-selected-indicator']}>
            <Icon icon={faChevronRight} className={classes['list-selected-indicator__icon']} />
          </Box>
        )}
      >
        { listItem.description }
      </ListItem>
    );
  });

  return (
    <List selectedId={selectedId} onSelectId={onSelectedIdChanged}>
      {
        renderListItems(reasonsTree)
      }
    </List>
  );
};

ReasonsList.propTypes = {
  reasonsTree: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    childReasons: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
    })),
  })).isRequired,
  selectedId: PropTypes.number,
  onSelectedIdChanged: PropTypes.func,
};

export default ReasonsList;
