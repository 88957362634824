class UserJobStatsModel {
  totalJobsCompleted?: number;
  totalJobsMissed?: number;
  totalAmountMissed?: number;
  totalAmountEarned?: number;
  startTime?: number;
  rangeType?: string;
  marketplace?: boolean;
  stats?: JobStats[];
  constructor(userJobStats?: UserJobStatsResponse) {
    if (userJobStats) {
      this.totalJobsCompleted = userJobStats.total_jobs_completed;
      this.totalJobsMissed = userJobStats.total_jobs_missed;
      this.rangeType = userJobStats.rage_type;
      this.stats = userJobStats.stats;
      this.totalAmountMissed = userJobStats.total_amount_missed;
      this.totalAmountEarned = userJobStats.total_amount_earned;
      this.startTime = userJobStats.start_time;
      this.marketplace = userJobStats.marketplace;
    }
  }
}

export default UserJobStatsModel;
