import React, { FormEvent } from 'react';
import classes from './selectFilter.module.scss';
import FilterIcon from '../../../images/filter-icon.svg';
import ArrowsIcon from '../../../images/filter-arrow.svg';

interface SelectFilterProps {
  options?: { label: string, value: any }[];
  onSelect?: (event: FormEvent<HTMLSelectElement>) => void;
  value?: { label: string, value: any };
}

const SelectFilter = ({ options = [], onSelect, value }: SelectFilterProps) => (
  <div className={classes.select_filter__filter}>
    <img src={FilterIcon} alt="filter" />
    <select id="select" className={classes.select_filter__selector} onChange={onSelect} value={value?.value}>
      {options.map((o: { label: string, value: any }) => (
        <option key={o.label} value={o.value}>
          {o.label}
        </option>
      ))}
    </select>
    <img src={ArrowsIcon} alt="arrows" />
  </div>
);

export default SelectFilter;
