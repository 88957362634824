import { action, makeAutoObservable } from 'mobx';
import moment from 'moment';
import { MarketplaceQualification } from '../constants/PerformanceDashboard';
import JobInfoModel from '../models/JobInfoModel';
import UserHighlightsModel from '../models/UserHighlightsModel';
import UserJobStatsModel from '../models/UserJobStatsModel';

class PerformanceDashboardStore {
  userPerformanceData = {};
  userHighlights = {};
  completedJobs = [];
  missedJobs = [];
  userId = null;
  isMarketplaceUser = false;

  constructor(apiService) {
    this.apiService_ = apiService;
    this.loading = false;
    this.error = null;
    makeAutoObservable(this);
  }

  set isLoading(value) {
    this.loading = value;
  }

  get startedAt() {
    if (this.userHighlights && this.userHighlights.startedAt) {
      return moment(this.userHighlights.startedAt);
    }
    return undefined;
  }

  set isConnectUser(value) {
    this.isMarketplaceUser = value;
  }

  async fetchIsUserConnect(userId) {
    this.userId = userId;
    if (userId) {
      const res = await this.apiService_.getUser(userId);
      const user = res.data;
      const isConnect = user
        && user.marketplace_qualification === MarketplaceQualification.Eligible;
      this.isConnectUser = isConnect;
      return isConnect;
    }
    return false;
  }

  fetchPerformanceStats(userId, startTime, range, marketplace) {
    this.isLoading = true;
    return this.apiService_.getUserPerformance(userId, startTime, range, marketplace)
      .then(action((data) => {
        this.userPerformanceData = new UserJobStatsModel(data);
      }))
      .catch(((error) => {
        this.error = error;
        this.userPerformanceData = new UserJobStatsModel();
      }))
      .finally(() => {
        this.isLoading = false;
      });
  }

  fetchUserHighlights(userId) {
    this.isLoading = true;
    return this.apiService_.getUserHighlights(userId)
      .then(action((data) => {
        this.userHighlights = new UserHighlightsModel(data);
      }))
      .catch(((error) => {
        this.error = error;
        this.userHighlights = new UserHighlightsModel();
      }))
      .finally(() => {
        this.isLoading = false;
      });
  }

  fetchUserCompletedJobs(userId, from, to, marketplace) {
    this.isLoading = true;
    return this.apiService_.getUserCompletedJobs(userId, from, to, marketplace)
      .then(action((data) => {
        this.completedJobs = data.map((jobInfo) => new JobInfoModel(jobInfo));
      }))
      .catch(((error) => {
        this.error = error;
        this.completedJobs = [];
      }))
      .finally(() => {
        this.isLoading = false;
      });
  }

  fetchUserMissedJobs(userId, from, to, marketplace) {
    this.isLoading = true;
    return this.apiService_.getUserMissedJobs(userId, from, to, marketplace)
      .then(action((data) => {
        this.missedJobs = data.map((jobInfo) => new JobInfoModel(jobInfo));
      }))
      .catch(((error) => {
        this.error = error;
        this.missedJobs = [];
      }))
      .finally(() => {
        this.isLoading = false;
      });
  }
}

export default PerformanceDashboardStore;
