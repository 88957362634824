/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
class Logger {
  log(...messages) {
    console.log(...messages);
  }

  info(...messages) {
    console.info(...messages);
  }

  warn(...messages) {
    console.warn(...messages);
  }

  error(...messages) {
    console.error(...messages);
  }
}

const logger = new Logger();

export default logger;
export {
  Logger,
};
