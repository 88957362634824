import * as LDClient from 'launchdarkly-js-client-sdk';
import { merge } from 'lodash';

import config from '../../config';

let ldClientInstance;

function getLdClient(user = {}) {
  return new Promise((resolve, reject) => {
    if (!ldClientInstance) {
      ldClientInstance = LDClient.initialize(
        config.LAUNCHDARKLY_CLIENT_ID,
        merge({}, user),
      );

      ldClientInstance.on('ready', () => { resolve(ldClientInstance); });
    } else {
      ldClientInstance.identify(user, null, () => { resolve(ldClientInstance); });
    }

    ldClientInstance.on('failed', reject);
    ldClientInstance.on('error', reject);
  });
}

export default getLdClient;
