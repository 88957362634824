/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { isEmpty } from 'lodash';
import { compose } from 'lodash/fp';

import MySiteDashboardView from './MySiteDashboardView';
import MySiteStatusView from './MySiteStatusView';
import MySiteAreaView from './MySiteAreaView';
import Availability from './Availability';
import DayAvailability from './DayAvailability';
import MySiteBasicSettingsView from './MySiteBasicSettingsView';
import DailyAvailabilityHours from './DailyAvailabilityHours';
import Skillset from './Skillset';
import MySiteSkillsView from './MySiteSkillsView';
import JobTypes from './JobTypes';
import MySiteReviewsView from './MySiteReviewsView';
import FeatureFlagRoute from '../../components/FeatureFlagRoute';
import { useFeatureFlag, FeatureIds } from '../../components/FeatureFlagsProvider';
import NotFoundView from '../NotFoundView/NotFoundView';
import CarBrandRoutes from './CarBrandSettings/CarBrandRoutes';

interface MySiteRoutesProps {
  match: {
    path: string,
    params: {
      userId: string,
    }
  },
  isOnboarding?: boolean
  mySiteSettingsStore: any
  connectTechSettingsStore: any,
  reviewsStore: any,
  categories: [],
  setCategories: any,
}

const MySiteRoutes = (
  {
    match, mySiteSettingsStore, connectTechSettingsStore, isOnboarding, categories,
    setCategories, reviewsStore,
  }: MySiteRoutesProps,
) => {
  const { userId } = match.params;
  const enableAutoBrandPresets = useFeatureFlag(FeatureIds.DEFAULT_AUTO_BRANDS);

  useEffect(() => {
    !connectTechSettingsStore.independentProfileStore
    && connectTechSettingsStore.fetchConnectTechSettings(userId);

    !reviewsStore.reviews && reviewsStore.fetchReviews(userId);
    // reset error states when navigating across pages
    connectTechSettingsStore.resetErrors();

    isEmpty(mySiteSettingsStore.mySite) && mySiteSettingsStore.fetchMySiteSettings(userId);
  }, [match, connectTechSettingsStore, mySiteSettingsStore, reviewsStore]);

  return (
    <Switch>
      <Route exact path={`${match.path}/availability/:day`} component={DayAvailability} />
      <Route
        exact
        path={`${match.path}/availability`}
        render={(props) => <Availability {...props} isOnboarding={isOnboarding} />}
      />
      <Route
        path={`${match.path}/skillset/:skillId/carbrands`}
        render={(props) => <CarBrandRoutes {...props} isOnboarding={isOnboarding} />}
      />
      <Route
        exact
        path={`${match.path}/skillset/:occupationId/subcategory/:subcategoryId`}
        render={(props) => (
          <Skillset
            {...props}
            isOnboarding={isOnboarding}
            enableAutoBrandPresets={enableAutoBrandPresets}
          />
        )}
      />
      <Route exact path={`${match.path}/dailyavailability`} component={DailyAvailabilityHours} />
      <Route exact path={`${match.path}/status`} component={MySiteStatusView} />
      <Route exact path={`${match.path}/area`} component={MySiteAreaView} />
      <Route exact path={`${match.path}/skillset`} component={MySiteSkillsView} />
      <Route exact path={`${match.path}/basic`} component={MySiteBasicSettingsView} />
      <FeatureFlagRoute exact path={`${match.path}/reviews`} component={MySiteReviewsView} fallback={<NotFoundView />} flag="micrositesPhase2" />
      {isOnboarding && (
      <Route
        exact
        path={`${match.path}`}
        render={(props) => (
          <JobTypes
            {...props}
            setCategories={setCategories}
            categories={categories}
            isOnboarding
          />
        )}
      />
      )}
      <Route exact path={match.path} component={MySiteDashboardView} />
    </Switch>
  );
};

export default compose(
  withRouter,
  inject('connectTechSettingsStore'),
  inject('mySiteSettingsStore'),
  inject('reviewsStore'),
  observer,
)(MySiteRoutes);
