import React from 'react';
import classes from './button.module.scss';

interface ButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>,
  label: string,
  icon?: string,
  className?: string,
  disabled?: boolean,
}

const Button = ({
  onClick, label, icon, className, disabled,
}: ButtonProps) => (
  <button type="button" onClick={onClick} className={`${classes.button} ${className}`} disabled={disabled}>
    {icon
      && <img src={icon} alt={label} />}
    {label}
  </button>
);

export default Button;
