import {
  observable,
  runInAction,
  makeObservable,
  action,
  toJS,
} from 'mobx';

import IndependentProfileStore from './IndependentProfileStore';
import BasicProfileStore from './BasicProfileStore';
import LocationSettingsModel from '../models/LocationSettingsModel';
import SkillsetModel from '../models/SkillsetModel';

const AUTO_OCCUPATION_ID = 1;
const AUTO_CATEGORY_ID = 3;

class ConnectTechSettingsStore {
  independentProfileStore = null;
  basicProfileStore = null;
  occupationsStore = null;
  locationSettings = {
    locationServicesEnabled: null,
    baseZip: null,
  };
  error = false;
  loading = false;
  occupationId = null;
  data = {};

  constructor(apiService, occupationsStore) {
    this.apiService = apiService;
    this.loading = false;
    this.userId = null;
    this.occupationsStore = occupationsStore;

    makeObservable(this, {
      loading: observable,
      userId: observable,
      error: observable,
      locationSettings: observable,
      data: observable,

      independentProfileStore: observable,
      basicProfileStore: observable,
      occupationsStore: observable,

      fetchConnectTechSettings: action,
      resetErrors: action,
      setOccupationSkills: action,
    });
  }

  get isLoading() {
    return this.loading;
  }

  set isLoading(loading) {
    runInAction(() => {
      this.loading = loading;
    });
  }

  get isError() {
    return this.error;
  }

  set isError(error) {
    runInAction(() => {
      this.error = error;
    });
  }

  getPageItems(occupationId, subcategoryId) {
    const occupation = this.occupationsStore.getOccupation(parseInt(occupationId, 10));
    const subcategory = occupation.categories.find(
      (c) => c.id === subcategoryId,
    );
    const activeServices = subcategory.services.filter((s) => s.active);

    return activeServices.map((service) => {
      const matchSkill = this.basicProfileStore.basicProfile.skillSet.find(
        (s) => service.id === s.job_service_id,
      );
      return {
        ...service,
        skillId: matchSkill ? matchSkill.id : null,
        enabled: !!matchSkill,
      };
    });
  }

  getJobSkillset() {
    const userOccupation = this.occupationId;
    const occupation = userOccupation
      && this.occupationsStore.getOccupation(parseInt(userOccupation, 10));

    if (occupation && !occupation.categories) {
      return [];
    }
    const techSkillset = this.basicProfileStore
      && this.basicProfileStore.basicProfile.skillSet.reduce((acc, skill) => ({
        ...acc,
        [skill.occupation_id]: {
          ...acc[skill.occupation_id],
          [skill.job_sub_category_id]: {
            numSkills: (
              (acc[skill.occupation_id] && acc[skill.occupation_id][skill.job_sub_category_id])
                ? acc[skill.occupation_id][skill.job_sub_category_id].numSkills
                : 0
            ) + 1,
          },
        },
      }), {});
    const enabledSkills = techSkillset
      && techSkillset[userOccupation]
      && Object.keys(techSkillset[userOccupation])
        .filter((k) => techSkillset[userOccupation][k].numSkills > 0);

    return enabledSkills
      ? new SkillsetModel(occupation, [])
        .categories.map((c) => {
          if (enabledSkills.includes(c.id.toString())) {
            return { ...c, enabled: true };
          }
          return c;
        })
      : new SkillsetModel(occupation, []).categories;
  }

  // Actions ------------------------------------

  fetchConnectTechSettings(userId) {
    this.isLoading = true;
    this.isError = false;

    this.userId = userId;

    this.getOccupation();

    return Promise.all([
      this.apiService.getConnectTechSettings(userId),
      this.occupationsStore.loadOccupations(),
    ]).then(action(([settings]) => {
      this.independentProfileStore = new IndependentProfileStore(
        this.apiService,
        userId,
        settings.independent_profile,
      );
      this.basicProfileStore = new BasicProfileStore(
        this.apiService,
        userId,
        settings.basic_profile,
      );
      this.notificationSettings = {
        textEnabled: settings.basic_profile.is_user_subscribe_marketplace_update,
      };
      this.locationSettings = new LocationSettingsModel(
        settings.location_services,
      );

      settings.basic_profile.skill_set.forEach((skill) => {
        if (
          skill.occupation_id === AUTO_OCCUPATION_ID
          && skill.job_sub_category_id === AUTO_CATEGORY_ID
        ) {
          this.basicProfileStore.fetchAutobrands(skill.id);
        }
      });
    })).catch(() => {
      this.isError = true;
    }).finally(() => {
      this.isLoading = false;
    });
  }

  updateBaseZip(newBaseZip) {
    if (this.locationSettings.baseZip !== newBaseZip) {
      // Convert form a Mobx proxied object to a plain JS object
      const plainBasicProfile = toJS(this.basicProfileStore.basicProfile);
      const basicProfilePayload = {
        ...plainBasicProfile,
        base_zip_code: newBaseZip,
        skill_set: plainBasicProfile.skillSet,
      };

      this.isError = false;
      this.apiService.updateBasicProfile(
        this.userId,
        { ...basicProfilePayload },
      ).then(action(() => {
        this.basicProfileStore.basicProfile.baseZip = newBaseZip;
      })).catch(() => {
        this.isError = true;
      });
    }
  }

  getOccupation() {
    this.apiService.getUser(this.userId)
      .then(action(({ data }) => {
        this.data = data;
        this.occupationId = data.occupation_id;
      }));
  }

  upload(file) {
    this.apiService.upload(file, this.userId)
      .then(action(({ data }) => {
        const { url } = data;
        if (url) {
          this.apiService.setUser({ ...this.data, profile_pic_url: url })
            .then(action(({ data }) => {
              this.data = data;
            }));
        }
      }));
  }

  resetErrors() {
    this.isError = false;
    if (this.independentProfileStore) {
      this.independentProfileStore.isError = false;
    }
    if (this.basicProfileStore) {
      this.basicProfileStore.isError = false;
    }
  }

  setOccupationSkills(newCategories) {
    this.occupationCategories = newCategories;
  }
}

export default ConnectTechSettingsStore;
