import React from 'react';
import { Switch, Route } from 'react-router-dom';

import withAnalytics from './components/withAnalytics';
import FeatureFlagRoute from './components/FeatureFlagRoute';

import reportError from './lib/reportError';
import logger from './lib/logger';

import MySiteRoutes from './views/MySiteDashboard/MySiteRoutes';
import OnboardingFlow from './views/MySiteDashboard/OnboardingAutomation/OnboardingFlow';
import NotFoundView from './views/NotFoundView/NotFoundView';
import PerformanceDashboardRoutes from './views/PerformanceDashboard/PerformanceDashboardRoutes';
import CancelJob from './views/CancelJob/CancelJob';

class App extends React.Component {
  componentDidCatch(error: Error) {
    logger.error(error);

    reportError(error);
  }

  render() {
    return (
      <Switch>
        <Route path="/mysite/:userId/onboarding" component={OnboardingFlow} />
        <Route path="/mysite/:userId" component={MySiteRoutes} />
        <Route path="/performance/:userId" component={PerformanceDashboardRoutes} />
        <FeatureFlagRoute
          path="/cancel-job/:jobId/:command(update|reject|revoke)/:reasonId?/:status(complete|quit)?"
          component={CancelJob}
          fallback={<NotFoundView />}
          flag="cancelReasonV2WebViews"
        />
        <Route path="/" component={NotFoundView} />
      </Switch>
    );
  }
}

export default withAnalytics()(App);
