import React from 'react';
import moment from 'moment';
import RangeGrid from '../RangeGrid/RangeGrid';
import classes from './monthRangePicker.module.scss';

interface MonthRangeGridProps {
  year: moment.Moment;
  selectedStart?: moment.Moment;
  selectedEnd?: moment.Moment;
  onSelected: (start?: moment.Moment, end?: moment.Moment) => void;
  minTime?: moment.Moment;
}
const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const momentToGridPayload = (
  time: moment.Moment,
  today: moment.Moment,
  minTime?: moment.Moment,
) => ({
  value: time.unix(),
  label: months[time.get('month')].substring(0, 3),
  disabled: time.isAfter(today) || !!(minTime && time.isBefore(minTime?.startOf('month'))),
});

const getMonthsArray = (year: number, minTime?: moment.Moment) => {
  const date = moment({ year, month: 0, day: 1 });
  const result = [];
  const now = moment();
  while (date.year() === year) {
    result.push(momentToGridPayload(date, now, minTime));
    date.set({ month: date.month() + 1 });
  }
  return result;
};

const getMonthDaysForCalendar = (momentInMonth: moment.Moment, minTime?: moment.Moment) => {
  const days = getMonthsArray(momentInMonth.get('year'), minTime);
  return days;
};

const MonthRangeGrid = ({
  year,
  selectedStart,
  selectedEnd,
  onSelected,
  minTime,
}: MonthRangeGridProps) => {
  const monthName = year.get('year');

  return (
    <>
      <div className={classes['month-range-grid__header']}>{monthName}</div>
      <RangeGrid
        selectedStart={selectedStart?.unix()}
        selectedEnd={selectedEnd?.unix()}
        columnsCount={4}
        items={getMonthDaysForCalendar(year.clone(), minTime)}
        onSelected={(val) => {
          onSelected(moment.unix(val), moment.unix(val));
        }}
        singlePick
      />
    </>
  );
};

export default MonthRangeGrid;
