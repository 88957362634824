import React from 'react';
import { Switch } from 'react-router';
import JobInfoView from './JobInfoView/JobInfoView';
import PerformanceDashboardView from './PerformanceDashboardView/PerformanceDashboardView';
import RangeSelectorView from './RangeSelectorView/RangeSelectorView';
import TrackedRoute from '../../components/TrackedRoute/TrackedRoute';

const PerformanceDashboardRoutes = ({ match }: any) => (
  <Switch>
    <TrackedRoute
      pageName="Closed Jobs Page"
      path={`${match.path}/closed_jobs`}
      component={JobInfoView}
      render={(props: any) => <JobInfoView {...props} closedJobs />}
    />
    <TrackedRoute
      pageName="Missed Jobs Page"
      path={`${match.path}/missed_jobs`}
      component={JobInfoView}
    />
    <TrackedRoute
      pageName="Range Selector"
      path={`${match.path}/select_range`}
      component={RangeSelectorView}
    />
    <TrackedRoute
      pageName="Performance Dashboard"
      path={match.path}
      component={PerformanceDashboardView}
    />
  </Switch>
);

export default PerformanceDashboardRoutes;
