/* eslint-disable no-param-reassign */

const maxLength = 30;

function formatForUrl(str) {
  str = str.slice(0, maxLength);
  str = str.replace(/^\s+|\s+$/g, '');
  str = str.toLowerCase();

  const specials = 'ąćżźłńśãàáäâáº½èéëêìíïîõòóöôùúüûñç·/_,:;';
  const regulars = 'aczzlnsaaaaaeeeeeiiiiooooouuuunc------';
  // eslint-disable-next-line no-plusplus
  for (let i = 0, l = specials.length; i < l; i++) {
    str = str.replace(new RegExp(specials.charAt(i), 'g'), regulars.charAt(i));
  }

  str = str.replace(/[^a-z0-9 -]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-');

  return str;
}

export default formatForUrl;
