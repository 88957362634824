import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import ToggleOnIcon from '../../images/icon-toggle-on.svg';
import ToggleOffIcon from '../../images/icon-toggle-off.svg';
import ArrowIcon from '../../images/icon-right-arrow-stone.svg';

import './ToggleItem.scss';

const ToggleItem = React.forwardRef(({
  title,
  subcomponent,
  link,
  enabled,
  onToggle,
  icon,
}, ref) => (
  <div className="toggle-item" ref={ref}>
    <div className={`toggle-item__top-section ${subcomponent && enabled ? 'toggle-item__top-section__flat' : ''}`}>
      <div className="toggle-item__top-section__title">
        {icon && <img src={icon} alt="" className="toggle-item__icon" />}
        { title }
      </div>
      <button type="button" className="toggle-item__top-section__btn" onClick={onToggle}>
        <img src={enabled ? ToggleOnIcon : ToggleOffIcon} alt={enabled ? 'on' : 'off'} />
      </button>
    </div>
    { subcomponent && enabled && (
      <>
        <div className="toggle-item__divider" />
        <Link to={link}>
          <div className="toggle-item__bottom-section">
            {subcomponent}
            <img className="toggle-item__bottom-section__arrow" src={ArrowIcon} alt="go to edit" />
          </div>
        </Link>
      </>
    )}
  </div>
));

ToggleItem.propTypes = {
  title: PropTypes.string,
  subcomponent: PropTypes.node,
  link: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      // eslint-disable-next-line react/forbid-prop-types
      state: PropTypes.object,
    }),
  ]),
  enabled: PropTypes.bool,
  onToggle: PropTypes.func,
  icon: PropTypes.string,
};

export default ToggleItem;
