import moment from 'moment';
import React from 'react';
import { List, AutoSizer } from 'react-virtualized';

interface RangeProviderProps {
  selectedStart?: moment.Moment;
  selectedEnd?: moment.Moment;
  totalRows: number;
  startIndex: number;
  onSelected: (start?: moment.Moment, end?: moment.Moment) => void;
  increment: 'month' | 'year';
  render: (
    onSelected: (start?: moment.Moment, end?: moment.Moment) => void,
    moment: moment.Moment,
    start?: moment.Moment,
    end?: moment.Moment
  ) => React.ReactNode;
  rowHeight?: number;
  increasing?: boolean;
}

function RangeProvider({
  selectedEnd,
  selectedStart,
  increment,
  render,
  onSelected,
  rowHeight,
  totalRows,
  startIndex,
  increasing,
}: RangeProviderProps) {
  const rowRenderer = ({ index, style, key }: any) => {
    const currRowIndex = startIndex - index;

    return (
      <div style={style} key={key}>
        {
          render(
            onSelected,
            increasing
              ? moment().startOf(increment).subtract(currRowIndex, increment)
              : moment().startOf(increment).add(currRowIndex, increment),
            selectedStart,
            selectedEnd,
          )
        }
      </div>
    );
  };

  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          in
          height={height}
          rowCount={totalRows}
          rowHeight={rowHeight || 200}
          rowRenderer={rowRenderer}
          scrollToIndex={startIndex}
          width={width}
        />
      )}
    </AutoSizer>
  );
}
export default RangeProvider;
