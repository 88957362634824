import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'lodash/fp';
import { useParams } from 'react-router';

import Button from '../../components/MySite/Button/Button';
import classes from './mySiteStatusView.module.scss';
import Input from '../../components/MySite/Input/Input';
import Layout from '../../components/MySite/Layout/Layout';
import { MAX_RADIUS, ZIP_CODE_MAX_LENGTH } from '../../constants/ConnectTechSettings';

interface MySiteAreaViewProps {
  mySiteSettingsStore: {
    mySite: {
      work_radius: string,
    },
    updateMySiteSettings: any,
  },
  connectTechSettingsStore: {
    basicProfileStore: {
      basicProfile: {
        baseZip: string,
      },
    },
    updateBaseZip: any,
  },
}

const MySiteAreaView = ({ connectTechSettingsStore, mySiteSettingsStore }
: MySiteAreaViewProps) => {
  const { userId } = useParams<{ userId: string }>();
  const zipCodeInit = (connectTechSettingsStore.basicProfileStore
    && connectTechSettingsStore.basicProfileStore.basicProfile.baseZip) || '';

  const radiusInit = mySiteSettingsStore.mySite.work_radius || '';

  const [zipCode, setZipCode] = useState(zipCodeInit);
  const [radius, setRadius] = useState(radiusInit);

  useEffect(() => (
    setRadius(radiusInit)
  ), [radiusInit]);

  useEffect(() => (
    setZipCode(zipCodeInit)
  ), [zipCodeInit]);

  const matchNumbers = /^\d+$/;

  const handleZipCodeChange = (value: string): void => {
    if (matchNumbers.test(value) || value === '') {
      setZipCode(value.substring(0, ZIP_CODE_MAX_LENGTH));
    }
  };

  const handleRadiusChange = (value: string): void => {
    if (matchNumbers.test(value) || value === '') {
      const newValue = value.charAt(0) === '0' ? value.substring(1) : value;

      setRadius(Number(newValue) < MAX_RADIUS ? newValue : `${MAX_RADIUS}`);
    }
  };

  const handleSubmit = () => {
    if (zipCode !== zipCodeInit) {
      connectTechSettingsStore.updateBaseZip(zipCode);
    }
    if (radius !== radiusInit) {
      mySiteSettingsStore.updateMySiteSettings({ work_radius: radius });
    }
  };

  const canSubmit = ((zipCode !== zipCodeInit) || (radius !== radiusInit)) && zipCode !== '' && radius !== '';

  return (
    <Layout userId={userId} title="Service area">
      <>
        <div className={classes.content}>
          <div className={classes.inputs}>
            <Input onChange={(e: { target: HTMLInputElement }) => { handleZipCodeChange(e.target.value); }} label="Base Zip" value={zipCode} name="zipCode" id="zipCode" />
            <Input onChange={(e: { target: HTMLInputElement }) => { handleRadiusChange(e.target.value); }} label="Drive distance (mi.)" value={radius} name="radius" id="radius" />
          </div>
          <span className={classes.copy}>
            {'Base zip setting will be also updated '
            + 'under your account information and connect profile.'}
          </span>
        </div>
        <Button onClick={() => handleSubmit()} label="Save" className={classes.button} disabled={!canSubmit} />
      </>
    </Layout>
  );
};

export default compose(
  inject(({ mySiteSettingsStore, connectTechSettingsStore }) => ({
    mySiteSettingsStore,
    connectTechSettingsStore,
  })),
  observer,
)(MySiteAreaView);
