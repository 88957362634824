/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject, PropTypes as MobxPropTypes } from 'mobx-react';
import { compose } from 'lodash/fp';
import _ from 'lodash';

import withProps from '../../components/withProps';
import ToggleItem from '../../components/ToggleItem/ToggleItem';
import LoadingIcon from '../../images/transparent-spinner.gif';
import BackArrowIcon from '../../images/icon-left-arrow.svg';
import SearchIcon from '../../images/icon-search.svg';

import './CarBrands.scss';
import './Skillset.scss';

class CarBrands extends Component {
  constructor(props) {
    super(props);

    this.state = {
      carBrands: [],
    };
  }

  componentDidMount() {
    const { connectTechSettingsStore, match } = this.props;

    connectTechSettingsStore.fetchConnectTechSettings(match.params.userId)
      .then(() => {
        const { basicProfileStore, skillId } = this.props;
        basicProfileStore.fetchAutobrands(skillId).then((brands) => {
          this.setPageBrands(brands);
        });
      });
  }

  onToggle(brand) {
    const data = {
      brand_id: brand.id,
      year_to: null,
      year_from: null,
    };
    const {
      skillId,
      basicProfileStore,
    } = this.props;

    if (!brand.enabled) {
      basicProfileStore.createUserSkillAutobrand(
        skillId,
        data,
      );
    } else {
      basicProfileStore.removeUserSkillAutobrand(
        skillId,
        brand.id,
      );
    }
    this.setPageBrands(basicProfileStore.autobrandsBySkill[skillId]);
  }

  onSearch(val) {
    const { skillId, basicProfileStore } = this.props;
    const brands = basicProfileStore.autobrandsBySkill[skillId].filter(
      (b) => b.brand_name.toLowerCase().startsWith(val.toLowerCase().trim()),
    );
    this.setPageBrands(brands);
  }

  onBack = () => {
    const { basicProfileStore, skillId, history } = this.props;
    if (
      !basicProfileStore.autobrandsBySkill[skillId]
      || basicProfileStore.autobrandsBySkill[skillId].filter(
        (brand) => brand.enabled,
      ).length === 0
    ) {
      let newSkillset = _.cloneDeep(basicProfileStore.skillset);
      newSkillset = newSkillset.filter((s) => s.id !== skillId);
      basicProfileStore.skillset = newSkillset;
    }
    history.goBack();
  };

  setPageBrands(brands) {
    this.setState({ carBrands: brands });
  }

  selectAllBrands = () => {
    const { skillId, basicProfileStore } = this.props;
    const { carBrands } = this.state;
    basicProfileStore.selectAllBrands(skillId);
    this.setPageBrands(carBrands.map((b) => ({ ...b, enabled: true })));
  };

  clearBrands = () => {
    const { skillId, basicProfileStore } = this.props;
    const { carBrands } = this.state;
    basicProfileStore.clearBrands(skillId);
    this.setPageBrands(carBrands.map((b) => ({ ...b, enabled: false })));
  };

  renderToggles() {
    const {
      location: {
        state: { title },
      },
    } = this.props;
    const { carBrands } = this.state;

    return (carBrands || [])
      .slice()
      .sort((a, b) => (a.brand_name > b.brand_name ? 1 : -1))
      .map((brand) => {
        let years = 'All Years';
        if (!brand.year_from && brand.year_to) {
          years = `Until ${brand.year_to}`;
        } else if (brand.year_from) {
          years = `${brand.year_from} - ${brand.year_to || 'Present'}`;
        }

        if (
          brand.year_from === 1950
          && brand.year_to === new Date().getFullYear()
        ) {
          years = 'All Years';
        }

        return (
          <ToggleItem
            key={brand.brand_name}
            title={brand.brand_name}
            subcomponent={<div>{years}</div>}
            link={{
              pathname: `carbrands/${brand.id}`,
              state: {
                serviceTitle: title,
                brandName: brand.brand_name,
                startYear: brand.year_from,
                endYear: brand.year_to,
              },
            }}
            enabled={brand.enabled}
            onToggle={() => this.onToggle(brand)}
          />
        );
      });
  }

  render() {
    const { skillId, basicProfileStore, location } = this.props;
    const { title } = location.state;
    const { carBrands } = this.state;

    if (!basicProfileStore || !basicProfileStore.autobrandsBySkill[skillId]) {
      return (
        <img
          className="connect-tech-settings__loading"
          src={LoadingIcon}
          alt="Loading"
        />
      );
    }

    return (
      <div className="connect-tech-settings__car-brands">
        <div className="connect-tech-settings__car-brands__fixed">
          {
            (basicProfileStore.isError || !carBrands) && (
              <div className="connect-tech-settings__error">
                Something went wrong, try again.
              </div>
            )
          }
          <div className="connect-tech-settings__car-brands__page-title">
            {title}
          </div>
          <img
            className="connect-tech-settings__back-arrow"
            src={BackArrowIcon}
            alt="Back"
            onClick={this.onBack}
          />
          <div className="connect-tech-settings__car-brands__title">
            <div className="connect-tech-settings__title__header">
              Car Brands
            </div>
            <div className="connect-tech-settings__car-brands__title__actions">
              <div onClick={this.clearBrands}>
                Clear
              </div>
              <div onClick={this.selectAllBrands}>
                Select all
              </div>
            </div>
          </div>
          <img
            className="connect-tech-settings__car-brands__search-icon"
            src={SearchIcon}
            alt="search"
          />
          <input
            type="text"
            id="brand-search"
            placeholder="Search Brands"
            className="connect-tech-settings__car-brands__search"
            onChange={(e) => this.onSearch(e.target.value)}
          />
        </div>
        <div className="connect-tech-settings__car-brands__brand-toggles">
          {this.renderToggles()}
        </div>
        {basicProfileStore.isLoading && (
          <div className="connect-tech-settings__skillset__backdrop-high">
            <img
              className="connect-tech-settings__loading"
              src={LoadingIcon}
              alt="Loading"
            />
          </div>
        )}
      </div>
    );
  }
}
CarBrands.propTypes = {
  userId: PropTypes.string,
  skillId: PropTypes.number,
  basicProfileStore: PropTypes.shape({
    fetchAutobrands: PropTypes.func,
    createUserSkillAutobrand: PropTypes.func,
    removeUserSkillAutobrand: PropTypes.func,
    clearBrands: PropTypes.func,
    selectAllBrands: PropTypes.func,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    autobrandsBySkill: MobxPropTypes.observableObject,
    skillset: MobxPropTypes.observableArray,
  }),
  connectTechSettingsStore: PropTypes.shape({
    fetchConnectTechSettings: PropTypes.func,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      userId: PropTypes.string,
      skillId: PropTypes.string,
    }),
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({
      title: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }),
};

export default compose(
  withProps(({
    match: {
      params: { userId, skillId },
    },
  }) => ({
    userId,
    skillId: parseInt(skillId, 10),
  })),
  inject((stores) => ({
    basicProfileStore: stores.connectTechSettingsStore.basicProfileStore,
    occupationsStore: stores.connectTechSettingsStore.occupationsStore,
    connectTechSettingsStore: stores.connectTechSettingsStore,
  })),
  observer,
)(CarBrands);
