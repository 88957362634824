import React from 'react';

export interface LoadingConfig<TProps = any> {
  renderLoader: () => React.ReactNode;
  checkProps: (props: TProps) => boolean;
}

function withLoading<TProps = any>({ checkProps, renderLoader }: LoadingConfig<TProps>) {
  return (component: React.ComponentType<TProps>) => (
    (props: TProps) => {
      const Component = component as React.ComponentType<TProps>;

      return checkProps(props)
        ? <Component {...(props as JSX.IntrinsicAttributes & TProps)} />
        : renderLoader();
    }
  );
}

export default withLoading;
