import { formatDate } from '../lib/date';

enum RangeType {
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export const getRangeStringFromEnum = (range: RangeType) => {
  switch (range) {
    case RangeType.WEEK:
      return 'WEEK';
    case RangeType.MONTH:
      return 'MONTH';
    case RangeType.YEAR:
      return 'YEAR';
    default:
      return 'WEEK';
  }
};

export const cleanJobRefId = (referenceId: string) => {
  let cleanRefIdCopy = referenceId || '';
  if (cleanRefIdCopy.length > 3 && cleanRefIdCopy.startsWith('JF7')) {
    cleanRefIdCopy = cleanRefIdCopy.slice(3);
  }
  const cleanRefId = cleanRefIdCopy;
  return cleanRefId;
};

export const colors = {
  JOBOX_BLUE: '#0075FF',
  GREY: '#EEEEF4',
  DISABLED: '#645B83',
  AXIS_STROKE: '#A29BBA',
};

export const formatDateForJobCard = (date: Date) => `${date.toLocaleString('en-US', {
  year: '2-digit',
  month: '2-digit',
})} ${date.toLocaleString('en-US', {
  hour: '2-digit',
  minute: '2-digit',
}).toLocaleLowerCase()}`;

// using en-BZ for dd/MM since en-US is MM/dd
export const formatJobViewDate = (from: Date, to: Date) => `${formatDate(from, 'MM/DD')} - ${formatDate(to, 'MM/DD')}`;

export const formatAddress = (address: string | undefined) => {
  if (!address) {
    return address;
  }
  const indexToSplit = address.split(',', 2).join(',').length;
  return address.slice(0, indexToSplit);
};

export const constants = {
  PERFORMANCE_DASH_ARTICLE_URL: 'https://intercom.help/jobox/en/articles/5604637-performance-dashboard',
};

export enum MarketplaceQualification {
  Qualified = 'Qualified',
  WaitingList = 'WaitingList',
  Eligible = 'Eligible',
}

export const INFO = {
  COMPLETED: {
    header: 'Earnings',
    subheader: 'Calculated for all jobs',
    listOfPoints: [
      'Individual job earning is calculated as your job cut plus your tip',
    ],
  },
  MISSED: {
    header: 'Missed Earnings',
    subheader: 'Calculated only for Jobox Connect job offers',
    listOfPoints: [
      'Job offer is considered missed if you haven’t responded to it in time',
      'Total missed earnings are calculated by multiplying the number of missed job offers with average earning for that type of job',
    ],
  },
};

export default RangeType;
