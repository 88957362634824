import React from 'react';
import classes from './header.module.scss';
import Logo from '../Logo/Logo';
import config from '../../../config';

interface HeaderProps {
  name: string,
  url: string,
  avatar?: string,
}

const Header = ({ name, url, avatar }: HeaderProps) => (
  <header className={classes.header}>
    <Logo avatar={avatar} />
    <div className={classes.headerContent}>
      <span className={classes.name}>{name}</span>
      <span className={classes.url}>{`${config.MYSITE_URL}/${url}`}</span>
    </div>
  </header>
);

export default Header;
