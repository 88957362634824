import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import cookieParser from 'cookie';
import { Provider } from 'mobx-react';
import 'bootstrap/dist/css/bootstrap.css';
import 'semantic-ui-css/semantic.min.css';
import './styles/style.scss';
import './styles/react-components-library/main.scss';
import moment from 'moment-timezone';

import App from './App';
import FeatureFlagsProvider from './components/FeatureFlagsProvider';

import ApiService from './services/ApiService';
import createAxiosInstance from './lib/axios';

import ConnectTechSettingsStore from './stores/ConnectTechSettingsStore';
import MySiteStoreSettingsStore from './stores/MySiteStore';
import OccupationsStore from './stores/OccupationsStore';
import PerformanceDashboardStore from './stores/PerformanceDashboardStore';
import CancelJobStore from './stores/CancelJobStore';
import ReviewsStore from './stores/ReviewsStore';

const cookies = cookieParser.parse(document.cookie);

const authApiService = new ApiService(createAxiosInstance({
  baseURL: '/api',
  headers: {
    Authorization: cookies.Authorization || '',
    'User-Id': cookies['User-Id'] || '',
    Timestamp: cookies.Timestamp || '',
    platform: cookies.Platform || 'UNKNOWN',
  },
}));

moment.tz.setDefault('America/Los_Angeles');

const occupationsStore = new OccupationsStore(authApiService);

const stores = {
  connectTechSettingsStore: new ConnectTechSettingsStore(authApiService, occupationsStore),
  performanceDashboardStore: new PerformanceDashboardStore(authApiService),
  mySiteSettingsStore: new MySiteStoreSettingsStore(authApiService),
  cancelJobStore: new CancelJobStore(authApiService),
  reviewsStore: new ReviewsStore(authApiService),
};

ReactDOM.render(
  <Provider {...stores}>
    <BrowserRouter>
      <FeatureFlagsProvider>
        <App />
      </FeatureFlagsProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);
