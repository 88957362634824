import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { compose } from 'lodash/fp';
import { Link, withRouter } from 'react-router-dom';
import classes from './rangeSelectorView.module.scss';
import BackArrow from '../../../images/back-arrow.svg';
import RangeProvider from '../../../components/DatePicker/RangeProvider/RangeProvider';
import DateRangeGrid from '../../../components/DatePicker/DateRangeGrid/DateRangeGrid';
import MonthRangeGrid from '../../../components/DatePicker/MonthRangeGrid/MonthRangeGrid';
import YearRangeGrid from '../../../components/DatePicker/YearRangeGrid/YearRangeGrid';
import withProps from '../../../components/withProps';
import PerformanceDashboardStore from '../../../stores/PerformanceDashboardStore';
import UserHighlightsModel from '../../../models/UserHighlightsModel';
import { getRangeStringFromEnum } from '../../../constants/PerformanceDashboard';

interface RangeSelectorViewProps {
  userId: number;
  currentRange?: {
    from: moment.Moment,
    to: moment.Moment,
    rangeType: string,
    minTime: moment.Moment,
    marketplace: boolean,
  };
  performanceDashboardStore: PerformanceDashboardStore;
}

const RangeSelectorView = ({
  userId,
  currentRange,
  performanceDashboardStore,
}: RangeSelectorViewProps) => {
  const [rangeType, setRangeType] = useState<'week' | 'month' | 'year'>('week');
  const [start, setStart] = useState<moment.Moment>(moment().startOf('isoWeek'));
  const [end, setEnd] = useState<moment.Moment>(moment().endOf('isoWeek'));
  const onSelect = (start?: moment.Moment, end?: moment.Moment) => {
    if (start) setStart(start);
    if (end) setEnd(end);
  };
  const setRange = (type: 'week' | 'month' | 'year') => {
    setRangeType(type);
  };

  const getRowsForMonths = () => {
    const userHighlights = performanceDashboardStore
      && performanceDashboardStore.userHighlights
      && (performanceDashboardStore.userHighlights as UserHighlightsModel);
    let val = 500;
    if (userHighlights && userHighlights.startedAt) {
      val = Math.floor(Math.abs(moment(userHighlights.startedAt).diff(moment(), 'months', true)));
      val = val === 0 ? 500 : val * 2;
    }
    return val;
  };

  const totalMonthsToDisplay = getRowsForMonths();

  return (
    <>
      <div className={classes.range_selector_view__header}>
        <Link
          to={{
            pathname: `/performance/${userId}`,
            state: {
              startTime: currentRange && currentRange.from && currentRange.from.unix(),
              endTime: currentRange && currentRange.to && currentRange.to.unix(),
              rangeType: currentRange && currentRange.rangeType && currentRange.rangeType,
              marketplace: currentRange && currentRange.marketplace,
            },
          }}
        >
          <img className={classes.range_selector_view__arrow} src={BackArrow} alt="back" />
        </Link>
        <div className={classes.range_selector_view__header_content}>
          <div className={classes.range_selector_view__title}>Select time range</div>
        </div>
        <div className={classes.range_selector_view__arrow} />
      </div>
      <div className={classes.range_selector_view__range_type_selector}>
        <button
          type="button"
          onClick={() => setRange('week')}
          className={`${classes.range_selector_view__range_type_selection} ${
            rangeType === 'week' && classes.range_selector_view__range_type_selected
          }`}
        >
          Week
        </button>
        <button
          type="button"
          onClick={() => setRange('month')}
          className={`${classes.range_selector_view__range_type_selection} ${
            rangeType === 'month' && classes.range_selector_view__range_type_selected
          }`}
        >
          Month
        </button>
        <button
          type="button"
          onClick={() => setRange('year')}
          className={`${classes.range_selector_view__range_type_selection} ${
            rangeType === 'year' && classes.range_selector_view__range_type_selected
          }`}
        >
          Year
        </button>
      </div>
      <div
        className={classes.range_selector_view__range_provider}
      >
        {rangeType === 'week' && (
          <RangeProvider
            render={(onSelected, moment, start, end) => (
              <DateRangeGrid
                month={moment}
                selectedEnd={end}
                selectedStart={start}
                onSelected={onSelected}
                selectWeeks
              />
            )}
            selectedStart={start}
            selectedEnd={end}
            onSelected={onSelect}
            increment="month"
            rowHeight={44 * 7}
            startIndex={totalMonthsToDisplay / 2}
            totalRows={totalMonthsToDisplay}
            increasing
          />
        )}
        {rangeType === 'month' && (
          <RangeProvider
            render={(onSelected, moment, start, end) => (
              <MonthRangeGrid
                year={moment}
                selectedEnd={end}
                selectedStart={start}
                onSelected={onSelected}
                minTime={currentRange && currentRange.minTime}
              />
            )}
            selectedStart={start}
            selectedEnd={end}
            onSelected={onSelect}
            increment="year"
            startIndex={0}
            totalRows={250}
          />
        )}
        {rangeType === 'year' && (
          <YearRangeGrid
            numYears={12}
            selectedEnd={end}
            selectedStart={start}
            onSelected={onSelect}
            minTime={currentRange && currentRange.minTime}
          />
        )}
      </div>
      <Link
        to={{
          pathname: `/performance/${userId}`,
          state: {
            startTime: start?.unix(),
            endTime: end?.unix(),
            rangeType: rangeType.toUpperCase(),
            marketplace: currentRange && currentRange.marketplace,
          },
        }}
      >
        <button className={classes.range_selector_view__apply_button} type="button">
          APPLY
        </button>
      </Link>
    </>
  );
};

export default compose(
  withProps((props) => {
    const { match: { params } } = props;
    const { location: { state } } = props;
    return {
      ...props,
      userId: params.userId,
      currentRange: {
        from: state && state.from ? moment(state.from) : undefined,
        to: state && state.to ? moment(state.to) : undefined,
        rangeType: state && state.rangeType
          ? getRangeStringFromEnum(state.rangeType).toUpperCase() : undefined,
        minTime: state && state.minTime ? moment(state.minTime) : undefined,
        marketplace: state && state.marketplace,
      },
    };
  }),
  withRouter,
  inject('performanceDashboardStore'),
  observer,
)(RangeSelectorView);
