class ActionStatus {
  // Keys ---------------------------------------
  static Idle = 'IDLE';

  static Success = 'SUCCESS';

  static Error = 'ERROR';

  static Loading = 'LOADING';

  // Creation -----------------------------------
  static createIdle() {
    return new ActionStatus(ActionStatus.Idle);
  }

  static createLoading() {
    return new ActionStatus(ActionStatus.Loading);
  }

  static createSuccess() {
    return new ActionStatus(ActionStatus.Success);
  }

  static createError(message, errorCode) {
    const errorStatus = new ActionStatus(ActionStatus.Error);
    errorStatus.errorMessage = message || 'Unknown error';
    errorStatus.errorCode = errorCode || 500;
    return errorStatus;
  }

  // Compare ------------------------------------
  static is(statusKey, status) {
    return status && status.status === statusKey;
  }

  constructor(status) {
    this.status = status;
  }
}

export default ActionStatus;
