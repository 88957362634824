import React from 'react';
import classes from './logo.module.scss';

import logoPlaceholder from '../../../images/logoPlaceholder.svg';

interface LogoProps {
  avatar?: string,
}

const Logo = ({ avatar }: LogoProps) => (
  <div className={classes.avatar}>
    <img src={avatar || logoPlaceholder} alt="logo" />
  </div>
);

export default Logo;
