import React from 'react';
import moment from 'moment';
import RangeGrid from '../RangeGrid/RangeGrid';
import classes from './dateRangeGrid.module.scss';

interface DateRangeGridProps {
  month: moment.Moment;
  selectedStart?: moment.Moment;
  selectedEnd?: moment.Moment;
  onSelected: (start?: moment.Moment, end?: moment.Moment) => void;
  selectWeeks: boolean;
}

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const momentToGridPayload = (time: moment.Moment, today: moment.Moment, disabled = false) => ({
  value: time.unix(),
  label: time.get('date'),
  disabled: time.isAfter(today) || disabled,
});

const getDaysArray = (
  month: number,
  year: number,
  disabled = false,
  startIdx?: number,
  endIdx?: number,
) => {
  const monthIndex = month;
  const date = moment({ year, month: monthIndex, day: 1 });
  if (startIdx) {
    date.set({ date: date.date() + startIdx });
  }
  const result = [];
  const now = moment();
  while (date.month() === monthIndex) {
    if (endIdx && date.date() === endIdx + 1) {
      break;
    }
    result.push(momentToGridPayload(moment(date), now, disabled));
    date.set({ date: date.date() + 1 });
  }
  return result;
};

const getMonthDaysForCalendar = (momentInMonth: moment.Moment) => {
  let days = getDaysArray(momentInMonth.get('month'), momentInMonth.get('year'));
  const dayOfMonthStart = momentInMonth.clone().startOf('month').get('d');
  const dayOfMonthEnd = momentInMonth.clone().endOf('month').get('d');
  if (dayOfMonthStart !== 1 || dayOfMonthEnd !== 6) {
    const prevMonth = momentInMonth.clone().subtract(1, 'month');
    const nextMonth = momentInMonth.clone().add(1, 'month');
    days = [
      ...getDaysArray(prevMonth.get('month'), prevMonth.get('year'), true, prevMonth.daysInMonth() - (dayOfMonthStart === 0 ? 7 : dayOfMonthStart) + 1),
      ...days,
      ...getDaysArray(nextMonth.get('month'), nextMonth.get('year'), true, 0, 7 - dayOfMonthEnd),
    ];
  }
  return days.slice(0, 35);
};

const DateRangeGrid = ({
  month,
  selectedStart,
  selectedEnd,
  selectWeeks,
  onSelected,
}: DateRangeGridProps) => {
  const monthName = `${months[month.get('M')]} ${month.get('year')}`;

  return (
    <>
      <div className={classes.date_range_grid__header}>{monthName}</div>
      <table className={classes.date_range_grid__table}>
        <tbody>
          <tr>
            <td className={classes.date_range_grid__cell}>Mon</td>
            <td className={classes.date_range_grid__cell}>Tue</td>
            <td className={classes.date_range_grid__cell}>Wed</td>
            <td className={classes.date_range_grid__cell}>Thu</td>
            <td className={classes.date_range_grid__cell}>Fri</td>
            <td className={classes.date_range_grid__cell}>Sat</td>
            <td className={classes.date_range_grid__cell}>Sun</td>
          </tr>
        </tbody>
      </table>
      <RangeGrid
        selectedStart={selectedStart && moment.max(selectedStart.startOf('isoWeek'), month.clone().startOf('month')).unix()}
        selectedEnd={selectedStart && selectedEnd && moment.min(selectedEnd.endOf('isoWeek'), selectedStart.endOf('isoWeek'), month.clone().endOf('month')).unix()}
        columnsCount={7}
        items={getMonthDaysForCalendar(month.clone())}
        onSelected={(val) => {
          const valueMoment = moment.unix(val);
          let start;
          let end;
          if (selectWeeks) {
            start = valueMoment.clone().startOf('isoWeek');
            end = valueMoment.clone().endOf('isoWeek');
            onSelected(start, end);
            return;
          }
          if (valueMoment.isBefore(selectedStart)) {
            end = selectedStart;
            start = valueMoment;
          } else if (valueMoment.isAfter(selectedStart)) {
            end = valueMoment;
          } else {
            start = valueMoment;
            end = valueMoment;
          }
          onSelected(start, end);
        }}
      />
    </>
  );
};

export default DateRangeGrid;
