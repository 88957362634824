import React, { useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from 'recharts';
import { months, weekdays } from 'moment';
import RangeType, { colors } from '../../../constants/PerformanceDashboard';
import formatPrice from '../../../utils/CurrencyUtils';
import classes from './jobStatChart.module.scss';
import TooltipIcon from '../../../images/info_tooltip.svg';

interface JobStatChartProps {
  jobDataArr?: JobStats[];
  rangeType?: RangeType;
  marketplace: boolean;
  openInfoModal: () => void;
  to: moment.Moment;
}

let tickPositions: number[] = [];

const JobStatChart = ({
  jobDataArr = [],
  rangeType,
  marketplace,
  openInfoModal,
  to,
}: JobStatChartProps) => {
  const [toolTipPos, setToolTipPos] = useState<any>({});
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);
  const widthOfTooltip = marketplace ? 182 : 91;

  const formatLabel = (label: string | number | undefined) => {
    if (!(typeof label === 'string') || label === 'auto') return '';
    switch (rangeType) {
      case RangeType.WEEK:
        return label.substring(0, 3); // Monday -> Mon
      case RangeType.YEAR:
        return label.substring(0, 1); // January -> J
      default:
        return label;
    }
  };

  const CustomTooltip = (jobDataArr: any) => {
    const { active, payload, label } = jobDataArr;
    const width = marketplace ? 182 : 91;
    if (active && payload && payload.length && tooltipVisible) {
      const xVal = Math.min(
        Math.max(toolTipPos.x + toolTipPos.width / 2, width / 2 + 8),
        window.innerWidth - width / 2 - 80,
      );
      let xDiff: string = '0px';
      if (xVal === toolTipPos.x + toolTipPos.width / 2) xDiff = '50%';
      else if (xVal === width / 2 + 8) xDiff = `${toolTipPos.x - 8 + toolTipPos.width / 2}px`;
      else xDiff = `calc(50% + ${toolTipPos.x + toolTipPos.width / 2 - xVal}px)`;
      return (
        <>
          <div className={classes.job_stats_chart__tooltip_container}>
            <div className={classes.job_stats_chart__tooltip_date}>{label}</div>
            <div className={classes.job_stats_chart__tooltip_values}>
              <div className={classes.job_stats_chart__tooltip_value}>
                <div className={classes.job_stats_chart__tooltip_legend_value}>
                  <div className={`${classes.job_stats_chart__legend_symbol} ${classes.job_stats_chart__fill_completed}`} />
                  <div>
                    {`${formatPrice(payload[0].payload.amount_collected)}`}
                    <div className={classes.job_stats_chart__tooltip_date}>
                      {`${payload[0].payload.jobs_completed} jobs`}
                    </div>
                  </div>
                </div>
              </div>
              {marketplace && (
                <div className={classes.job_stats_chart__tooltip_value}>
                  <div className={classes.job_stats_chart__tooltip_legend_value}>
                    <div className={`${classes.job_stats_chart__legend_symbol} ${classes.job_stats_chart__fill_missed}`} />
                    <div>
                      {`${formatPrice(payload[0].payload.amount_missed)}`}
                      <div className={classes.job_stats_chart__tooltip_date}>
                        {`${payload[0].payload.jobs_missed} jobs`}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className={classes.job_stats_chart__tooltip_line_container}
            style={{
              height: `${toolTipPos.y - 73 - Math.min(0, toolTipPos.y - 2 * 50) - 5}px`,
            }}
          >
            <div className={classes.job_stats_chart__arrow_down} style={{ marginLeft: `calc(${xDiff} - 4.5px)` }} />
            <div
              className={classes.job_stats_chart__tooltip_line}
              style={{
                marginLeft: xDiff,
              }}
            />
          </div>
        </>
      );
    }
    return null;
  };
  const max = jobDataArr && jobDataArr.length > 0
    ? jobDataArr.reduce((prev, curr) => {
      if (prev.amount_collected < curr.amount_collected) {
        return curr;
      }
      return prev;
    }).amount_collected
    : 0;

  const CustomTick = (props: any) => {
    const { x, payload, y } = props;
    let yPos = y;
    tickPositions.forEach((t) => {
      if (yPos > t - 1 && yPos < t + 30) {
        yPos = t + 30;
      }
    });
    if (payload.value === 0) {
      return null;
    }
    const isMax = payload.value === max;
    tickPositions.push(yPos);
    if (!isMax) tickPositions = [];
    return (
      <g transform={`translate(${x + max.toFixed(2).toString().length * 3.5},${yPos})`} className={classes.job_stats_chart__y_axis_text}>
        <text x={0} y={0} fill={!isMax ? colors.JOBOX_BLUE : colors.AXIS_STROKE}>
          <tspan textAnchor="middle" x="0">
            {`${formatPrice(payload.value)}`}
          </tspan>
          <tspan textAnchor="middle" x="0" dy="14">
            {isMax ? 'max' : 'avg'}
          </tspan>
        </text>
      </g>
    );
  };

  const fillJobData = (jobDataArr: any) => {
    const fullJobDataArr: JobStats[] = jobDataArr;
    switch (rangeType) {
      case RangeType.WEEK: {
        // eslint-disable-next-line no-plusplus
        for (let i = jobDataArr.length; i < weekdays().length; i++) {
          fullJobDataArr.push({
            jobs_completed: 0,
            jobs_missed: 0,
            amount_collected: 0,
            amount_missed: 0,
            label: weekdays(i + 1),
          });
        }
        return fullJobDataArr;
      }
      case RangeType.YEAR: {
        // eslint-disable-next-line no-plusplus
        for (let j = jobDataArr.length; j < months().length; j++) {
          fullJobDataArr.push({
            jobs_completed: 0,
            jobs_missed: 0,
            amount_collected: 0,
            amount_missed: 0,
            label: months(j),
          });
        }
        return fullJobDataArr;
      }
      case RangeType.MONTH: {
        const finalDay: number = to.date();
        if (jobDataArr && jobDataArr.length > 0) {
          let lastWeekDay: number = +jobDataArr.slice(-1)[0].label.substring(4) + 1;
          while (lastWeekDay < finalDay) {
            fullJobDataArr.push({
              jobs_completed: 0,
              jobs_missed: 0,
              amount_collected: 0,
              amount_missed: 0,
              label: (lastWeekDay + 7 < finalDay) ? `${lastWeekDay} - ${lastWeekDay + 7}` : `${lastWeekDay} - ${finalDay}`,
            });
            lastWeekDay += 8;
          }
        }
        return fullJobDataArr;
      }
      default: {
        return fullJobDataArr;
      }
    }
  };

  return (
    <>
      <ResponsiveContainer width="100%" height="88%">
        <BarChart
          data={fillJobData(jobDataArr)}
          margin={{
            top: 20,
            right: 0,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" verticalPoints={[0]} />
          <XAxis
            dataKey="label"
            fontFamily="sans-serif"
            tickLine={false}
            fontSize="12px"
            stroke={colors.AXIS_STROKE}
            interval={0}
            axisLine={{ stroke: colors.AXIS_STROKE }}
            tickFormatter={formatLabel}
          />
          <YAxis
            orientation="right"
            tickLine={false}
            axisLine={false}
            ticks={[
              0,
              0,
              max,
              jobDataArr.reduce((total, next) => total + next.amount_collected, 0)
              / jobDataArr.length,
            ]}
            tickFormatter={(value) => {
              if (value === 0) return '';
              return `${formatPrice(value)}`;
            }}
            fontSize="12px"
            width={max.toFixed(2).toString().length * 10}
            tick={<CustomTick />}
          />
          <Tooltip
            content={(props) => <CustomTooltip {...props} setPayload={setToolTipPos} />}
            cursor={false}
            position={{
              x: Math.min(
                Math.max(toolTipPos.x + toolTipPos.width / 2, widthOfTooltip / 2 + 16),
                window.innerWidth - widthOfTooltip / 2 - 80,
              ),
              y: Math.min(0, toolTipPos.y - 2 * 50),
            }}
          />
          <Bar
            name="Earnings"
            dataKey="amount_collected"
            stackId="a"
            fill={colors.JOBOX_BLUE}
            radius={[5, 5, 0, 0]}
            onClick={(jobDataArr) => {
              if (jobDataArr.x === toolTipPos.x) setTooltipVisible(!tooltipVisible);
              else setTooltipVisible(true);
              setToolTipPos(jobDataArr);
            }}
          >
            {jobDataArr.map((entry) => (
              <Cell
                key={`cell-${entry}`}
                fill={
                  (toolTipPos && toolTipPos.payload && entry.label === toolTipPos.payload.label)
                    || !tooltipVisible
                    ? colors.JOBOX_BLUE
                    : colors.DISABLED
                }
              />
            ))}
          </Bar>
          <Bar
            id="topBar"
            name="Missed earnings"
            dataKey="amount_missed"
            stackId="a"
            fill="#EEEEF4"
            radius={[5, 5, 0, 0]}
            onClick={(jobDataArr) => {
              if (jobDataArr.x === toolTipPos.x) setTooltipVisible(!tooltipVisible);
              else setTooltipVisible(true);
              setToolTipPos(jobDataArr);
            }}
          />
        </BarChart>
      </ResponsiveContainer>
      <div className={classes.job_stats_chart__legend}>
        <div className={classes.job_stats_chart__legend_entry}>
          <div className={`${classes.job_stats_chart__legend_symbol} ${classes.job_stats_chart__fill_completed}`} />
          Earnings
        </div>
        {marketplace && (
          <>
            <div className={classes.job_stats_chart__legend_entry}>
              <div className={`${classes.job_stats_chart__legend_symbol} ${classes.job_stats_chart__fill_missed}`} />
              Missed earnings
            </div>
          </>
        )}
        <button onClick={openInfoModal} type="button">
          <img src={TooltipIcon} alt="tooltip" />
        </button>
      </div>
    </>
  );
};

export default JobStatChart;
