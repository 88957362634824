import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { observer, inject } from 'mobx-react';
import { compose } from 'lodash/fp';

import classes from './jobInfoView.module.scss';
import JobInfoCard from '../../../components/PerformanceDashboard/JobInfoCard/JobInfoCard';
import BackArrow from '../../../images/back-arrow.svg';
import PerformanceDashboardStore from '../../../stores/PerformanceDashboardStore';
import JobInfoModel from '../../../models/JobInfoModel';
import { formatJobViewDate } from '../../../constants/PerformanceDashboard';

interface JobInfoViewProps {
  match: {
    params: {
      userId: number;
    };
  };
  location: {
    state: {
      from: number,
      to: number,
      marketplace: boolean,
      rangeType: string,
    }
  };
  performanceDashboardStore: PerformanceDashboardStore;
  closedJobs: boolean;
}

const JobInfoView = ({
  match: { params },
  location,
  performanceDashboardStore,
  closedJobs,
}: JobInfoViewProps) => {
  const { state } = location;
  const jobs = (closedJobs
    ? performanceDashboardStore.completedJobs
    : performanceDashboardStore.missedJobs
  );
  useEffect(() => {
    if (!state) {
      return;
    }
    if (closedJobs) {
      performanceDashboardStore.fetchUserCompletedJobs(
        params.userId,
        state.from,
        state.to,
        state.marketplace,
      );
    } else {
      performanceDashboardStore.fetchUserMissedJobs(
        params.userId,
        state.from,
        state.to,
        state.marketplace,
      );
    }
  }, [closedJobs]);

  return (
    <div
      className={classes.job_info_view__container}
    >
      <div className={classes.job_info_view__header}>
        <Link
          to={{
            pathname: `/performance/${params.userId}`,
            state: {
              startTime: state && state.from && state.from / 1000,
              endTime: state && state.to && state.to / 1000,
              rangeType: state && state.rangeType,
              marketplace: state && state.marketplace,
            },
          }}
        >
          <img className={classes.job_info_view__arrow} src={BackArrow} alt="back" />
        </Link>
        <div className={classes.job_info_view__header_content}>
          <div className={classes.job_info_view__title}>
            {closedJobs ? 'Closed' : 'Missed'}
            {state && state.marketplace ? ' connect jobs' : ' jobs'}
          </div>
          <div className={classes.job_info_view__date}>
            {state
              && `${formatJobViewDate(new Date(state.from), new Date(state.to))}`}
          </div>
        </div>
        <div className={classes.job_info_view__arrow} />
      </div>
      {
        performanceDashboardStore.loading ? (
          <div className={classes.job_info_view__loading_container}>
            <div className="spinner-border" role="status" />
          </div>
        ) : (
          <div
            className={classes.job_info_view__list_body}
          >
            {jobs.length > 0
              && jobs.map((job: JobInfoModel) => (
                <JobInfoCard job={job} key={job.createdAt} closed={closedJobs} />
              ))}
          </div>
        )
      }

    </div>
  );
};

export default compose(
  withRouter,
  inject('performanceDashboardStore'),
  observer,
)(JobInfoView);
