import React from 'react';
import classes from './toggle.module.scss';
import ToggleOnIcon from '../../../images/toggle-dark-on.svg';
import ToggleOffIcon from '../../../images/toggle-dark-off.svg';

const regEx = /[^a-z0-9]/gi;

interface InputProps {
  onToggle: any,
  label: string,
  icon?: string,
  id: string,
  value: boolean,
}

const Toggle = ({
  value, onToggle, label, id, icon,
}: InputProps) => {
  const toggleId = id || `toggle-${label.toLowerCase().replace(regEx, '')}`;

  return (
    <div className={classes.toggleContainer} tabIndex={0} role="textbox">
      {icon
      && <img className={classes.icon} src={icon} alt="input-decorator" />}
      <div className={classes.toggleContent}>
        <label
          id={`label-${label.toLowerCase().split(' ').join('_')}`}
          htmlFor={toggleId}
          className={classes.label}
        >
          {label}
        </label>
        <button type="button" onClick={onToggle}>
          <img src={value ? ToggleOnIcon : ToggleOffIcon} alt={value ? 'on' : 'off'} />
        </button>
      </div>
    </div>
  );
};

export default Toggle;
