class IndependentProfileModel {
  constructor(independentProfile) {
    if (independentProfile) {
      this.agreement = independentProfile.agreement;
      this.availability = independentProfile.availability;
      this.complete = independentProfile.complete;
    }
  }
}

export default IndependentProfileModel;
