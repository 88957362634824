class OccupationModel {
  constructor(occupation) {
    this.id = occupation.id;
    this.name = occupation.name;
    this.icon = occupation.icon_url;
    this.categories = occupation.categories;
    this.cancellationReasons = occupation.cancellation_reasons;
  }
}

export default OccupationModel;
