import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import {
  Box,
  ButtonLoading,
  Dialog,
  Icon,
  InputField,
  Input,
  InputAddon,
  NumberField,
  Field,
  Form,
  Pill,
  Text,
  isRequired,
} from '@jobox/react-components-library/mobile';

import { ActionType, ReasonType } from '../../consts';

import localClasses from './ReasonDetails.module.scss';
import commonClasses from '../common.module.scss';

const classes = { ...localClasses, ...commonClasses };

const ReasonDetails = ({
  isOpen,
  isLoading,
  command,
  selectedReason,
  onCancel,
  onSubmit,
  onChange,
}) => (
  <Dialog
    open={isOpen}
    className={classes.dialog}
  >
    <Box className={classes['selected-reason-header']}>
      <Text className={classes['selected-reason-header__label']}>
        { command === ActionType.Update ? 'Update Message' : 'Cancelation Reason' }
      </Text>
      <Box className={cn(classes['selected-reason'], classes.dialog__reason)}>
        <Text className={classes['selected-reason__value']}>
          { selectedReason?.description }
        </Text>
        <Pill
          className={classes['selected-reason__back']}
          color="primary"
          onClick={onCancel}
        >
          <Icon icon={faPen} />
        </Pill>
      </Box>
    </Box>

    <Form
      className={cn(classes.form)}
      onSubmit={onSubmit}
      onChange={onChange}
      initialValues={{
        ...(selectedReason?.type === ReasonType.Price ? { price: undefined } : {}),
        reason: '',
      }}
    >
      {
          selectedReason?.type === ReasonType.Price && (
            <NumberField
              name="price"
              type="number"
              numberType="float"
              digits={2}
              validate={isRequired('Requested price is required')}
            >
              <InputField>
                <Input
                  disabled={isLoading}
                  label="Price Offered"
                  placeholder="0.00"
                  align="right"
                  addOn={
                    <InputAddon>$</InputAddon>
                  }
                />
              </InputField>
            </NumberField>
          )
      }
      <Field name="reason">
        <InputField>
          <Input
            disabled={isLoading}
            label="Additional notes"
            type="multiline"
          />
        </InputField>
      </Field>

      <ButtonLoading
        type="submit"
        color={command === ActionType.Update ? 'secondary' : 'danger'}
        className={classes.form__submit}
        loading={isLoading}
      >
        { command === ActionType.Update ? 'Update Job' : 'Cancel Job' }
      </ButtonLoading>
    </Form>
  </Dialog>
);
ReasonDetails.propTypes = {
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool,
  command: PropTypes.oneOf([ActionType.Reject, ActionType.Update]).isRequired,
  selectedReason: PropTypes.shape({
    description: PropTypes.string.isRequired,
    type: PropTypes.oneOf([ReasonType.Price, ReasonType.Text]),
  }).isRequired,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
};

export default ReasonDetails;
