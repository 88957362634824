/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { observer, inject, PropTypes as MobxPropTypes } from 'mobx-react';
import { compose } from 'lodash/fp';

import {
  fullDayTimeslot,
  defaultTimeslot,
} from '../../constants/ConnectTechSettings';
import withProps from '../../components/withProps';

import ToggleOnIcon from '../../images/icon-toggle-on.svg';
import ToggleOffIcon from '../../images/icon-toggle-off.svg';
import LoadingIcon from '../../images/transparent-spinner.gif';

import TimeWindow from './TimeWindow';

import './DayAvailability.scss';

const render24HrWindow = () => (
  <div className="connect-tech-settings__day__block">
    <div className="connect-tech-settings__day__range">
      <div className="connect-tech-settings__day__range__unit right readonly">
        <div className="connect-tech-settings__day__range__display">
          12:00 AM
        </div>
        Start time
      </div>
      <div className="connect-tech-settings__day__range__unit left readonly">
        <div className="connect-tech-settings__day__range__display">
          11:59 PM
        </div>
        End Time
      </div>
    </div>
  </div>
);

class DailyAvailabilityHours extends Component {
  on24HrToggle(val) {
    const { independentProfile } = this.props;
    const { dayInfo } = independentProfile;

    independentProfile.setDayInfo({
      ...dayInfo,
      enabled: val,
      fullDay: val,
      timeSlots: val ? fullDayTimeslot : defaultTimeslot,
    });
  }

  onTimeInput = (window) => ({ start, end }) => {
    const { independentProfile } = this.props;
    const { dayInfo } = independentProfile;

    const [startHour, startMin] = start.split(':').map((v) => parseInt(v, 10));
    const [endHour, endMin] = end.split(':').map((v) => parseInt(v, 10));

    const newTimeSlots = dayInfo.timeSlots.map((slot) => (
      slot.display === window.display
        ? {
          ...slot,
          display: `${start}-${end}`,
          start_time: start,
          end_time: end,
          start_hour: startHour,
          start_min: startMin,
          end_hour: endHour,
          end_min: endMin,
        }
        : slot
    ));
    independentProfile.setDayInfo({ timeSlots: newTimeSlots });
  };

  onDeleteWindow = (window) => {
    const { independentProfile } = this.props;
    const { dayInfo } = independentProfile;

    if (dayInfo.timeSlots.length !== 1) {
      const newTimeSlots = dayInfo.timeSlots.filter(
        (slot) => slot.display !== window,
      );
      independentProfile.setDayInfo({ ...dayInfo, timeSlots: newTimeSlots });
    }
  };

  addNewWindow() {
    const { independentProfile } = this.props;
    const { dayInfo } = independentProfile;
    independentProfile.setDayInfo({
      timeSlots: [
        ...dayInfo.timeSlots,
        {
          display: '6:00-8:00',
          cross_day: null,
          start_time: '06:00',
          end_time: '18:00',
          start_hour: 0,
          end_hour: 0,
          start_min: 0,
          end_min: 0,
        },
      ],
    });
  }

  renderTimeWindows(dayInfo) {
    const { timeSlots } = dayInfo;

    return timeSlots.map((timeSlot) => (
      <TimeWindow
        key={timeSlot.display}
        deletable={timeSlots.length !== 1}
        onDelete={() => this.onDeleteWindow(timeSlot.display)}
        onChange={this.onTimeInput}
        startValue={timeSlot.start_time}
        endValue={timeSlot.end_time}
      />
    ));
  }

  render() {
    const { independentProfile } = this.props;

    if (!independentProfile) {
      return (
        <img
          className="connect-tech-settings__loading"
          src={LoadingIcon}
          alt="Loading"
        />
      );
    }

    const { dayInfo } = independentProfile;
    const { fullDay } = dayInfo;

    return (
      <div className="connect-tech-settings__day">
        {independentProfile.isError && (
          <div className="connect-tech-settings__error">
            Something went wrong, try again.
          </div>
        )}
        <div className="connect-tech-settings__day__header connect-tech-settings__day__question_header">
          <div className="connect-tech-settings__day__header__title">
            What are your usual hours?
          </div>
        </div>
        <div className="connect-tech-settings__day__all-day pt-2">
          <div>Available 24hrs</div>
          <img
            src={fullDay ? ToggleOnIcon : ToggleOffIcon}
            alt={fullDay ? 'enabled' : 'disabled'}
            onClick={() => this.on24HrToggle(!fullDay)}
          />
        </div>
        <div className="connect-tech-settings__day__divider" />
        <div className="mt-3">
          <div className="connect-tech-settings__day__all-day d-flex flex-row align-items-center justify-content-between my-2">
            Time windows
            {!fullDay && (
              <button
                type="button"
                className="connect-tech-settings__day__header__button"
                onClick={() => this.addNewWindow()}
              >
                +
              </button>
            )}
          </div>

          {
            !fullDay
              ? this.renderTimeWindows(dayInfo)
              : render24HrWindow()
          }
        </div>
      </div>
    );
  }
}
DailyAvailabilityHours.propTypes = {
  independentProfile: PropTypes.shape({
    dayInfo: MobxPropTypes.observableObject,
    setDayInfo: PropTypes.func,
    isError: PropTypes.bool,
  }),
};

export default compose(
  withProps(({
    match: {
      params: {
        userId,
        day,
      },
    },
  }) => ({
    userId,
    day: parseInt(day, 10),
  })),
  withRouter,
  inject((stores) => ({
    independentProfile: stores.connectTechSettingsStore.independentProfileStore,
  })),
  observer,
)(DailyAvailabilityHours);
