const formatPrice = (
  price = 0, currency = '$', showNegative = false, showPositive = false,
) => {
  const value = currency + Math.abs(price).toFixed(2).replace(/./g, (c, i, a) => (i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? `,${c}` : c));
  if (showNegative && showPositive) {
    return price >= 0 ? `+${value}` : `-${value}`;
  }
  if (showNegative) {
    return price >= 0 ? value : `-${value}`;
  }
  if (showPositive) {
    return price >= 0 ? `+${value}` : value;
  }
  return value;
};

export default formatPrice;
