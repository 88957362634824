class ApiService {
  constructor(request) {
    this.request = request;
  }

  getOccupations() {
    return this.request.get('/occupations').then((response) => response.data);
  }

  getAutoBrands() {
    return this.request.get('auto_brand').then((response) => response.data);
  }

  getUserSkillAutobrands(userId, skillId) {
    return this.request
      .get(`/users/${userId}/skill/${skillId}/autobrand_with_years`)
      .then((response) => response.data);
  }

  createUserSkillAutobrand(userId, skillId, data) {
    return this.request
      .post(`/users/${userId}/skill/${skillId}/autobrand_with_years`, data)
      .then((response) => response.data);
  }

  bulkCreateSkillAutobrands(userId, skillId, data) {
    return this.request
      .post(`/users/${userId}/skill/${skillId}/autobrands_with_years`, data)
      .then((response) => response.data);
  }

  updateUserSkillAutobrand(userId, skillId, brandId, data) {
    return this.request
      .put(`/users/${userId}/skill/${skillId}/autobrand_with_years/brand/${brandId}`, data)
      .then((response) => response.data);
  }

  removeUserSkillAutobrand(userId, skillId, brandId) {
    return this.request
      .delete(`/users/${userId}/skill/${skillId}/autobrand_with_years/brand/${brandId}`)
      .then((response) => response.data);
  }

  removeAllUserSkillAutobrand(userId, skillId) {
    return this.request
      .delete(`/users/${userId}/skill/${skillId}/autobrand_with_years`)
      .then((response) => response.data);
  }

  getAutoBrandPresets(userId, jobServiceId) {
    return this.request
      .get(`/users/${userId}/auto_brand_presets?job_service_id=${jobServiceId}`)
      .then((response) => response.data);
  }

  getConnectTechSettings(userId) {
    return this.request
      .get(`team/connect_tech_settings/${userId}`)
      .then((response) => response.data);
  }

  getUserPerformance(userId, start, range, marketplace) {
    return this.request
      .get(`performance/user/${userId}/job_stats?start_time=${start}&range=${range}&marketplace=${marketplace}`)
      .then((response) => response.data);
  }

  getUserHighlights(userId) {
    return this.request
      .get(`performance/user/${userId}/highlights`)
      .then((response) => response.data);
  }

  getUserMissedJobs(userId, from, to, marketplace) {
    return this.request
      .get(`performance/user/${userId}/missed_jobs?start_time=${from}&end_time=${to}&marketplace=${marketplace}`)
      .then((response) => response.data);
  }

  getUserCompletedJobs(userId, from, to, marketplace) {
    return this.request
      .get(`performance/user/${userId}/completed_jobs?start_time=${from}&end_time=${to}&marketplace=${marketplace}`)
      .then((response) => response.data);
  }
  getMySiteSettings(userId) {
    return this.request
      .get(`/techsite/user/${userId}`)
      .then((response) => response.data);
  }

  getMySiteStatus(userId) {
    return this.request
      .get(`/techsite/user/${userId}/status`)
      .then((response) => response.data);
  }

  updateMySiteStatus(userId, status) {
    return this.request
      .post(`/techsite/user/${userId}/status?enable=${status}`)
      .then((response) => response.data);
  }

  updateMySiteSettings(userId, body) {
    return this.request
      .put(`/techsite/user/${userId}`, body)
      .then((response) => response.data);
  }

  updateBasicProfile(userId, profile) {
    return this.request
      .put(`/team/basic_profile/${userId}`, { basic_profile: profile })
      .then((response) => response.data);
  }

  checkMySiteAlias(body) {
    return this.request
      .get(`/techsite/validate?site_alias=${body}`)
      .then((response) => response.data);
  }

  getUser(userId) {
    return this.request
      .get(`/users/${userId}`)
      .then((response) => (response));
  }

  setUser(body) {
    return this.request
      .put('/users', body)
      .then((response) => (response));
  }

  upload(file, userId) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('user_id', userId);
    formData.append('added_by_user_id', userId);

    return this.request
      .post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => (response));
  }

  updateIndepProfile(userId, profile) {
    return this.request
      .put(`/team/indep_profile/${userId}`, profile)
      .then((response) => (response.data));
  }

  getJob(jobId) {
    return this.request
      .get(`/jobs/${jobId}`)
      .then((response) => response.data);
  }

  getOccupationV2(occupationId) {
    return this.request
      .get(`/occupations/v2/${occupationId}`)
      .then((response) => response.data);
  }

  rejectJob(jobId, rejectInfo) {
    return this.request
      .post(`/v2/jobs/${jobId}/reject_v2`, rejectInfo)
      .then((response) => response.data);
  }

  revokeJob(jobId, revokeInfo) {
    return this.request
      .post(`/v2/jobs/${jobId}/revoke_v2`, revokeInfo)
      .then((response) => response.data);
  }

  updateJob(jobId, updateData) {
    return this.request
      .post(`/v2/jobs/${jobId}/update`, updateData)
      .then((response) => response.data);
  }

  getMySiteReviews(userId) {
    return this.request
      .get(`techsite/user/${userId}/ratings`)
      .then((response) => response.data);
  }

  updateMySiteReviews(userId, reviews) {
    return this.request
      .put(`techsite/user/${userId}/ratings`, reviews)
      .then((response) => response.data);
  }
}

export default ApiService;
