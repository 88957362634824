/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { compose } from 'lodash/fp';

import { defaultTimeslot } from '../../constants/ConnectTechSettings';
import { formatTimeWindow } from '../../lib/date';

import ForwardArrowIcon from '../../images/icon-right-arrow.svg';
import LoadingIcon from '../../images/transparent-spinner.gif';
import ToggleOnIcon from '../../images/icon-toggle-on.svg';
import Layout from '../../components/MySite/Layout/Layout';

import ToggleOffIcon from '../../images/icon-toggle-off.svg';
import './Availability.scss';

class Availability extends Component {
  onDayToggle(day) {
    const { schedule, dayInfo: { timeSlots } } = this.independentProfile;
    // Just create a new schedule
    const profileDefaultTimeSlot = _.get(
      this.findNearestNeighbor(day),
      'timeSlots',
      defaultTimeslot,
    );
    const newSchedule = schedule.map((scheduleDay) => (
      scheduleDay === day ? ({
        ...day,
        enabled: !day.enabled,
        timeSlots: !day.enabled ? timeSlots || profileDefaultTimeSlot : [],
      }) : scheduleDay
    ));
    // and assign it, the setter and the reaction will handle the update
    // in the store
    this.independentProfile.schedule = newSchedule;
  }

  get independentProfile() {
    const { independentProfileStore } = this.props;

    return independentProfileStore;
  }

  findNearestNeighbor(day) {
    const { schedule } = this.independentProfile;

    // Look at days above first
    for (let i = day.id; i >= 0; i -= 1) {
      if (schedule[i].timeSlots.length > 0) {
        return schedule[i];
      }
    }

    // Look at days below next
    for (let i = day.id; i < 7; i += 1) {
      if (schedule[i].timeSlots.length > 0) {
        return schedule[i];
      }
    }

    // return null value if nothing found
    return null;
  }

  renderWindowSection(day) {
    const { location } = this.props;

    const loadingState = (
      <div className="connect-tech-settings__availability__day-section__time-section__window">
        <img className="connect-tech-settings__availability__loading" src={LoadingIcon} alt="Loading" />
      </div>
    );

    const overflowElement = (
      <div
        key={`${day.weekday}-overflow`}
        className="connect-tech-settings__availability__day-section__time-section__window"
      >
        ...
      </div>
    );

    let overflow = false;

    // show 2 normal windows or 1 overnight window, add ellipsis if overflow
    const windows = day.timeSlots.slice(0, 2).map((window) => {
      const { range, overnight } = formatTimeWindow(window);

      if ((overnight && day.timeSlots.length > 1) || day.timeSlots.length > 2) {
        overflow = true;
      }

      return (
        <div
          key={`${day.weekday}-${window.display}`}
          className="connect-tech-settings__availability__day-section__time-section__window"
        >
          {range}
        </div>
      );
    });

    let viewableWindows = windows;

    if (overflow) {
      viewableWindows = [windows[0], overflowElement];
    } else if (day.timeSlots.length > 2) {
      viewableWindows.push(overflowElement);
    }

    return (
      <Link
        to={`${location.pathname}/${day.weekday}`}
        className="connect-tech-settings__availability__day-section__time-section text-decoration-none"
      >
        <div className="connect-tech-settings__availability__day-section__time-section__windows">
          { day.updating ? loadingState : viewableWindows}
        </div>
        <img
          className="connect-tech-settings__availability__day-section__time-section__arrow"
          src={ForwardArrowIcon}
          alt="edit"
        />
      </Link>
    );
  }

  renderDaySection(day) {
    return (
      <div className="connect-tech-settings__availability__day-section" key={`day-${day.weekday}`}>
        <div className="connect-tech-settings__availability__day-section__header">
          <div className="connect-tech-settings__availability__day-section__header__title">
            {day.name}
          </div>
          <img
            className="connect-tech-settings__availability__day-section__header__toggle"
            src={day.enabled ? ToggleOnIcon : ToggleOffIcon}
            onClick={() => this.onDayToggle(day)}
            alt={day.enabled ? 'day enabled' : 'day not enabled'}
          />
        </div>
        { day.enabled && <div className="connect-tech-settings__availability__day-section__divider" /> }
        { day.enabled && this.renderWindowSection(day)}
      </div>
    );
  }

  render() {
    const {
      independentProfileStore, isOnboarding, data, match: { params: { userId } },
    } = this.props;

    const loading = !this.independentProfile;
    const shouldDisplayWarning = data.marketplace_qualification === 'Eligible';

    return (
      <Layout userId={userId} fullWidth title="Working hours" loading={loading} isOnboarding={isOnboarding}>
        {!loading
        && (
          <>
            <div className="connect-tech-settings__availability">
              {
            independentProfileStore.isError && (
              <div className="connect-tech-settings__error">Something went wrong, try again.</div>
            )
          }

              <div className="connect-tech-settings__availability__title">
                {isOnboarding
            && (
              <div className="connect-tech-settings__title__header">
                What days do you work?
              </div>
            )}
              </div>
              {this.independentProfile.schedule.map((day) => this.renderDaySection(day))}
            </div>
            {shouldDisplayWarning
            && (
            <p className="connect-tech-settings__warning">
              Changing this setting on
              MySite will update it in your Connect Settings too.
            </p>
            )}
          </>
        )}
      </Layout>
    );
  }
}

Availability.propTypes = {
  isOnboarding: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }),
  independentProfileStore: PropTypes.shape({
    isError: PropTypes.bool,
  }),
  data: PropTypes.shape({
    marketplace_qualification: PropTypes.string,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      userId: PropTypes.string,
    }),
  }),
};

export default compose(
  withRouter,
  inject((stores) => ({
    independentProfileStore: stores.connectTechSettingsStore.independentProfileStore,
    data: stores.connectTechSettingsStore.data,
  })),
  observer,
)(Availability);
