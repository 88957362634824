import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import cookieParser from 'cookie';
import { compose } from 'lodash/fp';
import { useParams } from 'react-router';

import publicIcon from '../../images/public.svg';
import hiddenIcon from '../../images/hidden.svg';
import dotIcon from '../../images/dot.svg';

import classes from './mySiteStatusView.module.scss';
import Layout from '../../components/MySite/Layout/Layout';
import Button from '../../components/MySite/Button/Button';
import ShareButton from './ShareButton/ShareButton';
import PreviewButton from './PreviewButton/PreviewButton';
import config from '../../config';

interface MySiteStatusViewProps {
  mySiteSettingsStore: {
    mySite: {
      site_alias: string,
      active: boolean,
      business_name: string,
    },
    updateMySiteStatus: any,
  },
}

const MySiteStatusView = ({ mySiteSettingsStore }: MySiteStatusViewProps) => {
  const { userId } = useParams<{ userId: string }>();
  const [isConfirmStep, setIsConfirmStep] = useState(false);
  const url = mySiteSettingsStore.mySite.site_alias;
  const status = mySiteSettingsStore.mySite.active;
  const businessName = mySiteSettingsStore.mySite.business_name;
  const cookies = cookieParser.parse(document.cookie);

  const handleToggleActive = () => {
    if (isConfirmStep || !status) {
      mySiteSettingsStore.updateMySiteStatus();
    } else {
      setIsConfirmStep(true);
    }
  };

  useEffect(() => {
    setIsConfirmStep(false);
  }, [status]);

  const displayUrl = `pros.jobox.ai/${url}`;
  const buttonLabel = status ? 'Hide Site' : 'Publish Site';
  const buttonClass = status ? classes.buttonPublished : classes.buttonHidden;
  const title = status ? 'Your website is published' : 'Your website is hidden';
  const copy = status ? 'Website is live and public. Your customers can access it on the web address below. To hide your website tap the ‘unpublish’ button.' : 'Website is still there, but hidden from public. You can still edit and preview the website.';
  const icon = status ? publicIcon : hiddenIcon;

  const { MYSITE_URL } = config;
  const { Authorization, Timestamp } = cookies;
  const siteURL = `https://${MYSITE_URL}/${url}`;
  const previewUrl = MYSITE_URL ? `${siteURL}?preview=true&authorization=${Authorization}&userId=${userId}&timestamp=${Timestamp}` : `/mysite/${userId}`;

  const renderPoint = (title: string, text: string, button: React.ReactElement) => (
    <div className={classes.point}>
      <img className={classes.point__icon} src={dotIcon} alt="checkpoint" />
      <h6 className={classes.point__title}>{title}</h6>
      <p className={classes.point__text}>{text}</p>
      {button}
    </div>
  );

  return (
    <Layout userId={userId} title="Site status">
      <div className={classes.content}>
        {isConfirmStep && status ? (
          <>
            <h4 className={classes.header}>You’ll miss out on new jobs</h4>
            <p className={classes.warning}>
              To make the most out of your site, make sure you fill out
              all your business details and share the site online
            </p>
            {renderPoint(
              'Fill out your business details',
              'Your customers will appreciate clear information and easy booking process',
              <PreviewButton previewUrl={previewUrl} className={classes.point__button} />,
            )}
            {renderPoint(
              'Share your site online',
              'Make sure your customers know how to find your business and your site. \nShare it on social media, or group chats',
              <ShareButton
                businessName={businessName}
                url={url}
                className={classes.point__button}
              />,
            )}
          </>
        ) : (
          <>
            <img className={classes.statusImg} src={icon} alt="status indicator" />
            <div className={classes.title}>{title}</div>
            <div className={classes.copy}>{copy}</div>
            {status && <div className={classes.url}>{displayUrl}</div>}
          </>
        )}
      </div>
      {isConfirmStep && <span className={classes.confirm}>Still want to hide your site?</span>}
      <Button onClick={() => handleToggleActive()} label={isConfirmStep ? 'Yes, hide site' : buttonLabel} className={`${classes.button} ${buttonClass}`} />
    </Layout>
  );
};

export default compose(
  inject(({ mySiteSettingsStore }) => ({
    mySiteSettingsStore,
  })),
  observer,
)(MySiteStatusView);
