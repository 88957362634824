import React from 'react';
import moment from 'moment';
import RangeGrid from '../RangeGrid/RangeGrid';

interface YearRangeGridProps {
  numYears: number;
  selectedStart?: moment.Moment;
  selectedEnd?: moment.Moment;
  onSelected: (start?: moment.Moment, end?: moment.Moment) => void;
  minTime?: moment.Moment;
}

const formatDateToYearPayload = (date: moment.Moment, minTime?: moment.Moment) => ({
  value: date.clone().unix(),
  label: date.format('YYYY'),
  disabled: date.isAfter(moment()) || !!(minTime && minTime.isAfter(date)),
});

const getYearsArray = (numYears: number, minTime?: moment.Moment) => {
  const years = [];
  const dateStart = moment().clone();
  const dateEnd = moment().subtract(numYears - 1, 'y');
  while (dateStart.diff(dateEnd, 'years') >= 0) {
    years.push(formatDateToYearPayload(dateStart.startOf('year'), minTime?.startOf('year')));
    dateStart.subtract(1, 'year');
  }
  return years;
};

const YearRangeGrid = ({
  selectedStart,
  selectedEnd,
  onSelected,
  numYears,
  minTime,
}: YearRangeGridProps) => (
  <>
    <RangeGrid
      selectedStart={selectedStart?.unix()}
      selectedEnd={selectedEnd?.unix()}
      columnsCount={4}
      items={getYearsArray(numYears, minTime)}
      onSelected={(val) => {
        onSelected(moment.unix(val), moment.unix(val));
      }}
      inRange={(m1?: number, m2?: number) => !!m1 && !!m2 && moment.unix(m1).format('YYYY') === moment.unix(m2).format('YYYY')}
      singlePick
    />
  </>
);

export default YearRangeGrid;
