import React from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'lodash/fp';

import { Redirect, useParams } from 'react-router-dom';
import classes from './mySiteReviewsView.module.scss';
import Layout from '../../components/MySite/Layout/Layout';
import Review from '../../components/MySite/Review/Review';

interface MySiteReviewsViewProps {
  reviewsStore: {
    reviews: {
      id: string,
      created_at: number,
      rating: number,
      notes: string,
      visible: boolean,
      job_id: number,
      reference_id: string,
    }[],
    total: number,
    visible: number,
    toggleVisible: (id: string) => void,
    isLoading: boolean,
  }
}

const MySiteReviewsView = ({ reviewsStore }
: MySiteReviewsViewProps) => {
  const { userId } = useParams<{ userId: string }>();

  const handleClick = (id: string) => {
    reviewsStore.toggleVisible(id);
  };

  if (!reviewsStore.reviews) {
    if (reviewsStore && reviewsStore.total === 0 && !reviewsStore.isLoading) {
      return <Redirect to={`/mysite/${userId}`} />;
    }
    return <Layout loading />;
  }

  return (
    <Layout userId={userId} title="Customer reviews">
      <div className={classes.content}>
        <div className={classes.info}>
          <div className={classes.item}>
            <span className={classes.number}>{reviewsStore.total}</span>
            <span className={classes.text}>Reviews</span>
          </div>
          <div className={classes.item}>
            <span className={classes.number}>{reviewsStore.visible}</span>
            <span className={classes.text}>Displayed</span>
          </div>
        </div>
        <div>
          {reviewsStore.reviews.map((review) => (
            <Review key={review.id} review={review} onClick={handleClick} />
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default compose(
  inject(({ reviewsStore }) => ({
    reviewsStore,
  })),
  observer,
)(MySiteReviewsView);
