import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { camelCase } from 'lodash';
import cookieParser from 'cookie';

import getLdClient from './getLdClient';

import FeatureFlagContext from './FeatureFlagsContext';

const FeatureFlagsProvider = ({ children }) => {
  const [flags, setFlags] = useState(null);

  useEffect(() => {
    const cookies = cookieParser.parse(document.cookie);

    if (cookies['User-Id']) {
      getLdClient({
        key: cookies['User-Id'],
      }).then((ldClient) => {
        const ldFlags = ldClient.allFlags();
        const ldFlagsCamelCase = Object.keys(ldFlags).reduce((acc, key) => ({
          ...acc,
          [camelCase(key)]: ldFlags[key],
        }), {});
        setFlags(ldFlagsCamelCase);
      });
    } else {
      // eslint-disable-next-line no-console
      console.info('LaunchDarkly service not specified, User-Id not found in cookie.');
    }
  }, []);

  return (
    <FeatureFlagContext.Provider
      value={{
        flags: flags || {},
        initialized: flags !== null,
      }}
    >
      { children }
    </FeatureFlagContext.Provider>
  );
};
FeatureFlagsProvider.propTypes = {
  children: PropTypes.node,
};

export default FeatureFlagsProvider;
