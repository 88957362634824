import {
  useParams,
  useRouteMatch,
  useHistory,
  useLocation,
  generatePath,
} from 'react-router';
import { mapValues } from 'lodash';

type UrlParamStateMapper = (input: Record<string, string>) => Record<string, unknown>;

const useUrlParamState = (
  mapper: UrlParamStateMapper = (input) => input,
) => {
  const currentParams = useParams();
  const { path: pathPattern } = useRouteMatch();
  const { push } = useHistory();
  const { search } = useLocation();

  const setState = (partialState: Record<string, unknown>) => {
    const newState = mapValues(
      {
        ...currentParams,
        ...partialState,
      },
      (value) => {
        if (!value) {
          return undefined;
        }
        return value;
      },
    );
    const newPath = generatePath(pathPattern, newState) + search;

    push(newPath);
  };

  const state = mapper(currentParams);

  return [state, setState];
};

export default useUrlParamState;
