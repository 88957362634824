import React, { useState } from 'react';
import PropTypes from 'prop-types';

import DeleteIcon from '../../images/icon-x.svg';

const TimeWindow = ({
  startValue: initialStartValue,
  endValue: initialEndValue,
  onChange,
  onDelete,
  deletable,
}) => {
  const [startValue, setStartValue] = useState(initialStartValue);
  const [endValue, setEndValue] = useState(initialEndValue);

  const handleStartTimeChange = (ev) => {
    setStartValue(ev.target.value);
  };

  const handleEndTimeChange = (ev) => {
    setEndValue(ev.target.value);
  };

  const handleBlur = () => {
    const [startHour, startMin] = startValue.split(':').map((val) => parseInt(val, 10));
    const [endHour, endMin] = endValue.split(':').map((val) => parseInt(val, 10));

    const startMinutes = startHour * 60 + startMin;
    const endMinutes = endHour * 60 + endMin;

    let adjustedEndValue = endValue;

    if (endMinutes <= startMinutes) {
      adjustedEndValue = '23:59';

      setEndValue(adjustedEndValue);
    }
    onChange({ start: startValue, end: adjustedEndValue });
  };

  return (
    <div className="connect-tech-settings__day__block">
      <div className="connect-tech-settings__day__range">
        <div className="connect-tech-settings__day__range__unit right">
          <input
            className="connect-tech-settings__day__range__input"
            type="time"
            value={startValue}
            onChange={handleStartTimeChange}
            onBlur={handleBlur}
          />
          Start time
        </div>
        <div className="connect-tech-settings__day__range__unit">
          <input
            className="connect-tech-settings__day__range__input"
            type="time"
            value={endValue}
            onChange={handleEndTimeChange}
            onBlur={handleBlur}
          />
          End Time
        </div>
        {
          deletable && (
            <button
              type="button"
              className="connect-tech-settings__day__range__delete"
              onClick={() => onDelete()}
            >
              <img
                className="connect-tech-settings__day__range__delete__icon"
                src={DeleteIcon}
                alt="remove"
              />
            </button>
          )
        }
      </div>
    </div>
  );
};
TimeWindow.propTypes = {
  startValue: PropTypes.string,
  endValue: PropTypes.string,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  deletable: PropTypes.bool,
};

export default TimeWindow;
