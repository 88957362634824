import React, { ComponentType } from 'react';

export default function withProps<
  TInputProps = any,
  TOutputProps extends TInputProps = any,
>(
  mapper: (inputProps: TInputProps) => TOutputProps = (props: TInputProps) => props as TOutputProps,
) {
  return (
    component: ComponentType<any>,
  ) => function WithPropsWrapper(props: TInputProps) {
    const TargetComponent = component;

    return (
      <TargetComponent
        {...props}
        {...mapper(props)}
      />
    );
  };
}
