import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobxPropTypes } from 'mobx-react';
import { find } from 'lodash';
import { compose } from 'lodash/fp';

import {
  Button,
  ContentLoader,
  ModalDialog,
  ModalDialogHeader,
  ModalDialogContent,
  ModalDialogActions,
  ToggleButtons,
  ToggleButton,
  ViewHeaderContent,
  ViewLayout,
  ViewLayoutContent,
  ViewLayoutHeader,
  ContentLoaderBody,
} from '@jobox/react-components-library/mobile';

import withAnalytics from '../../components/withAnalytics';

import { useDidMount, useUrlParamState } from '../../hooks';
import ActionStatus from '../../lib/actionStatus';

import { ReasonsList, ReasonDetails, ResultSuccess } from './components';

import { ActionType } from './consts';

import classes from './CancelJob.module.scss';

const CancelJob = ({
  cancelJobStore,
  registerAnalyticsEvent,
}) => {
  const [
    {
      jobId,
      reasonId,
      command,
    },
    setState,
  ] = useUrlParamState(({ jobId, reasonId, ...otherParams }) => ({
    ...otherParams,
    jobId: parseInt(jobId, 10),
    reasonId: parseInt(reasonId, 10),
  }));
  const [isToggleVisible, setIsToggleVisible] = useState(false);
  const [
    reasonAdditionalDetails,
    setReasonAdditionalDetails,
  ] = useState({ description: '' });
  const {
    cancellationReasons,
    cancellationReasonsTree,
    jobRefId,
    rejectJob,
    revokeJob,
    updateJob,
  } = cancelJobStore;

  useDidMount(() => {
    cancelJobStore.initialize(jobId);

    // The action toggler should only be visible when
    // the first call is for Update
    setIsToggleVisible(command === ActionType.Update);
  }, [cancelJobStore.initialize]);

  const setReasonId = (reasonId) => {
    setState({ reasonId });
  };

  const setCommand = (command) => {
    setState({ command });
  };

  const submitHandler = (formData) => {
    const submitData = {
      ...formData,
      reason_id: reasonId,
    };

    const submitAction = ({
      [ActionType.Reject]: rejectJob,
      [ActionType.Revoke]: revokeJob,
      [ActionType.Update]: updateJob,
    })[command];

    if (submitAction) {
      submitAction.bind(cancelJobStore)(jobId, submitData);

      registerAnalyticsEvent({
        category: 'User Feedback',
        action: `User ${command === ActionType.Update ? 'updated' : 'cancelled'}`
          + `job ${jobRefId}${formData.reason ? `: ${formData.reason}` : ''}`,
        value: reasonId,
      });
    }
  };

  const backHandler = () => {
    setReasonId(null);
  };

  const clearRejectUpdateStatus = () => {
    cancelJobStore.updateStatus = ActionStatus.createIdle();
    cancelJobStore.rejectStatus = ActionStatus.createIdle();
    cancelJobStore.revokeStatus = ActionStatus.createIdle();
  };

  const selectedReason = find(cancellationReasons, { id: reasonId });

  const isInitialLoading = (
    ActionStatus.is(ActionStatus.Loading, cancelJobStore.fetchJobStatus)
    || ActionStatus.is(ActionStatus.Loading, cancelJobStore.fetchOccupationStatus)
  );
  const isSubmitLoading = (
    ActionStatus.is(ActionStatus.Loading, cancelJobStore.rejectStatus)
    || ActionStatus.is(ActionStatus.Loading, cancelJobStore.revokeStatus)
    || ActionStatus.is(ActionStatus.Loading, cancelJobStore.updateStatus)
  );
  const isSubmitSuccess = (
    ActionStatus.is(ActionStatus.Success, cancelJobStore.rejectStatus)
    || ActionStatus.is(ActionStatus.Success, cancelJobStore.revokeStatus)
    || ActionStatus.is(ActionStatus.Success, cancelJobStore.updateStatus)
  );

  return (
    <ContentLoader loading={isInitialLoading}>
      <ViewLayout>
        {
          isToggleVisible && (
            <ViewLayoutHeader>
              <ViewHeaderContent nav className={classes['toggle-container']}>
                <ToggleButtons activeId={command} onToggle={setCommand}>
                  <ToggleButton id={ActionType.Update}>Update Only</ToggleButton>
                  <ToggleButton id={ActionType.Reject}>Cancel Job</ToggleButton>
                </ToggleButtons>
              </ViewHeaderContent>
            </ViewLayoutHeader>
          )
        }
        <ViewLayoutContent>
          <ReasonsList
            selectedId={reasonId}
            onSelectedIdChanged={setReasonId}
            reasonsTree={cancellationReasonsTree}
          />

          <ReasonDetails
            isOpen={!!reasonId && !isSubmitSuccess}
            isLoading={isSubmitLoading}
            command={command}
            selectedReason={selectedReason}
            onCancel={backHandler}
            onSubmit={submitHandler}
            onChange={setReasonAdditionalDetails}
          />

          <ResultSuccess
            isOpen={isSubmitSuccess}
            command={command}
            selectedReason={selectedReason}
            reasonDetails={reasonAdditionalDetails}
          />

          { /* Cancel/Update job Failed Modal */ }
          <ModalDialog
            open={
              ActionStatus.is(ActionStatus.Error, cancelJobStore.rejectStatus)
              || ActionStatus.is(ActionStatus.Error, cancelJobStore.updateStatus)
            }
          >
            <ModalDialogHeader>
              Failed to submit data
            </ModalDialogHeader>
            <ModalDialogContent>
              We have encountered an error while submitting your data.
              {' '}
              Please try again or contact Jobox if the problem persists.
            </ModalDialogContent>
            <ModalDialogActions>
              <Button onClick={clearRejectUpdateStatus}>
                Close
              </Button>
            </ModalDialogActions>
          </ModalDialog>

          { /* Initial Loader */ }
          <ContentLoaderBody />
        </ViewLayoutContent>
      </ViewLayout>
    </ContentLoader>
  );
};
CancelJob.propTypes = {
  cancelJobStore: PropTypes.shape({
    initialize: PropTypes.func,
    cancellationReasonsTree: MobxPropTypes.arrayOrObservableArray,
    cancellationReasons: MobxPropTypes.arrayOrObservableArray,
    fetchJobStatus: MobxPropTypes.objectOrObservableObject,
    fetchOccupationStatus: MobxPropTypes.objectOrObservableObject,
    rejectStatus: MobxPropTypes.objectOrObservableObject,
    revokeStatus: MobxPropTypes.objectOrObservableObject,
    updateStatus: MobxPropTypes.objectOrObservableObject,
    rejectJob: PropTypes.func,
    revokeJob: PropTypes.func,
    updateJob: PropTypes.func,
    jobRefId: PropTypes.string,
  }),
  registerAnalyticsEvent: PropTypes.func,
};

export default compose(
  withAnalytics(),
  inject(({ cancelJobStore }) => ({
    cancelJobStore,
  })),
  observer,
)(CancelJob);
