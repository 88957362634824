import { useContext } from 'react';

import FeatureFlagsContext from './FeatureFlagsContext';

function useFeatureFlag(flagId) {
  const { flags } = useContext(FeatureFlagsContext);

  return flags[flagId] || false;
}

export default useFeatureFlag;
