import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react';
import moment from 'moment';
import { withRouter } from 'react-router';
import { observer, inject } from 'mobx-react';
import { compose } from 'lodash/fp';

import Highlights from '../../../components/PerformanceDashboard/Highlights/Highlights';
import DashboardCard from '../../../components/PerformanceDashboard/DashboardCard/DashboardCard';
import classes from './performanceDashboard.module.scss';
import JobStats from '../../../components/PerformanceDashboard/JobStats/JobStats';
import ArrowImg from '../../../images/avoid_miss_arrow.svg';
import HelpImg from '../../../images/help.svg';
import PerformanceDashboardStore from '../../../stores/PerformanceDashboardStore';
import UserHighlightsModel from '../../../models/UserHighlightsModel';
import RangeType, { constants, INFO } from '../../../constants/PerformanceDashboard';
import useOnEventOutside from '../../../hooks/useOnEventOutside';
import withProps from '../../../components/withProps';
import CloseModalIcon from '../../../images/close_modal_icon.svg';

interface PerformanceDashboardViewProps {
  match: {
    params: {
      userId: number;
    };
  };
  performanceDashboardStore: PerformanceDashboardStore;
  rangeQuery: any;
}

const PerformanceDashboardView = ({
  match: { params },
  performanceDashboardStore,
  rangeQuery,
}: PerformanceDashboardViewProps) => {
  const getInitState = (rangeQuery: any) => {
    if (rangeQuery && rangeQuery.rangeType && rangeQuery.startTime) {
      const { rangeType, startTime, marketplace } = rangeQuery;
      const rangeTypeString = rangeType.toLowerCase() === 'week' ? 'isoWeek' : rangeType.toLowerCase();
      return {
        from: moment(startTime * 1000).startOf(rangeTypeString),
        to: moment(startTime * 1000).endOf(rangeTypeString),
        range: rangeType,
        marketplace,
      };
    }
    return {
      from: moment().startOf('isoWeek'),
      to: moment().endOf('isoWeek'),
      range: 'WEEK',
      marketplace: true,
    };
  };

  const initStateMemoized = useMemo(() => getInitState(rangeQuery), [rangeQuery]);
  const [showModal, setShowModal] = useState(false);

  const [{
    from,
    to,
    range,
    marketplace,
  }, setRangePayload] = useState<{
    from: moment.Moment,
    to: moment.Moment,
    range: any,
    marketplace: boolean
  }>(initStateMemoized);

  const onChangeJobType = (e: any) => {
    setRangePayload({
      from,
      to,
      range,
      marketplace: e.target.value === 'true',
    });
  };

  const fetchIsUserConnect = async () => {
    const isConnect = await performanceDashboardStore.fetchIsUserConnect(params.userId);
    if (!isConnect) {
      setRangePayload({
        from,
        to,
        range,
        marketplace: false,
      });
    }
  };

  useEffect(() => {
    performanceDashboardStore.fetchUserHighlights(params.userId);
    fetchIsUserConnect();
  }, []);

  useEffect(() => {
    if (from && range) {
      performanceDashboardStore.fetchPerformanceStats(params.userId,
        from.unix() * 1000, range, !!marketplace);
    }
  }, [from, to, range, marketplace]);

  const onNextRange = () => {
    setRangePayload({
      from: from.clone().add(1, range.toLowerCase() === 'week' ? 'isoWeek' : range.toLowerCase()),
      to: to.clone().add(1, range.toLowerCase() === 'week' ? 'isoWeek' : range.toLowerCase()),
      range,
      marketplace,
    });
  };

  const onPrevRange = () => {
    setRangePayload({
      from: from.clone().subtract(1, range.toLowerCase() === 'week' ? 'isoWeek' : range.toLowerCase()),
      to: to.clone().subtract(1, range.toLowerCase() === 'week' ? 'isoWeek' : range.toLowerCase()),
      range,
      marketplace,
    });
  };

  const { isMarketplaceUser } = performanceDashboardStore;

  const renderHelpCard = () => (
    <a href={constants.PERFORMANCE_DASH_ARTICLE_URL}>
      <DashboardCard>
        <div className={classes.performance_dashboard_view__info_card}>
          <img src={HelpImg} alt="help" />
          <div className={classes.performance_dashboard_view__info_card_message}>
            <div className={classes.performance_dashboard_view__message_header}>
              How to avoid missing jobs
            </div>
            <div className={classes.performance_dashboard_view__message_body}>
              See tips and learn the ins and outs
            </div>
          </div>
          <img src={ArrowImg} alt="arrow" />
        </div>
      </DashboardCard>
    </a>
  );

  const renderEarningsInfoItem = (
    {
      header,
      subheader,
      listOfPoints,
    }: InfoItem,
    completed = true,
  ) => (
    <div className={classes.performance_dashboard_view__legend_entry}>
      <div className={classes.performance_dashboard_view__symbol_container}>
        <div
          className={`${classes.performance_dashboard_view__legend_symbol} ${classes[`performance_dashboard_view__fill_${completed ? 'completed' : 'missed'}`]}`}
        />
      </div>
      <div>
        <div className={classes.performance_dashboard_view__legend_header}><b>{header}</b></div>
        <div className={classes.performance_dashboard_view__legend_content}>
          {subheader}
          <br />
          <ul>
            {listOfPoints.map((point: string) => (
              <li key={point}>
                {point}
              </li>
            ))}
          </ul>
        </div>
      </div>

    </div>
  );

  const EarningsInfoModal = () => {
    const wrapperRef = useRef(null);
    useOnEventOutside(wrapperRef, () => setShowModal(false), ['mousedown']);
    return (
      <>
        <div className={classes.performance_dashboard_view__modal_backdrop} />
        <DashboardCard>
          <div className={classes.performance_dashboard_view__modal_container}>
            <div className={classes.performance_dashboard_view__modal_close}>
              <img src={CloseModalIcon} alt="close" />
            </div>
            <div
              className={classes.performance_dashboard_view__modal}
              ref={wrapperRef}
            >
              {renderEarningsInfoItem(INFO.COMPLETED)}
              {marketplace && renderEarningsInfoItem(INFO.MISSED, false)}
            </div>
          </div>
        </DashboardCard>
      </>
    );
  };

  return (
    <>
      <div
        className={classes.performance_dashboard_view__dashboard_container}
      >
        {isMarketplaceUser && (
        <Highlights
          userHighlights={performanceDashboardStore.userHighlights as UserHighlightsModel}
        />
        )}
        <JobStats
          userId={params.userId}
          from={from}
          to={to}
          maxTime={moment().endOf('week')}
          minTime={performanceDashboardStore.startedAt}
          rangeType={range as RangeType}
          onNextRange={onNextRange}
          onPrevRange={onPrevRange}
          onChangeJobType={onChangeJobType}
          connectJobFilter={marketplace}
          openInfoModal={() => setShowModal(true)}
        />
        {isMarketplaceUser && renderHelpCard()}
      </div>
      {showModal && <EarningsInfoModal />}
    </>
  );
};

export default compose(
  withProps((props) => {
    const { location: { state } } = props;
    const rangeQuery = {
      ...state,
      marketplace: !!(state && (state.marketplace === 'true' || state.marketplace === true)),
    };
    return {
      ...props,
      rangeQuery,
    };
  }),
  withRouter,
  inject('performanceDashboardStore'),
  observer,
)(PerformanceDashboardView);
