import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router';

import {
  useFeatureFlag,
  useFeautreFlagsInitialized,
} from '../FeatureFlagsProvider';

const FeatureFlagRoute = ({
  flag,
  fallback,
  ...routeProps
}) => {
  const featEnabled = useFeatureFlag(flag);
  const featureFlagsInitialized = useFeautreFlagsInitialized();

  if (!featureFlagsInitialized) {
    return null;
  }
  if (featEnabled) {
    return (
      <Route {...routeProps} />
    );
  }
  return fallback;
};
FeatureFlagRoute.propTypes = {
  flag: PropTypes.string.isRequired,
  fallback: PropTypes.node.isRequired,
};

export default FeatureFlagRoute;
