import React, { useEffect, useState } from 'react';
import { observer, inject, PropTypes as MobxPropTypes } from 'mobx-react';
import { toJS, autorun, when } from 'mobx';
import PropTypes from 'prop-types';
import { compose } from 'lodash/fp';
import {
  OnboardingButtons,
  ToggleItemCompact,
  YearRangePicker,
} from '@jobox/react-components-library/domain';
import { ToggleButtons, ToggleButton } from '@jobox/react-components-library/mobile';

import LoadingIcon from '../../../images/transparent-spinner.gif';
import withProps from '../../../components/withProps';

import './CarBrandsV2.scss';
import '../Skillset.scss';

const FILTERS = {
  SELECTED_BRANDS_FILTER: {
    key: 'SELECTED_BRANDS_FILTER',
    name: 'Selected Brands',
  },
  ALL_BRANDS_FILTER: {
    key: 'ALL_BRANDS_FILTER',
    name: 'All Brands',
  },
};

const CarBrandsV2 = ({
  basicProfileStore,
  skillId,
  jobServiceId,
  jobServiceName,
  usePresets,
  useNonPresets,
  defaultShowEnabled,
  doAutoConfigurePresets,
  removeSkillWithoutBrandsOnBack,
  removeSkillWithoutBrandsOnNext,
  contentTitle,
  contentSubtitle,
  enableSearch,
  enableToggleFilter,
  enableDatePicker,
  enableBrandToggle,
  enableNextButton,
  saveEagerly,
  history,
  prevLink,
  nextLink,
}) => {
  // Maps for fast lookup of the current and toggled state of brands
  const [enabledBrandsByIdMap, setEnabledBrandsByIdMap] = useState(new Map());
  const [toggledBrandsByIdMap, setToggledBrandsByIdMap] = useState(new Map());
  // A map of brand id to years for all brands with updated years
  const [currentYearsByIdMap, setCurrentYearsByIdMap] = useState(new Map());
  const [updatedYearsByIdMap, setUpdatedYearsByIdMap] = useState(new Map());
  // The subset of brands to be displayed after filtering
  const [displayedBrands, setDisplayedBrands] = useState([]);
  const [enableFilter, setEnableFilter] = useState();
  const [searchFilter, setSearchFilter] = useState();
  const [activeEnableFilterId, setActiveEnableFilterId] = useState(
    FILTERS.SELECTED_BRANDS_FILTER.key,
  );

  const getPresetCarBrands = () => toJS(
    basicProfileStore?.autoBrandPresets?.[jobServiceId]?.auto_brand_presets,
  );
  const getNonPresetCarBrands = () => toJS(
    basicProfileStore?.autoBrandPresets?.[jobServiceId]?.auto_brand_non_presets,
  );
  const getAllCarBrands = () => toJS(basicProfileStore?.autobrands);
  const getEnabledCarBrands = () => toJS(basicProfileStore?.autobrandsBySkill?.[skillId]);

  // retrieves a list of brands to be configurated on this page
  const getConfigurableCarBrands = () => {
    if (usePresets) {
      return getPresetCarBrands();
    }
    if (useNonPresets) {
      return getNonPresetCarBrands();
    }
    return getAllCarBrands();
  };

  const hasYearRangeChanged = (original, current) => (
    original?.yearEnd !== current?.yearEnd
    || original?.yearStart !== current?.yearStart
  );

  const syncCurrentlyEnabledBrands = (initialize = false) => {
    // Updates the map of currently enabled brands
    // and their associated year range data
    const updatedEnabledBrandsByIdMap = new Map();
    const updatedCurrentYearsByIdMap = new Map();
    getEnabledCarBrands().forEach((brand) => {
      updatedEnabledBrandsByIdMap.set(brand.id, brand.enabled);
      updatedCurrentYearsByIdMap.set(brand.id, {
        yearStart: brand.enabled ? brand.year_from : null,
        yearEnd: brand.enabled ? brand.year_to : null,
      });
    });
    setEnabledBrandsByIdMap(updatedEnabledBrandsByIdMap);
    setCurrentYearsByIdMap(updatedCurrentYearsByIdMap);

    // If presets are not auto configured
    // then perform initialization for the displayed state
    if (initialize) {
      setToggledBrandsByIdMap(updatedEnabledBrandsByIdMap);
      setUpdatedYearsByIdMap(updatedCurrentYearsByIdMap);
    }
  };

  const initializeCarBrands = (presetAutoBrands) => {
    // toggled the default list of brands to either ON or OFF
    // alternatively initialize using current brands from datastore
    if (!getEnabledCarBrands() || (usePresets && doAutoConfigurePresets)) {
      const updatedToggledBrandsByIdMap = new Map();
      presetAutoBrands.forEach((brand) => {
        updatedToggledBrandsByIdMap.set(brand.id, usePresets);
      });
      setToggledBrandsByIdMap(updatedToggledBrandsByIdMap);
    } else if (getEnabledCarBrands()) {
      syncCurrentlyEnabledBrands(true);
    }
  };

  const saveConfiguredCarBrands = () => {
    // saves all toggled brands state to the datastore
    const configurableBrands = getConfigurableCarBrands();
    if (!configurableBrands) {
      return;
    }
    // 1) for brands to be added, call the bulk add endpoint
    const carBrandsToAdd = configurableBrands
      .filter((brand) => (
        toggledBrandsByIdMap.get(brand.id) && !enabledBrandsByIdMap.get(brand.id)
      )).map((brand) => ({
        brand_id: brand.id,
        year_to: updatedYearsByIdMap.get(brand.id)?.yearEnd || null,
        year_from: updatedYearsByIdMap.get(brand.id)?.yearStart || null,
      }));
    // 2) for brands already added, but has the year range changed, call update endpoint
    const carBrandsToUpdate = configurableBrands
      .filter((brand) => (
        toggledBrandsByIdMap.get(brand.id) && enabledBrandsByIdMap.get(brand.id)
        && hasYearRangeChanged(currentYearsByIdMap.get(brand.id), updatedYearsByIdMap.get(brand.id))
      )).map((brand) => ({
        brand_id: brand.id,
        year_from: updatedYearsByIdMap.get(brand.id)?.yearStart || null,
        year_to: updatedYearsByIdMap.get(brand.id)?.yearEnd || null,
      }));
    // 3) for brands to be deleted, call the delete endpoint
    const carBrandsToDelete = configurableBrands.filter(
      (brand) => (!toggledBrandsByIdMap.get(brand.id) && enabledBrandsByIdMap.get(brand.id)),
    );
    // Make the actual datastore changes
    if (carBrandsToAdd.length > 0) {
      basicProfileStore?.selectManyCarBrands(skillId, carBrandsToAdd);
    }
    carBrandsToUpdate.forEach(
      (brand) => basicProfileStore?.updateUserSkillAutobrand(skillId, brand.brand_id, brand),
    );
    carBrandsToDelete.forEach(
      (brand) => basicProfileStore?.removeUserSkillAutobrand(skillId, brand.id),
    );
  };

  const onToggleCarBrand = (brandId) => {
    // toggles a specific brand and set year range automatically
    const updatedToggledBrandsByIdMap = new Map(toggledBrandsByIdMap);
    const newUpdatedYearsByIdMap = new Map(updatedYearsByIdMap);
    updatedToggledBrandsByIdMap.set(brandId, !toggledBrandsByIdMap.get(brandId));
    newUpdatedYearsByIdMap.set(brandId, {
      yearStart: null,
      yearEnd: null,
    });

    setToggledBrandsByIdMap(updatedToggledBrandsByIdMap);
    setUpdatedYearsByIdMap(newUpdatedYearsByIdMap);
  };

  const onSelectYearRange = (brandId, payload) => {
    // updates the displayed year range
    const newUpdatedYearsByIdMap = new Map(updatedYearsByIdMap);
    newUpdatedYearsByIdMap.set(brandId, {
      yearEnd: payload.yearEnd,
      yearStart: payload.yearStart,
    });
    setUpdatedYearsByIdMap(newUpdatedYearsByIdMap);
  };

  const onSearch = (val) => {
    // updates search filter based on input value
    const sanitizedInput = val.toLowerCase().trim();
    if (val?.length > 0) {
      setSearchFilter(() => (brands) => brands.filter(
        (brand) => brand.brand_name.toLowerCase().startsWith(sanitizedInput),
      ));
    } else {
      setSearchFilter(null);
    }
  };

  const onFilterBrands = (key) => {
    // updates the enable filter based on toggle
    setActiveEnableFilterId(key);
    if (key === FILTERS.SELECTED_BRANDS_FILTER.key) {
      setEnableFilter(() => (brands) => brands.filter(
        (brand) => enabledBrandsByIdMap.get(brand.id),
      ));
    } else if (key === FILTERS.ALL_BRANDS_FILTER.key) {
      setEnableFilter(null);
    }
  };

  const doRemoveSkillWithoutBrands = () => {
    const noBrands = !getEnabledCarBrands()?.some((brand) => brand.enabled);
    if (noBrands) {
      basicProfileStore?.removeSkill(skillId);
    }
    return noBrands;
  };

  const goToPath = (path) => {
    // replace the current entry in history instead of pushing
    // to make skillset page's back button work properly
    history.replace({
      pathname: path,
      state: {
        title: jobServiceName,
        serviceId: jobServiceId,
        doAutoConfigurePresets: false,
      },
    });
  };

  const onBack = () => {
    saveConfiguredCarBrands();
    let skillRemoved = false;
    if (removeSkillWithoutBrandsOnBack) {
      skillRemoved = doRemoveSkillWithoutBrands();
    }
    if (skillRemoved) {
      history.goBack();
    } else if (prevLink) {
      goToPath(prevLink);
    } else {
      history.goBack();
    }
  };

  const onNext = () => {
    saveConfiguredCarBrands();
    let skillRemoved = false;
    if (removeSkillWithoutBrandsOnNext) {
      skillRemoved = doRemoveSkillWithoutBrands();
    }
    if (skillRemoved) {
      history.goBack();
    } else if (nextLink) {
      goToPath(nextLink);
    } else {
      history.goBack();
    }
  };

  useEffect(() => {
    // watch for changes to filters to apply to displayed brands
    let newDisplayedBrands = getConfigurableCarBrands();
    if (newDisplayedBrands) {
      if (enableFilter) {
        newDisplayedBrands = enableFilter(newDisplayedBrands);
      }
      if (searchFilter) {
        newDisplayedBrands = searchFilter(newDisplayedBrands);
      }
      setDisplayedBrands(newDisplayedBrands);
    }
  }, [enableFilter, searchFilter]);

  useEffect(() => {
    // fetches presets into the datastore as soon as possible
    const brands = getConfigurableCarBrands();
    if (basicProfileStore && !brands) {
      basicProfileStore.getAutoBrandPresets(jobServiceId);
    }
  }, [basicProfileStore]);

  useEffect(() => {
    // performs initializtion of display once we have fetched data
    const disposer = when(
      () => !!getConfigurableCarBrands(),
      () => {
        if (defaultShowEnabled && getEnabledCarBrands()) {
          const newDisplayedBrands = getConfigurableCarBrands().filter(
            (brand) => getEnabledCarBrands().some((b) => b.id === brand.id && b.enabled),
          );
          setDisplayedBrands(newDisplayedBrands);
        } else {
          setDisplayedBrands(getConfigurableCarBrands());
        }
        initializeCarBrands(getConfigurableCarBrands());
      },
    );
    return () => {
      disposer();
    };
  }, []);

  useEffect(() => {
    // watch for unsaved changes and save accordingly
    // do not save on the initialization of the states
    if (saveEagerly && (toggledBrandsByIdMap.size !== 0)) {
      saveConfiguredCarBrands();
    }
  }, [toggledBrandsByIdMap, updatedYearsByIdMap]);

  useEffect(() => {
    // syncs enabled car brands with the component's states
    const disposer = autorun(() => {
      if (getEnabledCarBrands()) {
        syncCurrentlyEnabledBrands();
      }
    });
    return () => {
      disposer();
    };
  }, []);

  const renderToggle = (brand) => {
    const enabled = toggledBrandsByIdMap.get(brand.id);
    const yearStart = enabled ? updatedYearsByIdMap.get(brand.id)?.yearStart : null;
    const yearEnd = enabled ? updatedYearsByIdMap.get(brand.id)?.yearEnd : null;
    return (
      <ToggleItemCompact
        key={brand.id}
        title={brand.brand_name}
        enabled={enabled}
        showToggleButton={enableBrandToggle}
        onToggle={() => onToggleCarBrand(brand.id)}
      >
        {enableDatePicker && (
          <YearRangePicker
            minYear={1950}
            enabled={enabled}
            currentYearStart={yearStart}
            currentYearEnd={yearEnd}
            onSetYearRange={(payload) => onSelectYearRange(brand.id, payload)}
          />
        )}
      </ToggleItemCompact>
    );
  };

  const renderToggleList = (brands) => (
    <div className="connect-tech-settings__car-brands-v2__brand-toggles">
      {brands.map((brand) => renderToggle(brand))}
    </div>
  );

  const renderToggleFilter = () => (
    <div className="connect-tech-settings__car-brands-v2__toggle-filter-button-container">
      <ToggleButtons activeId={activeEnableFilterId} onToggle={onFilterBrands}>
        <ToggleButton id={FILTERS.SELECTED_BRANDS_FILTER.key}>
          {FILTERS.SELECTED_BRANDS_FILTER.name}
        </ToggleButton>
        <ToggleButton id={FILTERS.ALL_BRANDS_FILTER.key}>
          {FILTERS.ALL_BRANDS_FILTER.name}
        </ToggleButton>
      </ToggleButtons>
    </div>
  );

  const renderSearchContainer = () => (
    <div className="connect-tech-settings__car-brands-v2__search-container">
      <input
        type="text"
        id="brand-search"
        placeholder="Search Brands"
        className="connect-tech-settings__car-brands-v2__search"
        onChange={(e) => onSearch(e.target.value)}
      />
    </div>
  );

  const renderDefaultTitle = () => (
    <span>
      Car Brands for&nbsp;
      <span className="connect-tech-settings__car-brands-v2__hightlighted-text">
        {jobServiceName}
      </span>
    </span>
  );

  return (
    <div className="connect-tech-settings__car-brands-v2__wrapper">
      <div className="connect-tech-settings__car-brands-v2">
        <div className="connect-tech-settings__car-brands-v2__fixed">
          {basicProfileStore?.isError && (
            <div className="connect-tech-settings__error">
              Something went wrong, try again.
            </div>
          )}
          <div className="connect-tech-settings__car-brands-v2__title">
            <div className="connect-tech-settings__car-brands-v2__title__header">
              {contentTitle
                ? contentTitle.replace(':serviceName', jobServiceName)
                : renderDefaultTitle()}
            </div>
            <div className="connect-tech-settings__car-brands-v2__title__subheader">
              {contentSubtitle.replace(':serviceName', jobServiceName)}
            </div>
          </div>
          {enableToggleFilter && renderToggleFilter()}
          {enableSearch && renderSearchContainer()}
          <div className="connect-tech-settings__car-brands-v2__gradient-box" />
        </div>
        {displayedBrands && renderToggleList(displayedBrands)}
        {basicProfileStore?.isLoading && (
          <div className="connect-tech-settings__skillset__backdrop-high">
            <img
              className="connect-tech-settings__loading"
              src={LoadingIcon}
              alt="Loading"
            />
          </div>
        )}
      </div>
      <OnboardingButtons
        nextEnabled={enableNextButton}
        backEnabled
        completable={false}
        nextOnclick={onNext}
        backOnClick={onBack}
      />
    </div>
  );
};

CarBrandsV2.propTypes = {
  basicProfileStore: PropTypes.shape({
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    autobrands: MobxPropTypes.observableArray,
    autoBrandPresets: MobxPropTypes.observableObject,
    autobrandsBySkill: MobxPropTypes.observableObject,
    getAutoBrandPresets: PropTypes.func,
    selectManyCarBrands: PropTypes.func,
    updateUserSkillAutobrand: PropTypes.func,
    removeUserSkillAutobrand: PropTypes.func,
    removeSkill: PropTypes.func,
  }),
  jobServiceId: PropTypes.number,
  jobServiceName: PropTypes.string,
  skillId: PropTypes.number,
  doAutoConfigurePresets: PropTypes.bool,
  history: PropTypes.shape({
    goBack: PropTypes.func,
    push: PropTypes.func,
    replace: PropTypes.func,
  }),
  // customization
  usePresets: PropTypes.bool,
  useNonPresets: PropTypes.bool,
  defaultShowEnabled: PropTypes.bool,
  enableSearch: PropTypes.bool,
  enableToggleFilter: PropTypes.bool,
  enableDatePicker: PropTypes.bool,
  enableBrandToggle: PropTypes.bool,
  enableNextButton: PropTypes.bool,
  saveEagerly: PropTypes.bool,
  removeSkillWithoutBrandsOnBack: PropTypes.bool,
  removeSkillWithoutBrandsOnNext: PropTypes.bool,
  contentTitle: PropTypes.string,
  contentSubtitle: PropTypes.string,
  // routing
  prevLink: PropTypes.string,
  nextLink: PropTypes.string,
};

export default compose(
  withProps(({
    match: { params: { skillId } },
    location: {
      state: {
        title,
        serviceId,
        doAutoConfigurePresets,
      },
    },
  }) => ({
    skillId: parseInt(skillId, 10),
    jobServiceId: serviceId,
    jobServiceName: title,
    doAutoConfigurePresets,
  })),
  inject((stores) => ({
    basicProfileStore: stores.connectTechSettingsStore.basicProfileStore,
  })),
  observer,
)(CarBrandsV2);
