import React from 'react';
import DashboardCard from '../DashboardCard/DashboardCard';
import classes from './highlights.module.scss';
import moneyIcon from '../../../images/money.svg';
import UserHighlightsModel from '../../../models/UserHighlightsModel';
import formatPrice from '../../../utils/CurrencyUtils';
import { formatDate } from '../../../lib/date';

interface HighlightsProps {
  userHighlights: UserHighlightsModel;
}
const Highlights = ({
  userHighlights: {
    totalEarning,
    startedAt,
    bestWeek,
    bestMonth,
  },
}: HighlightsProps) => (
  <DashboardCard>
    <div className={classes.highlights__container}>
      <div className={classes.highlights__top}>
        <img src={moneyIcon} alt="money" />
        <div className={classes.highlights__lifetime_earnings}>
          <div className={classes.highlights__header}>Total lifetime earnings</div>
          <div className={classes.highlights__amount_lifetime}>
            {formatPrice(totalEarning)}
          </div>
          <div className={classes.highlights__label}>
            {'Since '}
            {formatDate(startedAt, 'MM/DD/YYYY')}
          </div>
        </div>
      </div>
      <div className={classes.highlights__bottom}>
        <div className={`${classes.highlights__best} ${classes.highlights__right_border}`}>
          <div className={classes.highlights__header}>Best week</div>
          <div className={classes.highlights__amount}>
            {formatPrice(bestWeek?.amount)}
          </div>
          <div className={classes.highlights__label}>{bestWeek?.range}</div>
        </div>
        <div className={classes.highlights__best}>
          <div className={classes.highlights__header}>Best month</div>
          <div className={classes.highlights__amount}>
            {formatPrice(bestMonth?.amount)}
          </div>
          <div className={classes.highlights__label}>{bestMonth?.range}</div>
        </div>
      </div>
    </div>
  </DashboardCard>
);

export default Highlights;
