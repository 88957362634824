import React from 'react';
import DashboardCard from '../DashboardCard/DashboardCard';
import classes from './jobInfoCard.module.scss';
import SelectorArrow from '../../../images/selector_arrow.svg';
import JobInfoModel from '../../../models/JobInfoModel';
import { cleanJobRefId, formatAddress, formatDateForJobCard } from '../../../constants/PerformanceDashboard';
import formatPrice from '../../../utils/CurrencyUtils';

interface JobInfoCardProps {
  job: JobInfoModel;
  closed: boolean;
}

interface LinkWrapperProps {
  referenceId?: string;
  children: any;
  closed: boolean;
}

const LinkToJobWrapper = ({ referenceId, children, closed }: LinkWrapperProps) => {
  if (referenceId && closed) {
    return (
      <a href={`jobox://job/${cleanJobRefId(referenceId)}`}>
        {children}
      </a>
    );
  }
  return children;
};

const JobInfoCard = ({
  job: {
    address,
    createdAt,
    closingPrice,
    eta,
    referenceId,
    zip,
  },
  closed,
}: JobInfoCardProps) => (
  <LinkToJobWrapper referenceId={referenceId} closed={closed}>
    <DashboardCard>
      <div
        className={classes.job_info__container}
      >
        <div className={classes.job_info__description}>
          <div className={classes.job_info__job_address}>{formatAddress(address) || zip}</div>
          <div className={classes.job_info__job_label}>{formatDateForJobCard(new Date(createdAt || ''))}</div>
        </div>
        <div className={classes.job_info__job_cost}>
          <div>
            {closed ? (
              `${formatPrice(closingPrice)}`
            ) : (
              <div className={`${classes.job_info__job_eta} ${classes.job_info__job_eta_grey}`}>ETA</div>
            )}
          </div>
          {closed ? (
            <img src={SelectorArrow} alt="arrow" />
          ) : (
            <div className={classes.job_info__job_eta}>{eta}</div>
          )}
        </div>
      </div>
    </DashboardCard>
  </LinkToJobWrapper>
);

export default JobInfoCard;
