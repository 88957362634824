import React, { useEffect, useState } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import PropTypes from 'prop-types';
import { compose } from 'lodash/fp';
import {
  useLocation,
  useHistory,
  useRouteMatch,
  useParams,
} from 'react-router';
import { OnboardingButtons } from '@jobox/react-components-library/domain';

import MySiteRoutes from '../MySiteRoutes';

import classes from './OnboardingFlow.module.scss';

const OnboardingFlow = ({
  connectTechSettingsStore,
}) => {
  const { userId } = useParams();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  const [categories, setCategoriesState] = useState([]);
  const [nextRoute, setNextRoute] = useState(null);
  const [prevRoutes, setPrevRoutes] = useState([]);
  const finalRoutes = [
    `/mysite/${userId}/onboarding/availability`,
  ];
  const [selectedCategoryRoutes, setSelectedCategoryRoutes] = useState(finalRoutes);

  const skillset = connectTechSettingsStore ? connectTechSettingsStore.getJobSkillset() : null;

  useEffect(() => {
    if (!connectTechSettingsStore.independentProfileStore) {
      connectTechSettingsStore.fetchConnectTechSettings(match.params.userId);
    }
    // reset error states when navigating across pages
    connectTechSettingsStore.resetErrors();
  }, [match, connectTechSettingsStore]);

  useEffect(() => {
    const configureNextRoute = () => {
      setNextRoute(selectedCategoryRoutes[0]);
    };
    configureNextRoute();
  }, [selectedCategoryRoutes]);

  useEffect(() => {
    if (!categories || categories.length === 0) {
      if (skillset) setCategoriesState(skillset);
    }
  }, [skillset]);

  const setCategories = async (newCategories) => {
    setCategoriesState(newCategories);
    if (newCategories) {
      if (newCategories.length === 0) {
        setSelectedCategoryRoutes(finalRoutes.slice(1));

        history.push(finalRoutes[0]);

        return;
      }
      const enabledCategories = newCategories
        .filter((c) => c.enabled)
        .map((c) => `/mysite/${userId}/onboarding/skillset/${connectTechSettingsStore.occupationId}/subcategory/${c.id}`);

      setSelectedCategoryRoutes(
        enabledCategories.length > 0
          ? [...enabledCategories, ...finalRoutes]
          : [],
      );
    }
  };

  const next = () => {
    if (selectedCategoryRoutes.length > 0) {
      const currRoute = nextRoute;

      setSelectedCategoryRoutes(selectedCategoryRoutes.slice(1));

      if (!prevRoutes.includes(currRoute)) {
        setPrevRoutes([...prevRoutes, currRoute]);
      }

      history.push(currRoute);
    } else {
      history.push(`/mysite/${userId}`);
    }
  };

  const back = () => {
    const lastRoute = prevRoutes[prevRoutes.length - 1];
    if (
      lastRoute
      && !selectedCategoryRoutes.includes(lastRoute)
      && prevRoutes.includes(location.pathname) && !location.pathname.includes('carbrands')
    ) {
      setSelectedCategoryRoutes([lastRoute, ...selectedCategoryRoutes]);
    }
    if (!location.pathname.includes('carbrands')) {
      setPrevRoutes(prevRoutes.slice(0, prevRoutes.length - 1));
    }
    history.goBack();
  };

  const enableBack = !location.pathname.includes('finalCheck');

  return (
    <>
      <div className={classes['routing-container']}>
        <MySiteRoutes
          categories={categories}
          setCategories={setCategories}
          isOnboarding
        />
      </div>

      <OnboardingButtons
        nextEnabled={enableBack}
        backEnabled={enableBack}
        completable={!enableBack}
        completionLink="jobox://connect-setting-complete"
        nextOnclick={next}
        backOnClick={back}
      />
    </>
  );
};

OnboardingFlow.propTypes = {
  connectTechSettingsStore: PropTypes.shape({
    independentProfileStore: MobXPropTypes.observableObject,
    occupationId: PropTypes.number,
    fetchConnectTechSettings: PropTypes.func,
    resetErrors: PropTypes.func,
    getJobSkillset: PropTypes.func,
  }),
};

export default compose(
  inject((stores) => ({
    connectTechSettingsStore: stores.connectTechSettingsStore,
    occupationsStore: stores.connectTechSettingsStore.occupationsStore,
  })),
  observer,
)(OnboardingFlow);
