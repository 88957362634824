/* eslint-disable no-console */
import React from 'react';
import ReactGA from 'react-ga';
import Button from '../../../components/MySite/Button/Button';
import shareIcon from '../../../images/share.svg';
import config from '../../../config';

interface ShareButtonProps {
  url: string;
  businessName: string;
  className?: string;
}

const ShareButton = ({ businessName, url, className }: ShareButtonProps) => {
  const { MYSITE_URL } = config;
  const siteURL = `https://${MYSITE_URL}/${url}`;

  const shareData = {
    title: businessName,
    url: siteURL,
  };

  const shareDataAndroid = {
    ...shareData,
    text: '',
  };

  const handleShare = () => {
    ReactGA.event({
      category: 'event',
      action: 'SHARE_MICROSITE',
    });

    // @ts-ignore
    if (window.Android) {
      // @ts-ignore
      window.Android.share(shareDataAndroid.title, shareDataAndroid.text, shareDataAndroid.url);
      return;
    }
    if (navigator.share) {
      navigator.share(shareData)
        .then(() => {})
        .catch((error) => console.error('Error sharing', error));
    }
  };

  return (
    <Button
      className={className}
      onClick={() => handleShare()}
      label="Share"
      icon={shareIcon}
    />
  );
};

export default ShareButton;
