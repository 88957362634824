import React from 'react';
import { Link } from 'react-router-dom';
import classes from './rangeDisplay.module.scss';
import ArrowsIcon from '../../../images/filter-arrow.svg';
import CalendarIcon from '../../../images/calendar-icon.svg';
import RightArrow from '../../../images/selector_arrow.svg';
import RangeType from '../../../constants/PerformanceDashboard';

interface SelectFilterProps {
  from: moment.Moment;
  to: moment.Moment;
  range: RangeType;
  onPrev?: () => void;
  onNext?: () => void;
  linkToCalendar?: string;
  disableNext?: boolean;
  disablePrev?: boolean;
  stateToCalendar?: {
    from: number,
    to: number,
    rangeType: RangeType,
    minTime?: number,
    marketplace: boolean
  };
}

const RangeDisplay = ({
  from,
  to,
  onPrev,
  onNext,
  linkToCalendar,
  range,
  disableNext,
  disablePrev,
  stateToCalendar,
}: SelectFilterProps) => {
  const displayValue = () => {
    switch (range) {
      case RangeType.WEEK:
        return `${from.format('MM/DD')} - ${to.format('MM/DD')}`;
      case RangeType.MONTH:
        return from.format('MMM YYYY');
      case RangeType.YEAR:
        return from.format('YYYY');
      default:
        return `${from.format('MM/DD')} - ${to.format('MM/DD')}`;
    }
  };
  return (
    <div className={classes.range_display__container}>
      <button className={classes.range_display__navigation} type="button" onClick={onPrev} disabled={disablePrev}>
        <img src={RightArrow} alt="arrows" className={classes.range_display__back_arrow} />
      </button>
      {linkToCalendar ? (
        <Link to={{
          pathname: linkToCalendar,
          state: stateToCalendar,
        }}
        >
          <div className={classes.range_display__filter}>
            <img src={CalendarIcon} alt="calendar" />
            <div className={classes.range_display__selector}>
              {displayValue()}
            </div>
            <img src={ArrowsIcon} alt="arrows" />
          </div>
        </Link>
      ) : (
        <div className={classes.range_display__filter}>
          <img src={CalendarIcon} alt="calendar" />
          <div className={classes.range_display__selector}>
            {displayValue()}
          </div>
          <img src={ArrowsIcon} alt="arrows" />
        </div>
      )}
      <button className={classes.range_display__navigation} type="button" onClick={onNext} disabled={disableNext}>
        <img src={RightArrow} alt="arrows" />
      </button>
    </div>
  );
};

export default RangeDisplay;
