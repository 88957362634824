import React from 'react';
import { useParams } from 'react-router';
import { observer, inject } from 'mobx-react';
import { Link } from 'react-router-dom';
import { compose } from 'lodash/fp';

import DashboardCard from '../DashboardCard/DashboardCard';
import classes from './jobStats.module.scss';
import SelectorArrow from '../../../images/selector_arrow.svg';
import SelectFilter from '../SelectFilter/SelectFilter';
import JobStatChart from '../JobStatChart/JobStatChart';
import PerformanceDashboardStore from '../../../stores/PerformanceDashboardStore';
import UserJobStatsModel from '../../../models/UserJobStatsModel';
import RangeDisplay from '../RangeDisplay/RangeDisplay';
import RangeType from '../../../constants/PerformanceDashboard';
import formatPrice from '../../../utils/CurrencyUtils';

interface JobStatsProps {
  performanceDashboardStore: PerformanceDashboardStore;
  from: moment.Moment;
  to: moment.Moment;
  rangeType: RangeType;
  onNextRange: () => void;
  onPrevRange: () => void;
  maxTime?: moment.Moment;
  minTime?: moment.Moment;
  connectJobFilter: boolean;
  onChangeJobType: (event: any) => void;
  openInfoModal: () => void;
}

const JobStats = ({
  performanceDashboardStore,
  from,
  to,
  rangeType,
  connectJobFilter,
  onNextRange,
  onPrevRange,
  onChangeJobType,
  maxTime,
  minTime,
  openInfoModal,
}: JobStatsProps) => {
  const {
    totalJobsCompleted,
    totalJobsMissed,
    totalAmountMissed,
    totalAmountEarned,
    stats,
  } = performanceDashboardStore.userPerformanceData as UserJobStatsModel;
  const { userId } = useParams<{ userId: string }>();

  const renderMissedEarningsSection = () => (
    <div className={classes.job_stats__missed_earnings_section}>
      <div className={classes.job_stats__missed_earning_header}>
        Total missed earnings (est.)
      </div>
      <div className={classes.job_stats__missed_earning_est}>
        {formatPrice(totalAmountMissed)}
      </div>
      <div className={classes.job_stats__label}>{`${totalJobsMissed} missed jobs`}</div>
    </div>
  );

  const { isMarketplaceUser } = performanceDashboardStore;

  const JOB_TYPE_FILTER: Array<{ label: string; value: boolean }> = [
    { label: 'All jobs', value: false },
    { label: 'Connect jobs', value: true },
  ];

  return (
    <DashboardCard>
      <>
        {isMarketplaceUser && (
          <SelectFilter
            options={JOB_TYPE_FILTER}
            onSelect={onChangeJobType}
            value={JOB_TYPE_FILTER.find((filter) => filter.value === connectJobFilter)}
          />
        )}
        <div className={classes.job_stats__total_container}>
          <div className={classes.job_stats__total_text}>Total earned</div>
          <div className={classes.job_stats__total_value}>
            {formatPrice(totalAmountEarned)}
          </div>
        </div>
        <div className={classes.job_stats__chart}>
          {performanceDashboardStore.loading ? (
            <div className={classes.job_stats__missed_earnings_section}>
              <div className="spinner-border" role="status" />
            </div>
          ) : (
            <JobStatChart
              jobDataArr={stats || []}
              rangeType={rangeType}
              marketplace={connectJobFilter}
              openInfoModal={openInfoModal}
              to={to}
            />
          )}
        </div>
        <RangeDisplay
          from={from}
          to={to}
          range={rangeType}
          linkToCalendar={`/performance/${userId}/select_range`}
          stateToCalendar={{
            from: from.unix() * 1000,
            to: to.unix() * 1000,
            minTime: minTime && (minTime.unix() * 1000),
            rangeType,
            marketplace: connectJobFilter,
          }}
          onNext={onNextRange}
          onPrev={onPrevRange}
          disableNext={maxTime && to.isSameOrAfter(maxTime)}
          disablePrev={minTime && from.isSameOrBefore(minTime)}
        />
        {isMarketplaceUser && renderMissedEarningsSection()}
        <Link
          to={{
            pathname: `/performance/${userId}/closed_jobs`,
            state: {
              from: from.unix() * 1000,
              to: to.unix() * 1000,
              rangeType,
              marketplace: connectJobFilter,
            },
          }}
          className={classes.job_stats__link}
        >
          <div className={classes.job_stats__selector}>
            <div className={classes.job_stats__selector_title}>Closed jobs</div>
            <div className={classes.job_stats__selector_desc}>
              <div>{totalJobsCompleted}</div>
              <img src={SelectorArrow} alt="arrow" />
            </div>
          </div>
        </Link>
        {connectJobFilter && (
        <Link
          to={{
            pathname: `/performance/${userId}/missed_jobs`,
            state: {
              from: from.unix() * 1000,
              to: to.unix() * 1000,
              rangeType,
              marketplace: connectJobFilter,
            },
          }}
          className={classes.job_stats__link}
        >
          <div className={classes.job_stats__selector}>
            <div className={classes.job_stats__selector_title}>Missed job offers</div>
            <div className={classes.job_stats__selector_desc}>
              <div>{totalJobsMissed}</div>
              <img src={SelectorArrow} alt="arrow" />
            </div>
          </div>
        </Link>
        )}
      </>
    </DashboardCard>
  );
};

export default compose(
  inject('performanceDashboardStore'),
  observer,
)(JobStats);
