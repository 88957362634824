class UserHighlightsModel {
  bestMonth?: {
    range: string;
    amount: number;
  };
  bestWeek?: {
    range: string;
    amount: number;
  };
  startedAt?: Date;
  totalEarning: number | undefined;
  constructor(userHighlights?: UserHighlights) {
    if (userHighlights) {
      this.bestMonth = userHighlights.best_month;
      this.bestWeek = userHighlights.best_week;
      this.startedAt = new Date(userHighlights.started_at);
      this.totalEarning = userHighlights.total_earning;
    }
  }
}

export default UserHighlightsModel;
