/* eslint-disable no-console */
import React from 'react';
import { observer, inject } from 'mobx-react';
import { compose } from 'lodash/fp';
import cookieParser from 'cookie';
import { toJS } from 'mobx';
import { useParams } from 'react-router';

import areaIcon from '../../images/area.svg';
import siteIcon from '../../images/site.svg';
import basicIcon from '../../images/basic.svg';
import servicesIcon from '../../images/services.svg';
import hoursIcon from '../../images/hours.svg';
import reviewsIcon from '../../images/reviews.svg';

import classes from './mySiteDashboardView.module.scss';
import MenuItem from '../../components/MySite/MenuItem/MenuItem';
import Layout from '../../components/MySite/Layout/Layout';
import Header from '../../components/MySite/Header/Header';
import withLoading from '../../components/withLoading';
import config from '../../config';

import ShareButton from './ShareButton/ShareButton';
import PreviewButton from './PreviewButton/PreviewButton';
import { useFeatureFlag } from '../../components/FeatureFlagsProvider';

const flag = 'micrositesPhase2';

interface MySiteDashboardViewProps {
  mySiteSettingsStore: {
    mySite: {
      site_alias: string,
      business_name: string,
      active: boolean,
      work_radius: number,
    }
  },
  connectTechSettingsStore: any,
  reviewsStore: {
    total: number,
    visible: number,
  },
}

const MySiteDashboardView = ({
  mySiteSettingsStore, connectTechSettingsStore, reviewsStore,
}
: MySiteDashboardViewProps) => {
  const { userId } = useParams<{ userId: string }>();
  const featEnabled = useFeatureFlag(flag);

  const cookies = cookieParser.parse(document.cookie);

  const {
    site_alias: url, business_name: businessName, active, work_radius: radius,
  } = mySiteSettingsStore.mySite;

  const zipCode = connectTechSettingsStore.basicProfileStore
    && connectTechSettingsStore.basicProfileStore.basicProfile.baseZip;

  const occupations = connectTechSettingsStore.basicProfileStore
    && connectTechSettingsStore.basicProfileStore.skillset;

  const { occupationId } = connectTechSettingsStore;

  const occupation = occupationId && connectTechSettingsStore.occupationsStore
          && connectTechSettingsStore.occupationsStore.getOccupation(occupationId).name;

  const numberOfSkills = occupations ? occupations.length : 0;

  const avatar = connectTechSettingsStore.data.profile_pic_url;

  const { availability } = connectTechSettingsStore.independentProfileStore.independentProfile;
  const availabilityRoute = `/mysite/${userId}/${!availability ? 'onboarding/dailyavailability' : 'availability'}`;
  const skillsetRoute = `/mysite/${userId}/${numberOfSkills > 0 ? 'skillset' : 'onboarding'}`;

  const availabilityObject = toJS(availability);
  const timeWindows = availabilityObject.schedule.reduce(
    (prev: any, curr: { time_ranges: any[] }) => prev + curr.time_ranges.length, 0,
  );

  const { MYSITE_URL } = config;
  const { Authorization, Timestamp } = cookies;
  const siteURL = `https://${MYSITE_URL}/${url}`;
  const previewUrl = MYSITE_URL ? `${siteURL}?preview=true&authorization=${Authorization}&userId=${userId}&timestamp=${Timestamp}` : `/mysite/${userId}`;

  const { total, visible } = reviewsStore;
  const reviewsRoute = total > 0 ? `/mysite/${userId}/reviews` : '#';

  const statusMenuItem = active
    ? (
      <>
        <div className={classes.indicator} />
        <span>Published</span>
      </>
    )
    : (
      <>
        <div className={`${classes.indicator} ${classes.indicatorHidden}`} />
        <span>Hidden</span>
      </>
    );

  const areaMenuItem = (
    <span>{`${zipCode || 'Not set'} (+${radius}mi radius)`}</span>
  );

  const servicesMenuItem = (
    <span>{`${occupation || ''} (${numberOfSkills} service${numberOfSkills === 1 ? '' : 's'})`}</span>
  );

  const basicMenuItem = (
    <span>{businessName}</span>
  );

  const hoursMenuItem = (
    <span>
      {timeWindows > 0 ? `${timeWindows} time window${timeWindows > 1 ? 's' : ''}` : 'Not set'}
    </span>
  );

  const reviewsMenuItem = (
    <span>{`${total} total (${visible} displayed)`}</span>
  );

  return (
    <main className={classes.main}>
      <Header name={businessName} url={url} avatar={avatar} />
      <div className={classes.buttons}>
        <PreviewButton previewUrl={previewUrl} />
        <ShareButton businessName={businessName} url={url} />
      </div>
      <div>
        <MenuItem
          to={`/mysite/${userId}/status`}
          icon={siteIcon}
          title="Site status"
          text={statusMenuItem}
        />
        <MenuItem
          to={`/mysite/${userId}/basic`}
          icon={basicIcon}
          title="Basic information"
          text={basicMenuItem}
        />
        <MenuItem
          to={skillsetRoute}
          icon={servicesIcon}
          title="Provided services"
          text={servicesMenuItem}
        />
        <MenuItem
          to={`/mysite/${userId}/area`}
          icon={areaIcon}
          title="Service area"
          text={areaMenuItem}
        />
        <MenuItem
          to={availabilityRoute}
          icon={hoursIcon}
          title="Working hours"
          text={hoursMenuItem}
        />
        {featEnabled && (
          <MenuItem
            to={reviewsRoute}
            icon={reviewsIcon}
            title="Customer reviews"
            text={reviewsMenuItem}
          />
        )}
      </div>
    </main>
  );
};

export default compose(
  inject(({ mySiteSettingsStore, connectTechSettingsStore, reviewsStore }) => ({
    mySiteSettingsStore,
    connectTechSettingsStore,
    reviewsStore,
  })),
  observer,
  withLoading<MySiteDashboardViewProps>({
    checkProps: ({ connectTechSettingsStore, mySiteSettingsStore }) => (
      connectTechSettingsStore.basicProfileStore && mySiteSettingsStore.mySite
    ),
    renderLoader: () => (
      <Layout loading />
    ),
  }),
)(MySiteDashboardView);
