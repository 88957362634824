/* eslint-disable no-alert */
import * as React from 'react';
import { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import { compose } from 'lodash/fp';
import { useParams } from 'react-router';

import Button from '../../components/MySite/Button/Button';
import Input from '../../components/MySite/Input/Input';
import classes from './mySiteBasicSettingsView.module.scss';
import Logo from '../../components/MySite/Logo/Logo';
import uploadIcon from '../../images/upload.svg';
import useDebounce from '../../hooks/useDebounce';
import formatForUrl from '../../lib/slug';
import Layout from '../../components/MySite/Layout/Layout';
import Toggle from '../../components/MySite/Toggle/Toggle';
import LoadingIcon from '../../images/transparent-spinner.gif';
import businessIcon from '../../images/business.svg';
import licenseIcon from '../../images/license.svg';
import warrantyIcon from '../../images/warranty.svg';
import CheckIcon from '../../images/check.svg';
import XIcon from '../../images/icon-x.svg';
import { useFeatureFlag } from '../../components/FeatureFlagsProvider';

const MAX_IMAGE_SIZE_IN_MB = 3;
const flag = 'micrositesPhase2';

interface MySiteBasicSettingsViewProps {
  mySiteSettingsStore: {
    mySite: {
      work_radius: string,
      site_alias: string,
      business_name: string,
      business_license: string,
      yelp_alias: string,
      show_business_license: boolean,
      show_warranty: boolean,
    },
    isLoading: boolean,
    isValidating: boolean,
    isAliasValid: boolean,
    updateMySiteSettings: any,
    checkMySiteAlias: any,
  },
  connectTechSettingsStore: any,
}

interface Event<T = EventTarget> {
  target: T;
}

const MySiteBasicSettingsView = ({ mySiteSettingsStore, connectTechSettingsStore }
: MySiteBasicSettingsViewProps) => {
  const featEnabled = useFeatureFlag(flag);
  const { userId } = useParams<{ userId: string }>();

  const businessNameInit = mySiteSettingsStore.mySite.business_name;
  const licenseInit = mySiteSettingsStore.mySite.business_license || '';
  const showLicenseInit = mySiteSettingsStore.mySite.show_business_license;
  const showWarrantyInit = mySiteSettingsStore.mySite.show_warranty;
  const aliasInit = mySiteSettingsStore.mySite.site_alias;
  const yelpInit = mySiteSettingsStore.mySite.yelp_alias || '';

  const [businessName, setBusinessName] = useState(businessNameInit);
  const [license, setLicense] = useState(licenseInit);
  const [showLicense, setShowLicense] = useState(showLicenseInit);
  const [showWarranty, setShowWarranty] = useState(showWarrantyInit);
  const [yelp, setYelp] = useState(yelpInit);
  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [logoURL, setLogoURL] = useState('');

  const [alias, setAlias] = useState(aliasInit);

  useEffect(() => (
    setBusinessName(businessNameInit)
  ), [businessNameInit]);

  useEffect(() => (
    setAlias(aliasInit)
  ), [aliasInit]);

  useEffect(() => (
    setLicense(licenseInit)
  ), [licenseInit]);

  useEffect(() => (
    setShowLicense(showLicenseInit)
  ), [showLicenseInit]);

  useEffect(() => (
    setShowWarranty(showWarrantyInit)
  ), [showWarrantyInit]);

  useEffect(() => (
    setYelp(yelpInit)
  ), [yelpInit]);

  const handleNameChange = (value: string): void => {
    setBusinessName(value);
  };

  const handleLicenseChange = (value: string): void => {
    setLicense(value);
  };

  const handleYelpChange = (value: string): void => {
    setYelp(value.replace(/\s/g, ''));
  };

  const debouncedSearchTerm = useDebounce(alias, 500);

  const validateAlias = (value: string) => {
    mySiteSettingsStore.checkMySiteAlias(value);
  };

  useEffect(
    () => {
      if (debouncedSearchTerm && debouncedSearchTerm !== aliasInit) {
        validateAlias(debouncedSearchTerm);
      }
    },
    [debouncedSearchTerm],
  );

  const handleImageChange = (event: Event<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];

    if (!file) {
      return false;
    }
    const allowedExtensions = ['png', 'jpg', 'gif'];
    const fileExtension = file.name.split('.').pop();
    const fileSizeInMb = file.size / 1024 / 1024;

    if (fileExtension && !allowedExtensions.includes(fileExtension)) {
      window.alert('Please upload PNG, GIF or JPG file');
      return false;
    }

    if (fileSizeInMb > MAX_IMAGE_SIZE_IN_MB) {
      window.alert(`File exceeds ${MAX_IMAGE_SIZE_IN_MB}MB`);
      return false;
    }

    setLogoFile(file);

    return setLogoURL(URL.createObjectURL(file));
  };

  const { isLoading, isValidating, isAliasValid } = mySiteSettingsStore;
  const canSubmit = ((alias !== '' && alias !== aliasInit)
      || (businessName !== '' && businessName !== businessNameInit)
      || (logoFile && businessName !== '' && alias !== '')
      || (license !== licenseInit)
      || (showLicense !== showLicenseInit)
      || (showWarranty !== showWarrantyInit)
      || (yelp !== yelpInit))
      && isAliasValid && !isValidating;

  const handleSubmit = () => {
    const n = yelp.lastIndexOf('/');
    const formattedYelp = yelp.substring(n + 1);

    mySiteSettingsStore.updateMySiteSettings({
      business_name: businessName,
      site_alias: alias,
      business_license: license,
      show_business_license: showLicense,
      show_warranty: showWarranty,
      yelp_alias: formattedYelp,
    });
    if (logoFile) {
      connectTechSettingsStore.upload(logoFile);
      setLogoFile(null);
    }
  };

  let indicator = null;
  if (alias !== aliasInit) {
    if (isValidating) {
      indicator = <img className={classes.loading} src={LoadingIcon} alt="Loading" />;
    } else {
      indicator = !isAliasValid || alias === '' ? <img className={classes.loading} src={XIcon} alt="error" />
        : <img className={classes.loading} src={CheckIcon} alt="ok" />;
    }
  }

  const avatar = logoURL || _.get(connectTechSettingsStore, 'data.profile_pic_url', '');

  return (
    <Layout userId={userId} title="Basic information" loading={isLoading}>
      <div className={classes.content}>
        <div className={classes.label}>Profile photo</div>
        <div className={classes.topRow}>
          <Logo avatar={avatar} />
          <div className={classes.upload}>
            <label htmlFor="file-upload">
              <div>
                <img src={uploadIcon} alt="file-upload" />
                Change photo
                <input id="file-upload" type="file" onChange={handleImageChange} accept="image/gif, image/png, image/jpg" />
              </div>
            </label>
          </div>
        </div>
        <div className={classes.label}>Business details</div>
        <Input icon={businessIcon} onChange={(e: { target: HTMLInputElement }) => { handleNameChange(e.target.value); }} label="Business name" value={businessName} name="businessName" id="businessName" />
        {featEnabled && (
          <>
            <Input
              icon={licenseIcon}
              onChange={(e: { target: HTMLInputElement }) => {
                handleLicenseChange(e.target.value);
              }}
              label="License number"
              value={license}
              name="license"
              id="license"
            />
            <Toggle onToggle={() => { setShowLicense(!showLicense); }} label="Show license number" id="licenseToggle" value={showLicense} />
          </>
        )}
        <span className={classes.copy}>
          Business name and license number will also be updated on your receipt preferences.
        </span>
        {featEnabled && (
          <>
            <div className={classes.label}>Warranty, Policies & Guarantees</div>
            <a href="jobox://warranty">
              <Input icon={warrantyIcon} onChange={() => {}} disabled label="Edit warranty" value="" name="warranty" id="warranty" />
            </a>
            <Toggle onToggle={() => { setShowWarranty(!showWarranty); }} label="Show warranty" id="warrantyToggle" value={showWarranty} />
          </>
        )}
        <div className={classes.label}>Site address</div>
        <Input indicator={indicator} onChange={(e: { target: HTMLInputElement }) => { setAlias(formatForUrl(e.target.value)); }} label="Address suffix" value={alias} name="alias" id="alias" />
        {!isAliasValid && alias !== aliasInit && <span>This address is already taken.</span>}
        {alias === '' && <span>The address cannot be empty.</span>}
        <div className={classes.copyBox}>
          <span className={classes.bold}>Full link:</span>
          <span className={classes.copy}>{`pros.jobox.ai/${alias}`}</span>
        </div>
        <div className={classes.copyBox}>
          <span className={classes.bold}>Important!</span>
          <span className={classes.copy}>
            Changing the address sufix will break all the previous links you might have shared.
          </span>
        </div>
        {featEnabled && (
          <>
            <div className={classes.label}>Yelp reviews</div>
            <Input onChange={(e: { target: HTMLInputElement }) => { handleYelpChange(e.target.value); }} label="Yelp profile link" value={yelp} name="yelp" id="yelp" />
            <span className={classes.copy}>
              Enter link to your Yelp profile and we will
              automatically display your Yelp reviews on your site.
            </span>
          </>
        )}
      </div>

      <Button onClick={() => handleSubmit()} label="Save" className={classes.button} disabled={!canSubmit} />
    </Layout>
  );
};

export default compose(
  inject(({ mySiteSettingsStore, connectTechSettingsStore }) => ({
    mySiteSettingsStore,
    connectTechSettingsStore,
  })),
  observer,
)(MySiteBasicSettingsView);
