import React from 'react';
import { Link } from 'react-router-dom';
import classes from './layout.module.scss';

import BackArrowIcon from '../../../images/icon-left-arrow.svg';
import LoadingIcon from '../../../images/transparent-spinner.gif';

interface LayoutProps {
  userId?: string,
  title?: string,
  children?: any,
  loading?: boolean,
  isOnboarding?: boolean,
  fullWidth?: boolean,
}

const Layout = ({
  userId, title, children, loading = false, isOnboarding, fullWidth = false,
}: LayoutProps) => (
  <main className={fullWidth ? classes.fullWidth : classes.main}>
    {loading
      && <img className={classes.loading} src={LoadingIcon} alt="Loading" />}
    {!loading
      && (
      <>
        <div className={classes.header}>
          <Link to={`/mysite/${userId}`}>
            <img src={BackArrowIcon} alt="Back" />
          </Link>
          {!isOnboarding
          && <div className={classes.title}>{title}</div>}
        </div>
        {children}
      </>
      )}
  </main>
);

export default Layout;
