import moment from 'moment-timezone';

const formatDate = (date, dateFormat) => {
  if (!moment.isMoment(date)) {
    // eslint-disable-next-line no-param-reassign
    date = moment(date);
  }

  return date.format(dateFormat);
};
const parseDate = (date, dateFormat) => moment(date, dateFormat);
const getTimezone = () => moment.tz.guess();

const formatTimeWindow = (window) => {
  const startTime = moment().hour(window.start_hour).minute(window.start_min);
  const endTime = moment().hour(window.end_hour).minute(window.end_min);
  const nextDay = (startTime > endTime) ? 'next day' : '';
  return {
    range: `${startTime.format('h:mm A')} - ${endTime.format('h:mm A')} ${nextDay}`,
    overnight: startTime > endTime,
  };
};

export {
  formatDate,
  parseDate,
  getTimezone,
  formatTimeWindow,
};
