import React from 'react';
import classes from './card.module.scss';

interface DashboardCardProps {
  children?: any;
  className?: string;
}
const DashboardCard = ({ children, className = '' }: DashboardCardProps) => (
  <div className={`${classes.dashboard_card} ${className}`}>{children}</div>
);

export default DashboardCard;
