import React from 'react';
import { times } from 'lodash';
import moment from 'moment';
import classes from './review.module.scss';

import visibleIcon from '../../../images/reviewVisible.svg';
import hiddenIcon from '../../../images/reviewHidden.svg';
import starIcon from '../../../images/ratingStar.svg';
import starGreyIcon from '../../../images/ratingGrayStar.svg';
import openIcon from '../../../images/open-arrow.svg';

interface ReviewProps {
  review: {
    id: string,
    notes: string,
    visible: boolean,
    rating: number,
    created_at: number,
    job_id: number,
    reference_id: string,
  },
  onClick: (id: string) => void,
}

const Review = ({ review, onClick }: ReviewProps) => {
  const visible = review.visible
    ? (
      <button type="button" className={classes.visibility} onClick={() => onClick(review.id)}>
        <img src={visibleIcon} alt="visible" />
        <span className={classes.visible}>Visible</span>
      </button>
    )
    : (
      <button type="button" className={classes.visibility} onClick={() => onClick(review.id)}>
        <img src={hiddenIcon} alt="hidden" />
        <span className={classes.hidden}>Hidden</span>
      </button>
    );

  const { rating } = review;
  const job = review.reference_id
    ? (
      <a href={`jobox://job/${review.reference_id}`} className={classes.hash}>
        {`#${review.reference_id}`}
        {' '}
        <img src={openIcon} alt="open job" />
      </a>
    )
    : <span className={classes.removed}>Job removed</span>;

  const timestamp = review.created_at ? moment(review.created_at).format('MM/DD/YYYY h:mm:a') : '';

  return (
    <div className={classes.review}>
      <div className={classes.header}>
        {visible}
        <div className={classes.headerRight}>
          {times(5, (i) => (
            <div key={i}>
              {i >= rating
                ? <img src={starGreyIcon} alt="star" />
                : <img src={starIcon} alt="star" />}
            </div>
          ))}
          {job}
        </div>
      </div>
      <p className={classes.comments}>
        {review.notes}
      </p>
      <div className={classes.footer}>
        <span>{timestamp}</span>
      </div>
    </div>
  );
};

export default Review;
