class JobInfoModel {
  referenceId?: string;
  jobId?: number;
  createdAt?: number;
  closingPrice?: number;
  eta?: string;
  address?: string;
  zip?: string;
  constructor(jobInfo?: JobInfo) {
    if (jobInfo) {
      this.referenceId = jobInfo.reference_id;
      this.jobId = jobInfo.job_id;
      this.createdAt = jobInfo.created_at;
      this.eta = jobInfo.eta;
      this.address = jobInfo.address;
      this.closingPrice = jobInfo.closing_price;
      this.zip = jobInfo.zip;
    }
  }
}

export default JobInfoModel;
