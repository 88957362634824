import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { generatePath, useRouteMatch, useParams } from 'react-router';
import {
  Box,
  Button,
  Dialog,
  Text,
  Header,
} from '@jobox/react-components-library/mobile';

import { ActionType, CompletionStatus } from '../../consts';

import iconCancelSuccess from './cancel-success-icon.svg';
import iconUpdateSuccess from './update-success-icon.svg';

import localClasses from './ResultSuccess.module.scss';
import commonClasses from '../common.module.scss';

const classes = { ...localClasses, ...commonClasses };

const ResultSuccess = ({
  isOpen,
  command,
  selectedReason,
  reasonDetails,
}) => {
  const { path } = useRouteMatch();
  const urlParams = useParams();

  return (
    <Dialog
      open={isOpen}
      className={classes.dialog}
    >
      <img
        className={classes.icon}
        src={(
          command === ActionType.Update
            ? iconUpdateSuccess
            : iconCancelSuccess
        )}
        alt="Action successful icon"
      />

      <Box className={classes.header}>
        <Header className={classes.header__title} type="h3">
          { command === ActionType.Update ? 'Update Sent' : 'Job Canceled' }
        </Header>
        <Text className={classes.header__details}>
          { selectedReason ? selectedReason.description : '' }
        </Text>
      </Box>

      <Box className={classes.details}>
        {
          reasonDetails.price && (
            <Text
              className={cn(
                classes.details__field,
                classes['details__field--price'],
              )}
            >
              { reasonDetails.price?.toFixed(2) }
            </Text>
          )
        }
        {
          reasonDetails.reason && (
            <Text className={classes.details__field} multiline>
              { reasonDetails.reason }
            </Text>
          )
        }
      </Box>

      <Button
        color="primary"
        className={classes.close}
        href={generatePath(path, { ...urlParams, status: CompletionStatus.Complete })}
      >
        Close
      </Button>
    </Dialog>
  );
};
ResultSuccess.propTypes = {
  isOpen: PropTypes.bool,
  command: PropTypes.oneOf([ActionType.Reject, ActionType.Update]).isRequired,
  selectedReason: PropTypes.shape({
    description: PropTypes.string.isRequired,
  }).isRequired,
  reasonDetails: PropTypes.shape({
    reason: PropTypes.string,
    price: PropTypes.number,
  }),
};

export default ResultSuccess;
