import {
  action, makeObservable, observable, toJS,
} from 'mobx';

class MySiteSettingsStore {
  error = false;

  constructor(apiService) {
    this.apiService = apiService;
    this.isLoading = false;
    this.userId = null;
    this.mySite = {};
    this.status = false;
    this.isAliasValid = true;
    this.isValidating = false;

    makeObservable(this, {
      isLoading: observable,
      userId: observable,
      error: observable,
      isAliasValid: observable,
      isValidating: observable,
      mySite: observable,
      resetErrors: action,
      setError: action,
    });
  }

  // Actions ------------------------------------
  async fetchMySiteSettings(userId) {
    this.isLoading = true;
    try {
      this.userId = userId;
      this.mySite = await this.apiService.getMySiteSettings(userId);

      this.status = await this.apiService.getMySiteStatus(userId);

      this.setError(false);
    } catch (error) {
      this.setError(true);
    } finally {
      this.isLoading = false;
    }
  }

  async updateMySiteStatus() {
    try {
      const response = await
      this.apiService.updateMySiteStatus(this.userId, !this.status);

      this.setError(false);
      this.mySite = response;
      this.status = response.active;
    } catch (error) {
      this.setError(true);
    }
  }

  async updateMySiteSettings(settings) {
    try {
      const currentSettings = toJS(this.mySite);
      const settingsPayload = {
        ...currentSettings,
        ...settings,
      };
      const response = await
      this.apiService.updateMySiteSettings(this.userId, settingsPayload);

      this.setError(false);
      this.mySite = response;
    } catch (error) {
      this.setError(true);
    }
  }

  async checkMySiteAlias(alias) {
    this.isValidating = true;
    try {
      const response = await
      this.apiService.checkMySiteAlias(alias);

      this.setError(false);
      this.isAliasValid = response.valid;
    } catch (error) {
      this.setError(true);
    } finally {
      this.isValidating = false;
    }
  }

  resetErrors() {
    this.error = false;
  }

  setError(newError) {
    this.error = newError;
  }
}

export default MySiteSettingsStore;
