import React, { useEffect, useRef, useState } from 'react';
import classes from './input.module.scss';

const regEx = /[^a-z0-9]/gi;

interface InputProps {
  onChange: any,
  label: string,
  type?: string,
  icon?: string,
  value: string | number,
  name: string,
  id: string,
  disabled?: boolean,
  indicator?: JSX.Element | null,
}

const Input = ({
  value, disabled, onChange, label, name, id, type = 'text', icon, indicator,
}: InputProps) => {
  const [active, setActive] = useState(false);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => setActive(!!value), [value]);

  const focusInput = () => {
    if (!disabled && ref.current) {
      ref.current.focus();
    }
  };

  const inputId = id || `input-${label.toLowerCase().replace(regEx, '')}`;

  return (
    <div className={classes.inputContainer} onClick={focusInput} onKeyDown={focusInput} tabIndex={0} role="textbox">
      {icon
      && <img className={classes.inputIcon} src={icon} alt="input-decorator" />}
      <div className={classes.inputContent}>
        <label
          className={`${classes.label} ${active ? classes.labelActive : ''}`}
          id={`label-${label.toLowerCase().split(' ').join('_')}`}
          htmlFor={inputId}
        >
          {label}
        </label>
        <input
          ref={ref}
          onFocus={() => setActive(true)}
          onBlur={(e) => setActive(!!e.target.value && !!e.target.value.length)}
          className={classes.input}
          id={inputId}
          disabled={disabled}
          onChange={onChange}
          name={name}
          value={value}
          type={type}
        />
        {indicator
          && <div className={classes.indicator}>{indicator}</div>}
      </div>
    </div>
  );
};

export default Input;
