import React from 'react';
import { inject, observer } from 'mobx-react';
import { compose } from 'lodash/fp';
import { useParams } from 'react-router';

import SkillsetSection from '../../components/SectionTypes/SkillsetSection';
import NavSection from '../../components/SectionTypes/NavSection';
import SkillsetModel from '../../models/SkillsetModel';
import Layout from '../../components/MySite/Layout/Layout';
import withLoading from '../../components/withLoading';

interface MySiteSkillsViewProps {
  connectTechSettingsStore: {
    occupationId: number,
    basicProfileStore: {
      basicProfile: {
        baseZip: string,
        skillSet: [
          { occupation_id: number,
            job_sub_category_id: number,
          },
        ],
      },
    },
    updateBaseZip: any,
    data: {
      marketplace_qualification: string,
    }
  },
  occupationsStore: any
}

const MySiteSkillsView = ({
  connectTechSettingsStore, occupationsStore,
}: MySiteSkillsViewProps) => {
  if (!occupationsStore || !connectTechSettingsStore
    || !connectTechSettingsStore.basicProfileStore) {
    return <Layout loading fullWidth />;
  }
  const { userId } = useParams<{ userId: string }>();
  const { basicProfile } = connectTechSettingsStore.basicProfileStore;

  const getSkillset = () => {
    const techSkillset = basicProfile.skillSet.reduce((acc, skill) => ({
      ...acc,
      [skill.occupation_id]: {
        // @ts-ignore
        ...acc[skill.occupation_id],
        [skill.job_sub_category_id]: {
          numSkills: (
            ( // @ts-ignore
              acc[skill.occupation_id]
              // @ts-ignore
              && acc[skill.occupation_id][skill.job_sub_category_id]
            ) // @ts-ignore
              ? acc[skill.occupation_id][skill.job_sub_category_id].numSkills
              : 0
          ) + 1,
        },
      },
    }), {});
    // map occupations and skills to names,
    // tech skillset to overall possible skillset for occupation
    const userOccupation = connectTechSettingsStore.occupationId;
    if (
      userOccupation && !Object.keys(techSkillset).includes(String(userOccupation))
    ) {
      // @ts-ignore
      techSkillset[userOccupation] = {};
    }

    return Object.entries(techSkillset).map(([occupationId, skillset]) => {
      const occupation = occupationId && occupationsStore.getOccupation(parseInt(occupationId, 10));
      return new SkillsetModel(occupation, skillset);
    }).filter((s) => s !== null);
  };

  const skillset = getSkillset();
  const shouldDisplayWarning = connectTechSettingsStore && connectTechSettingsStore.data.marketplace_qualification === 'Eligible';

  return (
    <Layout title="Service area" fullWidth userId={userId}>
      {
        skillset && skillset.map((occupation) => {
          if (!occupation.categories) {
            return (
              <div className="connect-tech-settings__section" key={`${occupation.name}-section`}>
                <NavSection
                  title={occupation.name}
                  subtitle="Enabled"
                  icon={occupation.icon}
                  className="connect-tech-settings__skillset-section"
                />
              </div>
            );
          }
          return (
            <div className="connect-tech-settings__section" key={`${occupation.name}-section`}>
              <SkillsetSection
                skillset={occupation.categories}
                occupation={occupation}
                userId={userId}
                isLink
                baseUrl="mysite"
              />
            </div>
          );
        })
      }
      {shouldDisplayWarning
      && (
        <p className="connect-tech-settings__warning">
          Changing this setting on
          MySite will update it in your Connect Settings too.
        </p>
      )}
    </Layout>
  );
};

export default compose(
  inject(({ connectTechSettingsStore }) => ({
    occupationsStore: connectTechSettingsStore.occupationsStore,
    connectTechSettingsStore,
  })),
  observer,
  withLoading({
    checkProps: ({ occupationsStore, connectTechSettingsStore }) => (
      occupationsStore && connectTechSettingsStore && connectTechSettingsStore.basicProfileStore
    ),
    renderLoader: () => (
      <Layout loading fullWidth />
    ),
  }),
)(MySiteSkillsView);
