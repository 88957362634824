import React from 'react';
import notFoundImage from '../../images/404.svg';
import logo from '../../images/jobox_logo.svg';
import classes from './notFound.module.scss';

const NotFoundView = () => (
  <>
    <div className={classes['not-found__container']}>
      <img src={notFoundImage} className={classes['not-found__img']} alt="not found" />
      <div className={classes['not-found__header']}>Nothing Here</div>
    </div>
    <img src={logo} className={classes['not-found__logo']} alt="not found" />
  </>
);

export default NotFoundView;
